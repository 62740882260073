import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import FormSelectInput from "../../../../../component/Form/FormSelectInput";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
} from "../../../../../helper/fetching";
import { genericSelectItemsMapper } from "../../../../../helper/model";

const GoldSection = () => {
  const formik = useFormikContext();

  const [goldTypes, setGoldTypes] = useState([]);

  useEffect(() => {
    const fetchGoldTypes = async () => {
      const res = await apiRequest().get(`${API_ENDPOINT_PREFIX}/gold-type`);

      const data = res.data;
      setGoldTypes(data);
    };

    fetchGoldTypes();
  }, []);
  

  const handleGoldWeightChange = (e) => {
    if(!e.target.value ) {
      formik.setFieldValue("gold_weight", e.target.value);
      formik.setFieldValue("gold_limit_weight", '');
      return 
    } 

    formik.setFieldValue("gold_weight", e.target.value);
    const limitWeight = !isNaN((e.target.value * (1 - 0.04)).toFixed(2)) ?
      (e.target.value * (1 - 0.04)).toFixed(2) :
      ""

    formik.setFieldValue("gold_limit_weight", limitWeight);
  }


  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <FormSelectInput
          id="gold_type_id"
          name="gold_type_id"
          label="成色"
          labelId="gold_type_id"
          items={goldTypes}
          value={formik.values.gold_type_id}
          itemsMapper={genericSelectItemsMapper}
          onChange={formik.handleChange}
          key={formik.values.gold_type_id}
          required={true}
        />
      </Grid>
      <Grid item xs={2}>
        <FormTextInput
          name="gold_style"
          label="成色"
          value={formik.values.gold_style}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextInput
          name="gold_weight"
          label="金重"
          value={formik.values.gold_weight}
          onChange={handleGoldWeightChange}
          required={true}
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextInput
          name="gold_limit_weight"
          label="限金重"
          value={formik.values.gold_limit_weight}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
    </Grid>
  );
};
 
export default GoldSection;
