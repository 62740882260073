import { useContext, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import PopupWidget from "../../../component/PopupWidget";
import TableWithCheckBox from "../../../component/TableWithCheckBox";
import { API_ENDPOINT_PREFIX, apiRequest } from "../../../helper/fetching";
import { PageContext } from "../../../context/PageContext";

function PoSearchSection({
  arrayHelpers,

  selectedOption,
  setSelectedOption,

  preSetValues,
  setPreSetValues,

  dialogTitle = "No Title",
  createTitle = "create Title",

  searchModule = "",

  toolTipTitle,

  searchFieldLabel,

  searchKeys,
  columns,

  PO_ID,
  PO_NO,

  default_gold_price = null,

  ...rest
}) {
  const pageContext = useContext(PageContext);

  const formik = useFormikContext();

  const [quotations, setQuotations] = useState([]);

  const [selectValue, setSelectValue] = useState("");

  useEffect(() => getNewRow(), []);

  const getNewRow = async () => {
    try {
      const res = await apiRequest().get(
        `${API_ENDPOINT_PREFIX}/purchase-order?po_no=${
          formik.values.po_no || PO_NO
        }`
      );
      setQuotations(res.data.data[0].quotations);
    } catch (error) {}
  };

  const handleAddSelectedOption = async () => {
    if (!selectedOption) return;
    getNewRow();
    const callBy = selectedOption.hasOwnProperty("order_date")
      ? "work-order"
      : "quotation";

    let work_order = {};

    if (callBy === "work-order") {
      if (!quotations.length)
        return alert("Please fill in the Quotation first! ");

      const setStone_code = (field) => {
        //set empty stone_code value to _
        field.length &&
          field.forEach(
            (item) => item.stone_code === "" && (item.stone_code = "_")
          );
      };
      const { stones, deduct_gems, deduct_stones, gems } = selectedOption;
      setStone_code(stones);
      setStone_code(deduct_gems);
      setStone_code(deduct_stones);
      setStone_code(gems);

      work_order = {
        ...selectedOption,
        factory_field: selectedOption.factory_fields, //have chain_weight chain_weight: 10
        factory_id: selectedOption.factory.id,
        id_no: null, //id can be null .it can auto gen an id
        // quotation_id: quotations[quotations.length - 1].id, //it have to be have an id from Purchase_Order/[quotation] id
        quotation: {
          id: selectValue.id,
          identifier: selectValue.identifier,
          images: selectValue.images,
          customer_item_no: selectValue.customer_item_no,
          unit_price: selectValue.unit_price,
          is_confirmed: selectValue.is_confirmed,
        },
        image_id: selectedOption.images?.id || null,
        quotation_id: selectValue.id,
      };
    }

    try {
      const res = await apiRequest().post(
        `${API_ENDPOINT_PREFIX}/purchase-order/${PO_ID}/${callBy}`,
        callBy === "work-order"
          ? work_order
          : {
              ...selectedOption,
              image_id: selectedOption.images?.id || null,
            }
      );

      callBy === "quotation" && getNewRow();
      selectedOption && setPreSetValues(selectedOption);
      pageContext.fetchPurchaseOrder && pageContext.fetchPurchaseOrder();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={12}>
        <PopupWidget
          dialogTitle={dialogTitle}
          createTitle={createTitle}
          handleSubmit={handleAddSelectedOption}
          toolTipTitle={toolTipTitle}
          onOpen={() => getNewRow()}
        >
          {dialogTitle === "搜索製工單" ? (
            <>
              <Typography variant="span" style={{ lineHeight: "40px" }}>
                選擇對應報價單：
              </Typography>

              <FormControl variant="outlined" size="small">
                <Select
                  id="select-quotation"
                  value={selectValue}
                  style={{ width: "200px" }}
                  onChange={(e) => setSelectValue(e.target.value)}
                  defaultValue={quotations[0]}
                >
                  {quotations.map((item) => (
                    <MenuItem value={item} key={item.id}>
                      ID: {item.id} 款號: {item.identifier}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : null}
          <TableWithCheckBox
            optionUrl={`${API_ENDPOINT_PREFIX}/${searchModule}/template`}
            setSelectedOption={setSelectedOption}
            searchFieldLabel={searchFieldLabel}
            searchKeys={searchKeys}
            columns={columns}
          />
        </PopupWidget>
      </Grid>
    </Grid>
  );
}

export default PoSearchSection;
