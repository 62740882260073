import axios from "axios";

const DOMAIN =
  window.location.hostname === "localhost"
    ? "matthew-jewellery.demo.digiec.com"
    : window.location.hostname;

export const SERVER_URL = `${window.location.protocol}//${DOMAIN}`;
export const API_ENDPOINT_PREFIX = `${SERVER_URL}/api`;
export const API_ENDPOINT_VALIDATE = `${SERVER_URL}/api/validate`;
export const API_ENDPOINT_AUTH_PREFIX = `${SERVER_URL}/api/auth`;

export const getURLFromPublic = (path) => {
  return `${SERVER_URL}/${path}`;
};

export const apiRequest = (
  params = {
    headers: {
      "Conetent-Type": "application/json",
    },
  }
) => {
  const instance = axios.create(params);

  instance.interceptors.request.use(
    (conf) => {
      if (localStorage.getItem("token")) {
        conf.headers["Authorization"] =
          "Bearer " + localStorage.getItem("token");
      }

      return conf;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      const token = res.headers["authorization"];

      if (token) {
        if (token.startsWith("Bearer ")) {
          localStorage.setItem("token", token.substring(7));
        }
      }
      return res;
    },
    (err) => {
      return Promise.reject(err.response);
    }
  );

  return instance;
};

export const cleanFilterObject = (filters) => {
  let cleaned_filter = {};
  for (const key in filters) {
    if (
      (filters[key] || filters[key] === false || filters[key] === 0) &&
      filters[key] !== "all"
    ) {
      cleaned_filter[key] = filters[key];
    }
  }
  return cleaned_filter;
};

export const convertToFormData = (data) => {
  let formData = new FormData();

  for (const key in data) {
    if (Array.isArray(data[key])) {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  }

  return formData;
};
