import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  TextField,
  Box,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FieldArray, useFormikContext } from "formik";
import { DataGrid } from "@mui/x-data-grid";
import FormSection from "../../../component/Form/FormSection";
import FormTable from "../../../component/Form/FormTable";
import { PoQuotationSectionTableHeader } from "../Constants";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import PopupWidget from "../../../component/PopupWidget";
import TableWithCheckBox from "../../../component/TableWithCheckBox";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../helper/fetching";
import { useEffect, useState } from "react";
// import PoSearchSection from "./PoSearchSection";
import PoSearchSection from "../../OrderPage/FormSection/PoSearchSection";
import SlideFormWrapper from "../../../component/SlideForm";
import useFormAnimation from "../../../hook/useFormAnimation";
import EditForm from "../../QuotationPage/QuotationListingPage/component/EditForm";

import ComponentLayout from "../../../layout/EntityMangeLayout/ComponentLayout";

import QuotationPageCreateForm from "../../QuotationPage/QuotationListingPage/component/CreateForm";
import LoadingIcon from "../../../component/LoadingIcon";

import CheckIcon from "@material-ui/icons/Check";
import GetAppIcon from "@material-ui/icons/GetApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";

var fileDownload = require("js-file-download");

/************************* DeleteConfirmActionPage *****************************************/
const DeleteConfirmActionPage = ({
  open,
  handleClose,
  handleConfirm,
  entity,
}) => {
  const [msg, setMsg] = useState(
    entity.workOrder.length > 0 ? "此報價單下有製工單，無法刪除" : ""
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>確認刪除此報價單</DialogTitle>
      <DialogContent>
        <Typography variant="h6">確認刪除：{entity.identifier} ?</Typography>
        {msg && (
          <Typography variant="body1" color="error">
            {msg}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          取消
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          disabled={entity.workOrder.length > 0 ? true : false}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/************************* CloneActionPage *****************************************/
const CloneActionPage = ({ open, handleClose, onSelectRow, PO_ID, entity }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const formik = useFormikContext();

  useEffect(() => {
    const fetchRows = async () => {
      try {
        const response = await apiRequest().get(
          `${API_ENDPOINT_PREFIX}/purchase-order${
            searchTerm ? `?po_no=${searchTerm}&` : "?"
          }page=${page + 1}&pageSize=${pageSize}`
        );
        setRows(response.data.data);
        setRowCount(response.data.meta.total);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRows();
  }, [searchTerm, page, pageSize]);

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };

  const handleConfirm = async () => {
    if (selectedRow) {
      const { gold_price, id } = selectedRow;
      try {
        await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/purchase-order/${id}/quotation`,
          {
            ...entity,
            gold_price: gold_price,
          }
        );
        setSnackbarMessage(`成功複製到${selectedRow.po_no}`);
        setSnackbarSeverity("success");
        handleClose();
        setSnackbarOpen(true);
        handleClose();
      } catch (error) {
        setSnackbarMessage(`複製失敗${": " + error.data.message}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.error(error);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>選擇訂單</DialogTitle>
        <DialogContent>
          <TextField
            label="搜索PO"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(0);
            }}
            width={50}
            size="small"
            style={{ marginBottom: 10 }}
            variant="outlined"
          />
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={[
                { field: "id", headerName: "ID", width: 150 },
                { field: "po_no", headerName: "PO", width: 200 },
                {
                  field: "quotations_are_confirmed",
                  headerName: "Confirmed",
                  width: 150,
                  valueGetter: (params) => (params.value ? "已確認" : "未確認"),
                },
              ]}
              pagination
              pageSize={pageSize}
              rowCount={rowCount}
              paginationMode="server"
              onPageChange={(params) => setPage(params)}
              onPageSizeChange={(params) => setPageSize(params.pageSize)}
              onRowClick={handleRowClick}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            取消
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            disabled={!selectedRow}
          >
            確認
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
/************************* PoWorkOrderTableRow *****************************************/
const PoWorkOrderTableRow = ({
  key,
  index,
  arrayHelpers,
  values,
  PO_ID,
  ...rest
}) => {
  const formik = useFormikContext();
  const [message, setMessage] = useState("");
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  if (formik.values.gold_price) {
    values.gold_price = formik.values.gold_price;
  }

  const handleDeleteButtonClick = async () => {
    let data = { ...formik.values };
    let getQuotationID =
      data.quotations.length > 0 ? data.quotations[index].id : null;

    if (!getQuotationID) return;

    try {
      const res = await apiRequest().delete(
        `${API_ENDPOINT_PREFIX}/purchase-order/${formik.values.id}/quotation/${getQuotationID}`
      );
      arrayHelpers.remove(index);
      await formik.fetchCurrentPO();
    } catch (err) {
      err.status === 400 && setMessage(err.data.message);
    }
  };

  const {
    formIn: editFormIn,
    openForm: openEditForm,
    closeForm: closeEditForm,
  } = useFormAnimation();

  const handleConfirm = async () => {
    // try {
    //   const res = await apiRequest().post(
    //     `${API_ENDPOINT_PREFIX}/quotation/${params.row.id}/confirm`
    //   );
    //   console.log(res);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const ExportButton = ({ id }) => {
    const [loading, setLoading] = useState(false);

    const handleAction = async () => {
      setLoading(true);
      try {
        const res = await apiRequest({
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
          responseType: "blob",
        }).get(`${API_ENDPOINT_PREFIX}/quotation/${id}/exportExcel`);

        var blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        const date = "";

        fileDownload(blob, `Quotation-${id}-${date}.xlsx`);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        {loading ? (
          <IconButton>
            <LoadingIcon />
          </IconButton>
        ) : (
          <Tooltip title="Download Quotation">
            <IconButton onClick={handleAction}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  const ConfirmButton = ({ id }) => {
    const [loading, setLoading] = useState(false);

    const handleAction = async () => {
      setLoading(true);
      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/quotation/${id}/confirm`
        );
        await formik.fetchCurrentPO();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        {loading ? (
          <IconButton>
            <LoadingIcon />
          </IconButton>
        ) : (
          <Tooltip title="Confirm Quotation">
            <IconButton onClick={handleAction}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <>
      <TableRow>
        <TableCell>{values.identifier}</TableCell>
        <TableCell>{values.client?.name || ""}</TableCell>
        <TableCell>{values.quotation_order_date}</TableCell>

        <TableCell>{values.is_confirmed ? "已確認" : "未確認"}</TableCell>

        <TableCell>
          <IconButton onClick={openEditForm}>
            <EditIcon />
          </IconButton>

          <ConfirmButton id={values.id} />

          <ExportButton id={values.id} />

          <IconButton onClick={() => setDeleteDialogOpen(true)}>
            <CloseIcon color={message ? "error" : ""} />
          </IconButton>
          {message ? (
            <Typography variant="h6" color="error">
              {message}
            </Typography>
          ) : null}

          <IconButton onClick={() => setCloneDialogOpen(true)}>
            <FileCopyIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <SlideFormWrapper formIn={editFormIn} closeForm={closeEditForm}>
        <EditForm
          entity={values}
          PO_ID={PO_ID}
          closeForm={() => {
            rest.fetchCurrentPO();
            closeEditForm();
          }}
        />
      </SlideFormWrapper>
      <CloneActionPage
        open={cloneDialogOpen}
        handleClose={() => setCloneDialogOpen(false)}
        onSelectRow={(row) => {
          // Handle the selected row data
          setCloneDialogOpen(false);
        }}
        entity={values}
        PO_ID={PO_ID}
      />
      <DeleteConfirmActionPage
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={handleDeleteButtonClick}
        entity={values}
      />
    </>
  );
};

/************************* PoQuotationTable *****************************************/
const PoQuotationTable = ({
  arrayHelpers,
  PO_ID,
  searchKeys,
  columns,
  fetchCurrentPO,
}) => {
  const formik = useFormikContext();

  //confirmed option that add to Formik
  const [preSetValues, setPreSetValues] = useState("");

  // Selected option in search area
  const [selectedOption, setSelectedOption] = useState("");

  const renderRows = () => {
    return formik.values.quotations?.map((quotation, index) => (
      <PoWorkOrderTableRow
        key={index}
        index={index}
        arrayHelpers={arrayHelpers}
        values={quotation}
        PO_ID={PO_ID}
        fetchCurrentPO={fetchCurrentPO}
      />
    ));
  };

  const handleAddPoWorkOrder = () => {
    if (formik.values?.gold_price) {
      preSetValues.gold_price = formik.values?.gold_price;
    }
    arrayHelpers.push(preSetValues);
  };

  useEffect(() => {
    if (preSetValues) {
      handleAddPoWorkOrder(preSetValues);
    }
  }, [preSetValues]);

  return (
    <>
      <ComponentLayout
        renderHeader={(props) => (
          <Button
            variant="contained"
            color="primary"
            style={{
              position: "absolute",
              width: 104,
              margin: "12px 0",
              transform: "translate(113px , -12px)",
            }}
            onClick={props.openForm}
          >
            新增報價單
          </Button>
        )}
        renderCreateForm={(props) => (
          <QuotationPageCreateForm
            {...props}
            callInfo={{
              by: "Purchase-Order",
              id: formik.values.id,
              default_gold_price: formik.values.gold_price,
              setValueForTable: setPreSetValues,
            }}
          />
        )}
      />

      <PoSearchSection
        dialogTitle="搜索報價單"
        searchModule="quotation"
        preSetValues={preSetValues}
        setPreSetValues={setPreSetValues}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        searchFieldLabel="款號"
        searchKeys={searchKeys}
        columns={columns}
        PO_ID={formik.values.id}
        default_gold_price={formik.values.gold_price}
      />

      <FormTable
        headerNames={PoQuotationSectionTableHeader}
        renderRows={renderRows}
      />
    </>
  );
};

/**************************** MainContent **************************************/
const MainContent = ({ PO_ID, searchKeys, columns, fetchCurrentPO }) => {
  return (
    <FieldArray
      name="quotations"
      validateOnChange={false}
      render={(arrayHelpers) => (
        <PoQuotationTable
          arrayHelpers={arrayHelpers}
          PO_ID={PO_ID}
          searchKeys={searchKeys}
          columns={columns}
          fetchCurrentPO={fetchCurrentPO}
        />
      )}
    />
  );
};

/**************************** PoQuotationSection **************************************/
function PoQuotationSection({ PO_ID, searchKeys, columns, fetchCurrentPO }) {
  return (
    <FormSection
      title="報價單"
      renderContent={() => (
        <MainContent
          PO_ID={PO_ID}
          searchKeys={searchKeys}
          columns={columns}
          fetchCurrentPO={fetchCurrentPO}
        />
      )}
      collaspe
    />
  );
}

export default PoQuotationSection;
