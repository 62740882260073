import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useContext, useState, useEffect, useRef } from "react";
import SlideFormWrapper from "../../../../../component/SlideForm";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import { PageContext } from "../../../../../context/PageContext"
import * as Yup from "yup";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
} from "../../../../../helper/fetching";
import FormSection from "../../../../../component/Form/FormSection";
import OrderDetailSection from "./OrderDetailSection";
import MarkUpsection from "./MarkUpSection";
import BasicInfoSection from "./BasicInfoSection";
import GoldSection from "./GoldSection";
import StoneSection from "./StoneSection";
import AdditionalCostSection from "./AdditionalCostSection";
import RemarkSection from "./RemarkSection";
import FactorySection from "./FactorySection";
import DiamondsSection from "./DiamondSection";
import { newWorkOrderInitValue } from "../../constant/NewWorkOrderForm";
import { workOrderFormSchema } from "../Schema/EditFormSchema";
import ClientInfoSection from "./ClientInfoSection";
import ScrollToFieldError from "../../../../../component/SrollToTop/index";




// css style 
const useStyles = makeStyles((theme) => ({

  input: {
    width: "100%",
  },
  informationWrapper: {
    marginBottom: 20,
  },
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
}));


function CreateNewWorkOrderSection(props) {
  const {
    openWorkOrderForm,
    toggleWorkOrderForm,
    arrayHelpers,
    work_order,
    work_order_index,
    client,
    productImgPath,
    ...rest
  } = props;
  const { fetchQuotations } = useContext(PageContext);

  //apply style
  const classes = useStyles();

  // initialize the work order form
  const formik = useFormik(
    {
      initialValues: work_order ? work_order : newWorkOrderInitValue,
      validationSchema: Yup.object().shape(workOrderFormSchema),

      onSubmit: () => {
        if (typeof (work_order_index) === "undefined") {
          //if order is new create, add it to quotation
          arrayHelpers.push(formik.values)
        } else {
          //if order is existing, save changes to quotation
          arrayHelpers.replace(work_order_index, formik.values);
        }
        toggleWorkOrderForm();

        //scrolling to top after save
        window.scrollTo(0, 0)
      },
      validateOnChange: false,
      validateOnBlur: false,

    }
  )


  return (
    <SlideFormWrapper formIn={openWorkOrderForm} closeForm={toggleWorkOrderForm}>
      <FormikProvider value={formik}>
        <div {...rest}>
          <div>
            <ScrollToFieldError />
            <Typography variant="h3" className={classes.formTitle}>
              編輯製工單
            </Typography>

            <FormSection
              title="工單基本信息"
              renderContent={() => <OrderDetailSection />}
              collaspe
            />
            <FormSection
              title="客人資料"
              renderContent={() => <ClientInfoSection client={client} />}
              collaspe
            />

            <FormSection
              title="產品基本信息"
              renderContent={
                () => <BasicInfoSection productImgPath={productImgPath}/>
              }
              collaspe
            />

            <FormSection
              title="金資料"
              renderContent={() => <GoldSection />}
              collaspe
            />

            <FormSection
              title="鑽石資料"
              renderContent={() => <DiamondsSection />}
              collaspe
            />

            <FormSection
              title="其他石資料"
              renderContent={() => <StoneSection />}
              collaspe
            />

            <FormSection
              title="備註部分"
              renderContent={() => <RemarkSection />}
              collaspe
            />

            <FormSection
              title="廠方部分"
              renderContent={() => <FactorySection />}
              collaspe
            />


            <Grid container>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={formik.handleSubmit}
                // disabled={formik.isSubmitting}
                >
                  確定
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </FormikProvider>
    </SlideFormWrapper>


  );
}

export default CreateNewWorkOrderSection;