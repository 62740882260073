import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core";
import FormTextInput from "../../component/Form/FormTextInput";
import { useHistory } from "react-router-dom";
import { validteField } from "../../helper/validate";
import { apiRequest, API_ENDPOINT_AUTH_PREFIX } from "../../helper/fetching";
import GoBackButton from "../../component/GoBackButton";

const SignUpPage = () => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email()
        .required("Required")
        .test(
          "Unique User Email",
          "This email has been used",
          async function (value) {
            const data = {
              email: value,
            };
            const validate = await validteField("user/email", data);
            if (!validate.status) {
              if (validate.errors.identifier) {
                return this.createError({
                  message: `${validate.errors.identifier[0]}`,
                });
              }
              return false;
            }

            return true;
          }
        ),
      password: Yup.string().min(6).required("Required"),
      password_confirmation: Yup.string()
        .min(6)
        .required("Required")
        .test("same with password", "password must be same", function (value) {
          if (value === this.parent.password) {
            return true;
          }

          return false;
        }),
    }),
    onSubmit: async (data, { setSubmitting }) => {
      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_AUTH_PREFIX}/register`,
          data
        );
        history.push("/login");
      } catch (err) {
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 64,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GoBackButton
            style={{
              alignSelf: "start",
            }}
          >
            返回
          </GoBackButton>
          <Typography component="h1" variant="h5">
            註冊
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormTextInput
              name="email"
              label="電郵地址"
              value={formik.values.email}
              onChange={formik.handleChange}
              fullWidth
            />

            <FormTextInput
              name="password"
              label="輸入密碼"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              fullWidth
            />

            <FormTextInput
              name="password_confirmation"
              label="再次輸入密碼"
              type="password"
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
              fullWidth
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ marginTop: 24, marginBottom: 16 }}
              disabled={formik.isSubmitting}
            >
              註冊
            </Button>
          </Box>
        </Box>
      </Container>
    </FormikProvider>
  );
};

export default SignUpPage;
