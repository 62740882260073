import { createContext, useContext, useEffect, useState } from "react";
import MainRoute from "../component/MainRoute";
import useAuth from "../hook/useAuth";
import { privateRoutes, publicRoutes } from "../route/routes";
import LoadingPage from "../Page/LoadingPage";
import ResponsiveDrawer from "../component/ResponsiveDrawer";

export const TokenContext = createContext(null);

export const TokenProvider = ({
    children
}) => {

    const {
        token,
        permission,
        login,
        logout,
        verifyToken
    } = useAuth();

    return (
        <TokenContext.Provider value={{
            token,
            permission,
            login,
            logout,
            verifyToken
        }}>
            {children}
        </TokenContext.Provider>
    )
};

export const TokenBaseRouteComponent = () => {
    const { token, verifyToken, permission} = useContext(TokenContext);

    const [verified, setVerified] = useState(false);
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        const verifyUser = async () => {
            setVerified(await verifyToken());
            setFetching(false);
        }
        verifyUser();
    }, [token]);

    if (fetching){
        return (
            <LoadingPage/>
        );
    }
    
    if (verified){
        return (
            <ResponsiveDrawer>
                <MainRoute routes={privateRoutes}/>
            </ResponsiveDrawer>
        );
    }

    return (
        <MainRoute routes={publicRoutes}/>
    );
}