import { useState, useEffect } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  Button,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import FormTable from "../../../component/Form/FormTable";
import FormSection from "../../../component/Form/FormSection";
import PoSearchSection from "../FormSection/PoSearchSection";
import useFormAnimation from "../../../hook/useFormAnimation";
import SlideFormWrapper from "../../../component/SlideForm";
import { PoQuotationSectionTableHeader } from "../constants";
import { FieldArray, useFormikContext } from "formik";
import EditForm from "../../QuotationPage/QuotationListingPage/component/EditForm";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../helper/fetching";

import ComponentLayout from "../../../layout/EntityMangeLayout/ComponentLayout";

import QuotationPageCreateForm from "../../QuotationPage/QuotationListingPage/component/CreateForm";

import LoadingIcon from "../../../component/LoadingIcon";
import CheckIcon from "@material-ui/icons/Check";
import GetAppIcon from "@material-ui/icons/GetApp";

var fileDownload = require("js-file-download");

const PoWorkOrderTableRow = ({
  key,
  index,
  arrayHelpers,
  values,
  PO_ID,
  disabled
}) => {
  const formik = useFormikContext();
  const handleDeleteButtonClick = () => arrayHelpers.remove(index);
  const {
    formIn: editFormIn,
    openForm: openEditForm,
    closeForm: closeEditForm,
  } = useFormAnimation();

  const ExportButton = ({ id, disabled}) => {
    const [loading, setLoading] = useState(false);

    const handleAction = async () => {
      setLoading(true);
      try {
        const res = await apiRequest({
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
          responseType: "blob",
        }).get(`${API_ENDPOINT_PREFIX}/quotation/${id}/exportExcel`);

        var blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        const date = "";

        fileDownload(blob, `Quotation-${id}-${date}.xlsx`);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        {loading ? (
          <IconButton>
            <LoadingIcon />
          </IconButton>
        ) : (
          <Tooltip title="Download Quotation">
            <IconButton onClick={handleAction} disabled>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <>
      <TableRow>
        <TableCell>{values.identifier}</TableCell>
        <TableCell>{values.client?.name || ""}</TableCell>
        <TableCell>{values.quotation_order_date}</TableCell>
        <TableCell>{values.is_confirmed ? "已確認" : "未確認"}</TableCell>

        <TableCell>
          <IconButton onClick={openEditForm} disabled>
            <EditIcon />
          </IconButton>

          <ExportButton id={values.id} disabled/>

          {/* <IconButton onClick={handleDeleteButtonClick}>
            <CloseIcon />
          </IconButton> */}
        </TableCell>
      </TableRow>

      <SlideFormWrapper formIn={editFormIn} closeForm={closeEditForm}>
        <EditForm entity={values} PO_ID={PO_ID} />
      </SlideFormWrapper>
    </>
  );
};

const PoQuotationTable = ({
  arrayHelpers,
  PO_ID,
  searchKeys,
  columns,
  ...rest
}) => {
  const formik = useFormikContext();

  //confirmed option that add to Formik
  const [preSetValues, setPreSetValues] = useState("");

  // Selected option in search area
  const [selectedOption, setSelectedOption] = useState("");

  const renderRows = () => {
    return formik.values.quotations?.map((quotation, idx) => (
      <PoWorkOrderTableRow
        key={idx}
        index={idx}
        arrayHelpers={arrayHelpers}
        values={quotation}
        PO_ID={PO_ID}
        disabled={rest.disabled}
      />
    ));
  };

  const handleAddPoWorkOrder = () => {
    if (formik.values?.gold_price) {
      preSetValues.gold_price = formik.values?.gold_price;
    }
    arrayHelpers.push(preSetValues);
  };

  useEffect(() => {
    if (preSetValues) {
      handleAddPoWorkOrder(preSetValues);
    }
  }, [preSetValues]);

  return (
    <>
      {!rest.disabled && (
        <ComponentLayout
          renderHeader={(props) => (
            <Button
              variant="contained"
              color="primary"
              style={{
                position: "absolute",
                width: 104,
                margin: "12px 0",
                transform: "translate(113px , -12px)",
              }}
              onClick={props.openForm}
            >
              新增報價單
            </Button>
          )}
          renderCreateForm={(props) => (
            <QuotationPageCreateForm
              {...props}
              callInfo={{
                by: "Purchase-Order",
                id: PO_ID,
                default_gold_price: formik.values.gold_price,
                setValueForTable: setPreSetValues,
              }}
            />
          )}
        />
      )}

      {!rest.disabled && (
        <PoSearchSection
          dialogTitle="搜索報價單"
          createTitle="創建報價單"
          searchModule="quotation"
          preSetValues={preSetValues}
          setPreSetValues={setPreSetValues}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          searchFieldLabel="款號"
          searchKeys={searchKeys}
          columns={columns}
          PO_ID={PO_ID}
        />
      )}
      <FormTable
        headerNames={PoQuotationSectionTableHeader}
        renderRows={renderRows}
      />
    </>
  );
};

const MainContent = (props) => {
  return (
    <FieldArray
      name="quotations"
      validateOnChange={false}
      render={(arrayHelpers) => (
        <PoQuotationTable arrayHelpers={arrayHelpers} {...props} />
      )}
    />
  );
};

const PoQuotationSection = (props) => {
  return (
    <FormSection
      collaspe
      title="報價單"
      renderContent={() => <MainContent {...props} />}
    />
  );
};

export default PoQuotationSection;
