import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import clsx from "clsx";
import { useFormikContext } from "formik";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    width: "100%",
  },
}));

const FormSelectInput = ({
  variant = "outlined",
  margin = "dense",
  name,
  value,
  className,
  labelId,
  items = [],
  label,
  error,
  defaultValue,
  itemsMapper = (items) => items,
  ...rest
}) => {
  const classes = useStyles();

  const formik = useFormikContext();

  const errorState = (formik.touched[name] && formik.errors[name]) || error;

  const formattedItems = itemsMapper(items);
  
 

  return (
    <FormControl
      variant={variant}
      margin={margin}
      className={clsx([classes.root])}
    >
      <InputLabel id={labelId} error={errorState}>
        {label}
      </InputLabel>

      <Select
        labelId={labelId}
        value={value}
        name={name}
        label={label}
        disabled={!items.length}
        error={errorState}
        defaultValue={defaultValue}
        {...rest}
      >
        {formattedItems.map((item) => {
          return(
            <MenuItem key={item.key || item.value} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText error={errorState}>
        {(formik.touched[name] && formik.errors[name]) || error}
      </FormHelperText>
    </FormControl>
  );
};

export default FormSelectInput;
