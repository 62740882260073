import { useState, useEffect, useContext } from "react";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../helper/fetching";
import {
  TextField,
  Button,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import PageHeadline from "../../../component/PageHeadline";
import PageSubHeadline from "../../../component/PageSubHeadline";
import FormTextInput from "../../../component/Form/FormTextInput";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { PageContext } from "../../../context/PageContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    width: "400px",
  },

  div: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.1rem",
  },

  label: {
    width: "150px",
  },
}));

const EditForm = ({ entity, closeForm }) => {
  const { fetchProcess } = useContext(PageContext);

  const classes = useStyles();

  const [processDetail, setProcessDetail] = useState({
    delivery_date: entity?.processes?.delivery_date || "",
    diamond_out: entity?.processes?.diamond_out || "",
    gem_out: entity?.processes?.gem_out || "",
  });

  const [isHaveDetail, setIsHaveDetail] = useState(false);

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    (async () => {
      setFlag(flag);
      try {
        const res = await apiRequest().get(
          `${API_ENDPOINT_PREFIX}/work-order-processes/${entity.id}`
        );
        const json = res.data.data || res.data;

        setProcessDetail({
          delivery_date: json?.delivery_date || "",
          diamond_out: json?.diamond_out || "",
          gem_out: json?.gem_out || "",
        });
        setIsHaveDetail(true);
      } catch (err) {
        console.log(err);
        setIsHaveDetail(false);
      } finally {
        setFlag(true);
      }
    })();
  }, []);

  const formik = useFormik({
    initialValues: processDetail,
    validationSchema: Yup.object().shape({
      delivery_date: Yup.date().required("必填"),
      diamond_out: Yup.string().required("必填"),
      gem_out: Yup.string().required("必填"),
    }),
    onSubmit: async (data, { setSubmitting }) => {
      if (isHaveDetail) {
        try {
          const res = await apiRequest().put(
            `${API_ENDPOINT_PREFIX}/work-order-processes/${entity.id}`,
            {
              ...data,
              work_orders_id: entity.id,
              processes_id: Number(entity.id),
            }
          );
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          const res = await apiRequest().post(
            `${API_ENDPOINT_PREFIX}/work-order-processes`,
            {
              ...data,
              work_orders_id: entity.id,
              processes_id: Number(entity.id),
            }
          );
          const json = res.data.data;
          setProcessDetail(json);
        } catch (err) {
          console.log(err);
        }
      }
      closeForm();
      fetchProcess();
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  const saveChange = () => formik.handleSubmit();

  return (
    <>
      {flag ? (
        <FormikProvider value={formik}>
          <Grid container spacing={2} direction="column">
            <Grid item sx={12}>
              <br />
              <PageHeadline>編輯工序</PageHeadline>
              <PageSubHeadline>日期編輯</PageSubHeadline>
            </Grid>

            <Grid item sx={12} className={classes.div}>
              <label className={classes.label}>
                實際出貨日期：&nbsp;&nbsp;&nbsp;
              </label>
              <FormTextInput
                className={classes.root}
                name="delivery_date"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.delivery_date}
                label="實際出貨日期"
                InputLabelProps={{
                  shrink: true,
                }}
              ></FormTextInput>
            </Grid>

            <Grid item sx={12} className={classes.div}>
              <label className={classes.label}>
                鑽石out：&nbsp;&nbsp;&nbsp;
              </label>
              <FormTextInput
                className={classes.root}
                name="diamond_out"
                onChange={formik.handleChange}
                value={formik.values.diamond_out}
                label="鑽石out"
              ></FormTextInput>
            </Grid>

            <Grid item sx={12} className={classes.div}>
              <label className={classes.label}>
                寶石out：&nbsp;&nbsp;&nbsp;
              </label>
              <FormTextInput
                className={classes.root}
                name="gem_out"
                onChange={formik.handleChange}
                value={formik.values.gem_out}
                label="寶石out"
              ></FormTextInput>
            </Grid>
            <Grid item sx={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={saveChange}
                disabled={formik.isSubmitting}
              >
                保存
              </Button>
            </Grid>
          </Grid>
        </FormikProvider>
      ) : null}
    </>
  );
};

export default EditForm;
