//Detail Section Table Cell Column
const GOLDSTABLECELLS = [
  { name: "gold_style", label: "" },
  { name: "name", label: "成色" },
  { name: "weight", label: "重量" },
  { name: "price", label: "價格" },
  { name: "unit", label: "單位" },
];
const DIAMONDS1TABLECELLS = [
  { name: "name", label: "粒數" },
  { name: "weight", label: "重量" },
  { name: "price", label: "價格" },
  { name: "unit", label: "單位" },
];

const DIAMONDS2TABLECELLS = [
  { name: "name", label: "粒數" },
  { name: "weight", label: "重量" },
  { name: "price", label: "價格" },
  { name: "unit", label: "單位" },
];
const STONES1TABLECELLS = [
  { name: "name", label: "粒數" },
  { name: "weight", label: "重量" },
  { name: "price", label: "價格" },
  { name: "unit", label: "單位" },
];
const STONES2TABLECELLS = [
  { name: "name", label: "粒數" },
  { name: "weight", label: "重量" },
  { name: "price", label: "價格" },
  { name: "unit", label: "單位" },
];

const MOLDTABLECELLS = [{ name: "cost", label: "價錢" }];
const CUTTINGTABLECELLS = [{ name: "cost", label: "價錢" }];
const ENTABLECELLS = [{ name: "cost", label: "價錢" }];
const LABOURTABLECELLS = [{ name: "cost", label: "價錢" }];
const STONESETTING = [{ name: "setting_fee", label: "STONE SETTING" }];
const STONES1ETTING = [
  { name: "setting_multiplier", label: "setting_multiplier" },
  { name: "setting_fee", label: "setting_fee" },
];
const DIAMOND1DIASETTING = [
  { name: "setting_multiplier", label: "Dia setting_multiplier" },
  { name: "Dia setting_fee", label: "Dia setting_fee" },
];
const DIAMOND2DIASETTING = [{ name: "setting_fee", label: "Dia setting_fee" }];

export const DETAILSECTIONTABLECELL = {
  golds: GOLDSTABLECELLS,
  diamonds1tablecells: DIAMONDS1TABLECELLS,
  diamonds2tablecells: DIAMONDS2TABLECELLS,
  stones1tablecells: STONES1TABLECELLS,
  stones2: STONES2TABLECELLS,
  mold: MOLDTABLECELLS,
  cutting: CUTTINGTABLECELLS,
  en: ENTABLECELLS,
  labour: LABOURTABLECELLS,
  stone1setting: STONES1ETTING,
  stonesetting: STONESETTING,
  diamond1diasetting: DIAMOND1DIASETTING,
  diamond2diasetting: DIAMOND2DIASETTING,
};
