import {
  useFormik,
  FormikProvider,
  FieldArray,
  useFormikContext,
} from "formik";
import { useState, useEffect, Fragment, useContext, memo } from "react";
import * as Yup from "yup";
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Input,
  Chip,
  TableRow,
  TableCell,
  Select,
  MenuItem,
} from "@material-ui/core";
import AutoCompleteInput from "../../../component/AutoCompleteInput";
import FormSelectInput from "../../../component/Form/FormSelectInput";
import FormTextInput from "../../../component/Form/FormTextInput";
import FormCheckbox from "../../../component/Form/FormCheckbox";
import FormTable from "../../../component/Form/FormTable";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
  SERVER_URL,
} from "../../../helper/fetching";
import moment from "moment";
import { PageContext } from "../../../context/PageContext";
import ButtonWithLoading from "../../../component/ButtonWithLoading";
import InputsFormTable from "./InputsFormTable";

var fileDownload = require("js-file-download");

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  informationWrapper: {
    marginBottom: 20,
  },
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
  tableRoot: {
    marginTop: 20,
  },
  goldSection: {
    margin: "10px 0 0 0",
  },
  clientSimpleName: {
    padding: "10px 0 0 0",
  },
}));

const InvoiceTable = memo(({ invoice, ...rest }) => {
  const [format, setFormat] = useState("Default");
  const handleFormat = ({ target }) => setFormat(target.value);

  const [isLoading, setIsLoading] = useState(false);

  const exportInput = async (invoiceFormat = format) => {
    let formatInput = invoiceFormat === "MC" ? "?format=mc" : "";
    setIsLoading(true);
    try {
      const res = await apiRequest({
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
        responseType: "blob",
      }).get(
        `${API_ENDPOINT_PREFIX}/invoice/${invoice.id}/excel${formatInput}`
      );

      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      const date = moment().format("MM_DD_YYYY_hh_mm_ss");

      fileDownload(
        blob,
        `Invoice_${invoice.id}_${date}_${
          invoiceFormat === "MC" ? "(MC)" : "(DEFAULT)"
        }.xlsx`
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const saveInput = async (val = {}) => {
    const idx = invoice.inputs.findIndex((item) => item.id === val.id);
    const ids = invoice.default_remarks.map((item) => item.id);

    const inputs = invoice.inputs;
    Object.keys(val).forEach((key) => (inputs[idx][key] = val[key]));
    const data = { ...invoice, inputs, default_remark_ids: ids };

    try {
      const res = await apiRequest().put(
        `${API_ENDPOINT_PREFIX}/invoice/${invoice.id}`,
        data
      );
      // fetchPurchaseOrder();
    } catch (error) {
      console.log(error);
    }
  };

  const deleInput = async (id) => {
    const ids = invoice.default_remarks.map((item) => item.id);
    const inputs = invoice.inputs.filter((input) => input.id !== id);
    const data = { ...invoice, inputs, default_remark_ids: ids };
    try {
      const res = await apiRequest().put(
        `${API_ENDPOINT_PREFIX}/invoice/${invoice.id}`,
        data
      );
      // fetchPurchaseOrder();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <InputsFormTable
        rows={invoice.inputs}
        saveInput={saveInput}
        deleInput={deleInput}
        {...rest}
      />
      <br />
      {invoice.inputs.length ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Select
              id="format"
              labelId="format"
              value={format}
              onChange={handleFormat}
              label="Invoice format"
              fullWidth
              defaultValue="Default"
            >
              <MenuItem value="MC" name="MC format invoice">
                MC format invoice
              </MenuItem>
              <MenuItem value="Default" name="Default format invoice">
                Default format invoice
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <ButtonWithLoading
              variant="contained"
              color="primary"
              onClick={() => exportInput(format)}
              loading={isLoading}
            >
              {`Invoice Export ( ${format} )`}
            </ButtonWithLoading>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
});

const EditForm = ({ closeForm, ...rest }) => {
  const [showError, setShowError] = useState(false);
  const currentContext = useContext(PageContext);

  const currentInvoice = currentContext.rows.find(
    (row) => row.id === rest.entity.id
  );

  const [clients, setClients] = useState({});

  const getClient = async (newVal = rest.entity.client_id) => {
    try {
      const res = await apiRequest().get(`${API_ENDPOINT_PREFIX}/client`);
      setClients(res.data.data.find((client) => client.id === newVal) || {});
    } catch (error) {}
  };

  const [remark_ids, setRemark_ids] = useState([]);

  const getRemarkIDS = async () => {
    try {
      const res = await apiRequest().get(
        `${API_ENDPOINT_PREFIX}/invoice-remark`
      );
      setRemark_ids(
        res.data.data.map((remark_id) => ({
          label: remark_id.remark,
          value: remark_id.id,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    getClient();
    getRemarkIDS();
  }, []);

  const classes = useStyles();
  const formik = useFormik({
    initialValues: Object.keys(rest.entity).length
      ? {
          ...rest.entity,
          default_remark_ids: rest.entity.default_remarks.map(
            (remark) => remark.id
          ),
        }
      : {
          invoice_date: "",
          invoice_no: "",
          country: "",
          harmonized_code: "",
          mawb: "",
          additional_costs_cost: "",
          additional_costs_title: "",
          contains_ruby_stone: false,
          default_remark_ids: "",
          remark2_1: "",
          remark2_2: "",
          remark2_3: "",
          remark2_4: "",
          remark2_5: "",
          remark3: "",
        },

    validationSchema: Yup.object({
      invoice_date: Yup.date().required("required"),
      invoice_no: Yup.string().required("required"),
      country: Yup.string().required("required"),
      harmonized_code: Yup.string().required("required"),
      mawb: Yup.string().required("required"),
      default_remark_ids: Yup.array(),
      contains_ruby_stone: Yup.bool(),
      remark2_1: Yup.string().nullable(),
      remark2_2: Yup.string().nullable(),
      remark2_3: Yup.string().nullable(),
      remark2_4: Yup.string().nullable(),
      remark2_5: Yup.string().nullable(),
      remark3: Yup.string().nullable(),
    }),

    onSubmit: async (data, action) => {
      try {
        const res = await apiRequest().put(
          `${API_ENDPOINT_PREFIX}/invoice/${rest.entity.id}`,
          data
        );
        setShowError(false)
        action.resetForm();
        currentContext.fetchInvoice();
        closeForm();
      } catch (error) {
        console.log(error);
        setShowError(true)
      } finally {

      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const invoiceInfo = [
    {
      title: "To",
      value: clients?.name,
      name: "client_id",
      optionUrl: `${API_ENDPOINT_PREFIX}/client`,
      label: "客名",
      onChange: (client) => {
        getClient(client.id);
        formik.setFieldValue("client_id", client.id);
      },
      noOptionsText: "沒有選項",
      error: formik.errors?.client_id,
      helperText: formik.errors?.client_id,
      type: "select",
    },
    {
      title: "Date",
      value: formik.values.invoice_date,
      name: "invoice_date",
      type: "date",
    },
    { title: "Address1", value: clients?.address1 },
    {
      title: "Invoice No",
      value: formik.values.invoice_no,
      name: "invoice_no",
    },
    { title: "Address2", value: clients?.address2 },
    {
      title: "Harmonized Code",
      value: formik.values.harmonized_code,
      name: "harmonized_code",
    },
    { title: "Tel1", value: clients?.tel1 },
    {
      title: "Country of manufacture",
      value: formik.values.country,
      name: "country",
    },
    { title: "Tel2", value: clients?.tel2 },
    { title: "MAWB", value: formik.values.mawb, name: "mawb" },
    { title: "Email", value: clients?.email },
    { title: "Total Amount", value: formik.values.total_amount },
  ];

  const invoiceRemarks = [
    // ...formik.values.default_remarks.map((remark, idx) => ({
    //   title: `Default Remarks (${idx + 1})`,
    //   value: remark.remark,
    // })),
    { title: "Remark2_1", value: formik.values.remark2_1, name: "remark2_1" },
    { title: "Remark2_2", value: formik.values.remark2_2, name: "remark2_2" },
    { title: "Remark2_3", value: formik.values.remark2_3, name: "remark2_3" },
    { title: "Remark2_4", value: formik.values.remark2_4, name: "remark2_4" },
    { title: "Remark2_5", value: formik.values.remark2_5, name: "remark2_5" },
    { title: "Remark3", value: formik.values.remark3, name: "remark3" },
  ];

  return (
    <FormikProvider value={formik}>
      <Typography variant="h5" className={classes.formTitle}>
        Edit Invoice
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} spacing={2}>
          <Grid container>
            {invoiceInfo.map((item) => (
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      <span style={{ width: 200, lineHeight: "50px" }}>
                        {item.title} :
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {item?.name ? (
                      <>
                        {item?.type === "select" ? (
                          <AutoCompleteInput
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            {...item}
                            style={{ width: "80%" }}
                          />
                        ) : (
                          <FormTextInput
                            id={item.name}
                            label={item.title}
                            name={item.name}
                            type={item?.type ? item.type : "text"}
                            InputLabelProps={{ shrink: true }}
                            value={formik.values[item.name]}
                            onChange={formik.handleChange}
                            style={{ width: "80%" }}
                          />
                        )}
                      </>
                    ) : (
                      <Typography
                        style={{ width: 200, lineHeight: "50px" }}
                        variant="subtitle1"
                      >
                        {item.value}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <br />
          <br />
          <InvoiceTable invoice={currentInvoice} {...rest} />
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormSelectInput
                id="default_remark_ids"
                name="default_remark_ids"
                label="Default Remark Ids"
                labelId="default_remark_ids"
                multiple
                input={<Input />}
                getOptionLabel={(option) => (option ? option.remark : "")}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          remark_ids.find(
                            (remark_id) => remark_id.value === value
                          )?.label || "Error Label"
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                items={remark_ids}
                value={formik.values.default_remark_ids}
                onChange={formik.handleChange}
              />
            </Grid>

            {invoiceRemarks.map((item) => (
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <FormTextInput
                    label={item.title}
                    name={item.name}
                    rows={4}
                    value={item.value}
                    multiline
                    onChange={formik.handleChange}
                    checked={formik.values[item.name]}
                  />
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            Save Change
          </Button>
          {showError ? <span style={{ color: "red", padding: 10 }}>請查看製工單列表是否有字段未填寫</span> : null}
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

export default EditForm;
