import { Grid, TextField } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
} from "../../../../../helper/fetching";

function TableWithCheckBox({
  optionUrl,
  setWorkOrder = () => {},
  setSelectedOption,
}) {
  //option: all selection or filtered selection
  const [options, setOptions] = useState([]);
  //identifierSearchWord: use for identifier search field
  const [identifierSearchWord, setIdentifierSearchWord] = useState("");
  //stonesSearchWord: use for stone search field
  const [stonesSearchWord, setStonesSearchWord] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  //selectionModel: DataGrid selectionModel
  const [selectionModel, setSelectionModel] = useState([]);
  const formik = useFormikContext();

  const [pageInfo, setPageInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // 0-based page index
  const [pageSize, setPageSize] = useState(10); // Rows per page

  useEffect(() => {
    const fetchOptions = async () => {
      setLoading(true);
      const res = await apiRequest().get(
        `${optionUrl}?identifier=${identifierSearchWord}&initials=${stonesSearchWord}&page=${
          page + 1
        }&pageSize=${pageSize}`
      );
      const data = res.data.data;
      setOptions(data);
      setPageInfo(res.data.meta); // Update to use res.data.meta instead of res.meta
      setLoading(false);
    };
    fetchOptions();
  }, [identifierSearchWord, stonesSearchWord, page, pageSize]);

  const [goldTypes, setGoldTypes] = useState([]);

  useEffect(() => {
    const fetchGoldTypes = async () => {
      const res = await apiRequest().get(`${API_ENDPOINT_PREFIX}/gold-type`);

      const data = res.data;
      setGoldTypes(data);
    };

    fetchGoldTypes();
  }, []);

  useEffect(() => {
    // parseInt()-1 : is to deal with base different issue
    setSelectedOption(options[parseInt(selectionModel) - 1]);
    setSelectedRows(options[parseInt(selectionModel) - 1]);
  }, [selectionModel]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "identifier",
      headerName: "款號",
      flex: 1,
    },
    {
      field: "wrist_size",
      headerName: "手寸",
      flex: 1,
    },
    {
      field: "gold_style",
      headerName: "成色",
      flex: 1,
      valueGetter: (params) =>
        `${
          goldTypes.find((type) => type.id == params.row.gold_type_id)?.name ||
          ""
        }${params.row.gold_style || ""}`,
    },
    {
      field: "chain_length",
      headerName: "鏈長",
      flex: 1,
    },
    {
      field: "diamonds.stone_initial",
      headerName: "鑽石類",
      width: 250,
      valueGetter: (params) => {
        const diamonds = params.row.diamonds || [];
        var concatString = diamonds.reduce((accumulator, current) => {
          if (current.stone_initial === null) {
            current.stone_initial = "";
          }

          return accumulator.concat(current.stone_initial);
        }, "");
        return concatString ? concatString : "----";
      },
    },
    {
      field: "stones.stone_initial",
      headerName: "其他石類",
      width: 250,
      valueGetter: (params) => {
        const stones = params.row.stones || [];
        var concatString = stones.reduce((accumulator, current) => {
          if (current.stone_initial === null) {
            current.stone_initial = "";
          }

          return accumulator.concat(current.stone_initial);
        }, "");
        return concatString ? concatString : "----";
      },
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} md={2} lg={2}>
          <TextField
            label="款號"
            variant="outlined"
            margin="dense"
            value={identifierSearchWord}
            onChange={(event) => {
              setIdentifierSearchWord(() => event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={6} md={2} lg={2}>
          <TextField
            label="石類"
            variant="outlined"
            margin="dense"
            value={stonesSearchWord}
            onChange={(event) => {
              setStonesSearchWord(() => event.target.value);
            }}
          />
        </Grid>
      </Grid>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={options}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]} // Optional: Add more options for page size
          rowCount={pageInfo.total} // Set total row count
          pagination
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            setPage(0); // Reset to first page when page size changes
          }}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectionModel}
          loading={loading}
          //***  Multi-select function: for multi selection
          // onSelectionModelChange={
          //     (ids) => {
          //         const selectedIDs = new Set(ids);
          //         const selectedRowData = options.filter((option) => selectedIDs.has(option.id))
          //         setWorkOrder(selectedRowData)
          //         setSelectedRows(selectedRowData);
          //     }
          // }

          //***  Single-select function: for single selection
          onSelectionModelChange={(selection) => {
            //Get the latest selected option
            if (selection.length > 1) {
              const selectionSet = new Set(selectionModel);
              const result = selection.filter(
                (selectedRowId) => !selectionSet.has(selectedRowId)
              );
              setSelectionModel(result);
            } else {
              setSelectionModel(selection);
            }
          }}
        />
      </div>
    </>
  );
}

export default TableWithCheckBox;
