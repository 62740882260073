import * as Yup from "yup";
export const createFormSchema = {
    name: Yup.string().required("必填"),
    fake_name:Yup.string().required("必填"),
    email:Yup.string().email().required("必填"),
    tel1:Yup.string().required("必填"),
    tel2:Yup.string().required("必填"),
    fax:Yup.number().nullable(),
    address1:Yup.string().required("必填"),
    address2:Yup.string().nullable(),
    ups:Yup.string().required("必填"),
    stamp_image_id:Yup.number(),
} 
