import React, { useState, Fragment } from "react";
import {
  Button,
  makeStyles,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../helper/fetching";
import AutoCompleteInput from "../../component/AutoCompleteInput";
import ButtonWithLoading from "../../component/ButtonWithLoading";

var fileDownload = require("js-file-download");

const useStyles = makeStyles({
  root: {
    width: 300,
    margin: 2,
  },
});

const CardPage = () => {
  const classes = useStyles();

  const [workOrder, setWorkOrder] = useState({});
  const [cardInfo, setCardInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const handleChange = async (e) => {
    if (!e) return setCardInfo({});

    setWorkOrder(e);
    setIsLoading(true);
    try {
      const res = await apiRequest().get(
        `${API_ENDPOINT_PREFIX}/work-order/${e.id}/card`
      );
      setCardInfo(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrint = async () => {
    setIsLoadingButton(true);
    try {
      const res = await apiRequest({
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
        responseType: "blob",
      }).get(`${API_ENDPOINT_PREFIX}/work-order/${cardInfo.id}/exportCard`);

      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      fileDownload(blob, `Card_${cardInfo.id}.xlsx`);
    } catch (error) {
    } finally {
      setIsLoadingButton(false);
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <AutoCompleteInput
              style={{ width: 300, marginBottom: "1rem" }}
              id="word_order_id"
              getOptionLabel={(option) => {
                let stones = option.stones.reduce(
                  (previousValue, currentValue) => {
                    if (currentValue.stone_initial) {
                      return currentValue.stone_initial + " " + previousValue;
                    } else {
                      return "";
                    }
                  },
                  ""
                );
                // return option ? option.identifier + stones : "";
                return option ? option.id.toString().padStart(5, "0") : "";
              }}
              getOptionValue={(value) => (value ? value.id : "")}
              optionUrl={`${API_ENDPOINT_PREFIX}/work-order`}
              label="工單編號"
              name="work-order"
              onChange={handleChange}
              noOptionsText="沒有選項"
              // error={isErr}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            {Object.keys(cardInfo).length ? (
              !isLoading ? (
                <Fragment>
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        牌仔
                      </Typography>
                    </CardContent>
                  </Card>

                  <Card className={classes.root}>
                    <CardContent>
                      <Typography variant="body2" component="p">
                        {workOrder.identifier}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {workOrder.id_no}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {/* {cardInfo?.net} */}
                        NET
                      </Typography>
                    </CardContent>
                  </Card>

                  <Card className={classes.root}>
                    <CardContent>
                      <Typography variant="body2" component="p">
                        {cardInfo?.gold_type}
                        {cardInfo?.gold_style}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {cardInfo?.stones?.map((item, idx) => (
                          <div key={idx}>
                            <span>{item.qty}</span>&nbsp;
                            <span>{item.name}</span>&nbsp;
                            <span>{item.weight}</span>&nbsp;
                            <span>{item.unit}</span>&nbsp;
                          </div>
                        ))}
                      </Typography>
                    </CardContent>
                  </Card>

                  {cardInfo?.yours.length ? (
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                          Your
                        </Typography>
                        <Typography variant="body2" component="p">
                          {cardInfo?.yours.map((item, idx) => (
                            <div key={idx}>
                              {`${item.qty}  ${item.name}  ${item.weight} X ${item.price_code} = ${item.price}`}
                            </div>
                          ))}
                        </Typography>
                      </CardContent>
                    </Card>
                  ) : null}
                  <br />
                  <ButtonWithLoading
                    variant="contained"
                    color="primary"
                    onClick={handlePrint}
                    loading={isLoadingButton}
                  >
                    Print
                  </ButtonWithLoading>
                </Fragment>
              ) : (
                <>
                  <Skeleton
                    variant="rect"
                    width={300}
                    height={59}
                    animation="wave"
                  />
                  <br />
                  <Skeleton
                    variant="rect"
                    width={300}
                    height={100}
                    animation="wave"
                  />
                  <br />
                  <Skeleton
                    variant="rect"
                    width={300}
                    height={60}
                    animation="wave"
                  />
                </>
              )
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CardPage;
