import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import { useContext } from "react";
import FormTextInput from "../../../../component/Form/FormTextInput";
import * as Yup from "yup";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
  convertToFormData,
} from "../../../../helper/fetching";
import { PageContext } from "../../../../context/PageContext";
import UploadImageArea from "../../../../component/UploadImageArea";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  informationWrapper: {
    marginBottom: 20,
  },
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
}));

const DeleteForm = ({ entity: product, closeForm, ...rest }) => {
  const classes = useStyles();

  const { fetchProducts } = useContext(PageContext);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (data, { setSubmitting }) => {
      try {
        const res = await apiRequest().delete(
          `${API_ENDPOINT_PREFIX}/work-order/${product.id}`
        );

        const json = res.data;

        closeForm();
      } catch (err) {
      } finally {
        await fetchProducts();

        setSubmitting(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <div {...rest}>
        <div>
          <Typography variant="h3" className={classes.formTitle}>
            刪除製工單
          </Typography>
          <Typography variant="h5" className={classes.formTitle}>
            製工單詳情
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <UploadImageArea defaultImage={product.images} disabled />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormTextInput
                variant="outlined"
                id="name"
                label="款號"
                defaultValue={product.identifier}
                className={classes.input}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <FormTextInput
                variant="outlined"
                id="id"
                label="工單編號"
                defaultValue={product.id.toString().padStart(5, "0")}
                className={classes.input}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <FormTextInput
                variant="outlined"
                id="id_no"
                label="編號"
                defaultValue={product.id_no}
                className={classes.input}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                刪除
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </FormikProvider>
  );
};

export default DeleteForm;
