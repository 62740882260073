import { makeStyles, TextField } from "@material-ui/core";
import clsx from "clsx";
import { useFormikContext } from "formik";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    width: "100%",
  },
}));

const FormTextInput = ({
  variant = "outlined",
  margin = "dense",
  name,
  required = false,
  ...rest
}) => {
  const classes = useStyles();
  const formik = useFormikContext();

  return (
    <TextField
      variant={variant}
      margin={margin}
      name={name}
      error={formik.errors[name]}
      helperText={formik.errors[name]}
      className={clsx([classes.root])}
      required={required}
      disabled={false}
      {...rest}
    />
  );
};

export default FormTextInput;
