import { FormikProvider, useFormik } from "formik";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../../helper/fetching";
import PoBasicSection from "../../FormSection/PoBasicSection";
import PoWorkOrderSection from "../../FormSection/PoWorkOrderSection";
import { initialValues } from "../../Constants";
import PoQuotationSection from "../../FormSection/PoQuotationSection";
import { useEffect, useState } from "react";
import { poWorkOrderColumns, poQuotationColumns } from "../../Constants";
import useFetch from "../../../../hook/useFetch";
import LoadingIcon from "../../../../component/LoadingIcon";

function EditForm({ entity: purchaseOrder, closeForm, ...rest }) {
  const {
    entities,
    isLoading,
    fetchEntity,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/purchase-order`,
    paged: true,
    initFilter: {po_no: purchaseOrder.po_no}
  });

  const fetchCurrentPO = purchaseOrder.po_no ? fetchEntity : () => {};

  const currentRow = entities.find(entity => entity.id === purchaseOrder.id)

  const formik = useFormik({
    initialValues: currentRow,
    enableReinitialize: true,

    onSubmit: async (data, { setSubmitting }) => {
      const formattedData = {
        ...data,
        client_id: data.client.id,
      };

      try {
        const res = await apiRequest().put(
          `${API_ENDPOINT_PREFIX}/purchase_order`,
          formattedData
        );

        const json = res.data;

        formik.resetForm();
        closeForm();
      } catch (err) {
      } finally {
        //   await fetchQuotations();

        setSubmitting(false);
      }
    },
  });

  const [PO_ID, setPO_ID] = useState(true);
  const workOrderSearchKeys = {
    identifier: {
      label: "款號",
      value: "",
    },
    initials: {
      label: "石類",
      value: "",
    },
  };

  const QuotationSearchKeys = {
    identifier: {
      label: "款號",
      value: "",
    },
  };

  return (
    <>
      {
        !isLoading ? 
        <FormikProvider value={{...formik, fetchCurrentPO}}>
        <div {...rest}>
          <div>
            <PoBasicSection setPO_ID={setPO_ID} />
            
            {PO_ID && (
              <PoQuotationSection
                PO_ID={PO_ID}
                searchKeys={QuotationSearchKeys}
                columns={poQuotationColumns}
                fetchCurrentPO={fetchCurrentPO}
              />
            )}
  
            {PO_ID && (
              <PoWorkOrderSection
                PO_ID={PO_ID}
                searchKeys={workOrderSearchKeys}
                columns={poWorkOrderColumns}
                fetchCurrentPO={fetchCurrentPO}
              />
            )}
          </div>
        </div>
        </FormikProvider> : 
        <div style={{width: '100%', height: '50vh', display: 'flex' ,justifyContent: 'center', alignItems: 'center'}}>
          <LoadingIcon /> <span>&nbsp;&nbsp;&nbsp;</span> Loading...
        </div>
      }
    </>
  );
}

export default EditForm;
