import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Tooltip,
  Box,
} from "@material-ui/core";
import React, { useState } from "react";
import CreateButton from "../../../component/PopupWidget/CreateButton";

function PopupWidget(props) {
  //* This Component is a popup window container only, passing children component for popping-up the child component
  const {
    dialogTitle,
    createTitle,
    customStyle,
    handleSubmit = () => {},
    confirmButtonText = "加入",
    toolTipTitle = "",
    onOpen = () => {},
    onClose = () => {},
    fullWidth = true,
    buttonSize = "medium",
    startIcon = () => {},
  } = props;

  //* open: the state of the popup window, true: window open, false: window close
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    onOpen();
    setOpen(true);
  };

  const handleClose = () => {
    onClose()
    setOpen(false);
  };

  const saveAndClose = () => {
    handleSubmit();
    setOpen(false);
  };

  const useStyles = makeStyles({
    dialogContent: {
      padding: "20px !important",
      width: "500px"
    },
  });
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={"xl"}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>{dialogTitle}</DialogTitle>

            <DialogContent className={classes.dialogContent}>
              {props.children}
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>取消</Button>
              <Button onClick={saveAndClose}>{confirmButtonText}</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <Box display="inline-block">
        <Tooltip title={toolTipTitle} arrow>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            color="primary"
            className={classes.searchButton}
            size={buttonSize}
            startIcon={startIcon}
          >
            {dialogTitle}
          </Button>
        </Tooltip>
      </Box>
    </>
  );
}
export default PopupWidget;
