import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import FormTextInput from "../../../../../component/Form/FormTextInput";

const QuotationRemarkSection = () => {
  const formik = useFormikContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormTextInput
          variant="outlined"
          id="remark1"
          label="備註1"
          multiline
          rows={4}
          name="remark1"
          onChange={formik.handleChange}
          value={formik.values.remark1}
        />
      </Grid>

      <Grid item xs={12}>
        <FormTextInput
          variant="outlined"
          id="remark2"
          label="備註2"
          multiline
          rows={4}
          name="remark2"
          onChange={formik.handleChange}
          value={formik.values.remark2}
        />
      </Grid>
    </Grid>
  );
};

export default QuotationRemarkSection;
