import { Fragment, useContext, useState } from "react";
import { useFormikContext, FieldArray } from "formik";
import FormTextInput from "../../../component/Form/FormTextInput";
import {
  Grid,
  Button,
  makeStyles,
  TableCell,
  TableRow,
} from "@material-ui/core";
import PageSubHeadline from "../../../component/PageSubHeadline";
import FormTable from "../../../component/Form/FormTable";
import { PageContext } from "../../../context/PageContext";

const useStyles = makeStyles((theme) => ({
  tableRow: {},
  tableCell: {
    width: "100%",
  },
  button: {
    margin: "0 0 20px 0",
  },
}));

const DiamondTableRow = ({ index, arrayHelpers, value, ...rest }) => {
  const formik = useFormikContext();

  const arrayFieldName = "diamonds";

  const classes = useStyles();

  return (
    <TableRow {...rest} className={classes.tableRow}>
      <TableCell>{value.id}</TableCell>
      {/* <TableCell>
        <FormTextInput
          name={`${arrayFieldName}.${index}.invoice_input_id`}
          value={value.invoice_input_id}
          type="number"
          onChange={formik.handleChange}
          required
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].invoice_input_id &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].invoice_input_id
          }
        />
      </TableCell> */}
      <TableCell>{value.invoice_input_id}</TableCell>

      <TableCell>
        <FormTextInput
          name={`${arrayFieldName}.${index}.stone_full_name`}
          value={value.stone_full_name}
          onChange={formik.handleChange}
          required
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_full_name &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_full_name
          }
        />
      </TableCell>

      <TableCell>
        <FormTextInput
          name={`${arrayFieldName}.${index}.qty`}
          value={value.qty}
          type="number"
          onChange={formik.handleChange}
          required
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].qty &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].qty
          }
        />
      </TableCell>

      <TableCell>
        <FormTextInput
          name={`${arrayFieldName}.${index}.stone_initial`}
          value={value.stone_initial}
          onChange={formik.handleChange}
          required
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_initial &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_initial
          }
        />
      </TableCell>

      <TableCell>
        <FormTextInput
          name={`${arrayFieldName}.${index}.unit`}
          value={value.unit}
          onChange={formik.handleChange}
          required
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].unit &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].unit
          }
        />
      </TableCell>

      <TableCell>
        <FormTextInput
          name={`${arrayFieldName}.${index}.weight`}
          value={value.weight}
          onChange={formik.handleChange}
          required
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].weight &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].weight
          }
        />
      </TableCell>

      <TableCell>
        <Button onClick={() => arrayHelpers.remove(index)}>X 刪除</Button>
      </TableCell>
    </TableRow>
  );
};

const DiamondsTable = ({ arrayHelpers, ...rest }) => {
  const tableHeader = [
    "ID",
    "Invoice Input Id",
    "Stone Full Name ( 全名 )",
    "QTY ( 粒數 )",
    "Stone Initial ( 石類 )",
    "Unit ( 單位 )",
    "Weight ( 重量 )",
    "Action",
  ];

  const formik = useFormikContext();

  const classes = useStyles();

  const handleAddDiamond = () =>
    arrayHelpers.push({
      id: null,
      invoice_input_id: "",
      qty: "",
      stone_initial: "",
      unit: "",
      weight: "",
      created_at: null,
      updated_at: null,
    });

  const renderRows = () =>
    formik.values.diamonds?.map((diamond, index) => (
      <DiamondTableRow
        key={index}
        index={index}
        arrayHelpers={arrayHelpers}
        value={diamond}
      />
    ));

  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddDiamond}
        className={classes.button}
      >
        新增
      </Button>

      <FormTable headerNames={tableHeader} renderRows={renderRows} />
    </Fragment>
  );
};

const EditInput = (props) => {
  const formik = useFormikContext();
  const { fetchInvoice } = useContext(PageContext);

  const [deleteLoading, setDeleteLoading] = useState(false);
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormTextInput
          name="client_nickname"
          label="Client Nickname"
          onChange={formik.handleChange}
          value={formik.values.client_nickname}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextInput
          name="item_no"
          label="Item No"
          onChange={formik.handleChange}
          value={formik.values.item_no}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextInput
          name="aa_no"
          label="aa_no"
          onChange={formik.handleChange}
          value={formik.values.aa_no}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextInput
          name="style_no"
          label="style_no"
          onChange={formik.handleChange}
          value={formik.values.style_no}
        />
      </Grid>

      {/* <Grid item xs={12}>
        <FormTextInput
          name="description"
          label="description"
          minRows={4}
          multiline={true}
          onChange={formik.handleChange}
          value={formik.values.description}
        />
      </Grid> */}

      <Grid item xs={12}>
        <PageSubHeadline>Diamonds</PageSubHeadline>
        <FieldArray
          name="diamonds"
          render={(arrayHelpers) => (
            <DiamondsTable arrayHelpers={arrayHelpers} />
          )}
        ></FieldArray>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => {
            formik.handleSubmit();
            if (Object.keys(formik.errors).length) return;
            props.closeForm();
          }}
          disabled={formik.isSubmitting}
        >
          Save
        </Button>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <Button
          variant="contained"
          color="secondary"
          disabled={deleteLoading}
          onClick={async () => {
            try {
              setDeleteLoading(true);
              props.deleInput(formik.values.id);
              await fetchInvoice();
              props.closeForm();
            } catch (error) {
            } finally {
              setDeleteLoading(false);
            }
          }}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditInput;
