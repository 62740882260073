import { Grid, TextField } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { apiRequest } from "../../helper/fetching";

function TableWithCheckBox({
  optionUrl,
  searchKeys = {},
  setSelectedOption,
  searchFieldLabel = "no label",
  columns,
  paginationMode = "server",
}) {
  //*Options is all option that fetching from backend
  const [options, setOptions] = useState([]);

  //*SelectionModel is to record the option's id that you ticked in frontend table
  const [selectionModel, setSelectionModel] = useState([]);

  //*SearchWords is an array contain the search key for filter the table
  const [searchWords, setSearchWords] = useState(searchKeys);

  const [rowCount, setRowCount] = useState(null);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // 0-based page index
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [pageInfo, setPageInfo] = useState({}); // Page information

  //*This useEffect is to fetch filtered data

  const fetchOptions = async (page = 1) => {
    const params = new URLSearchParams({
      page,
      pageSize,
    });

    Object.keys(searchWords).forEach((keyword) => {
      if (keyword) {
        params.append(keyword, searchWords[keyword].value);
      }
    });
    setLoading(true);
    setOptions([]);
    const res = await apiRequest().get(`${optionUrl}?${params.toString()}`);
    const data = res.data.data;
    setRowCount(res.data.meta.total); // Set the total row count
    setPageInfo(res.data.meta); // Set pageInfo with the meta information

    setOptions(data);
    setLoading(false);
  };

  useEffect(() => fetchOptions(page + 1), [searchWords, page, pageSize]);

  //*This useEffect is to set selected objectid i.e the option user ticked in frontend
  useEffect(() => {
    const tickedOption = options.filter(
      (option) => option.id == selectionModel[0]
    );
    setSelectedOption(tickedOption[0]);
  }, [selectionModel]);

  const onPageChange = async (newPage) => {
    setPage(newPage);
  };

  const onPageSizeChange = async (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* key word array */}
        {Object.keys(searchWords).map((searchKeyWord, index) => {
          return (
            <Grid item xs={6} md={2} lg={2} key={index}>
              <TextField
                label={searchWords[searchKeyWord].label}
                variant="outlined"
                margin="dense"
                value={searchWords[searchKeyWord].value}
                onChange={(event) => {
                  setSearchWords({
                    ...searchWords,
                    [searchKeyWord]: {
                      label: searchWords[searchKeyWord].label,
                      value: event.target.value,
                    },
                  });
                }}
              />
            </Grid>
          );
        })}
      </Grid>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={options}
          columns={columns}
          pageSize={pageSize}
          rowCount={rowCount}
          rowsPerPageOptions={[10]}
          pagination
          paginationMode={paginationMode}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            setPage(0); // Reset to first page when page size changes
          }}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectionModel}
          loading={loading}
          //***  Multi-select function ***
          //*** if the table need to select more than one options, use the below code ****/
          // onSelectionModelChange={
          //     (ids) => {
          //         const selectedIDs = new Set(ids);
          //         const selectedRowData = options.filter((option) => selectedIDs.has(option.id))
          //         setWorkOrder(selectedRowData)
          //         setSelectedRows(selectedRowData);
          //     }
          // }
          //***End of  Multi-select function ***

          //***  Single-select function ***
          //*** if the table need to select only one option, use the below code ****/
          onSelectionModelChange={
            (selection) => {
              //Get the latest selected option
              if (selection.length > 1) {
                const selectionSet = new Set(selectionModel);
                //result is the id array,which is one element in this array, of the ticked object
                const result = selection.filter(
                  (selectedRowId) => !selectionSet.has(selectedRowId)
                );
                setSelectionModel(result);
              } else {
                setSelectionModel(selection);
              }
            }
            //***End of Single-select function ***
          }
        />
      </div>
    </>
  );
}

export default TableWithCheckBox;
