import { useState, Fragment, useMemo } from "react";
import { API_ENDPOINT_PREFIX, apiRequest } from "../../helper/fetching";
import useFetch from "../../hook/useFetch";
import {
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import DataTable from "../../component/DataTable";

import ButtonWithLoading from "../../component/ButtonWithLoading";

import ComponentLayout from "../../layout/EntityMangeLayout/ComponentLayout";

import Createform from "./Component/Createform";

var fileDownload = require("js-file-download");

const DiamondDeductionTable = (props) => {
  const [genExlErr, setGenExlErr] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [clickID, setClick] = useState("");
  const formik = useFormikContext();

  //SAVE HANDEL
  const handleSave = async (params) => {
    setClick(params.id);
    // api/diamond-deduction/{diamond deduction id}
    setLoadingSave(true);
    try {
      const res = await apiRequest().put(
        `${API_ENDPOINT_PREFIX}/diamond-deduction/${params.row.id}`,
        params.row
      );
      formik.fetchDiamondDeduction();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSave(false);
    }
  };

  //DEL HANDEL
  const handleDel = async (params) => {
    setClick(params.id);
    setLoadingDel(true);
    try {
      const res = await apiRequest().delete(
        `${API_ENDPOINT_PREFIX}/diamond-deduction/${params.row.id}`
      );
      formik.fetchDiamondDeduction();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDel(false);
    }
  };

  //SEARCH HANDEL
  const searchDiamondDeduction = () => {
    setGenExlErr("");
    Object.keys(filterSearch).forEach((key) => {
      if (filterSearch[key] === "" || filterSearch[key] === undefined)
        delete filterSearch[key];
    });
    formik.handleFilterChange(filterSearch);
  };

  //EXPORT HANDEL
  const exportExl = async () => {
    setGenExlErr("");

    Object.keys(filterSearch).forEach((key) => {
      if (filterSearch[key] === "" || filterSearch[key] === undefined)
        delete filterSearch[key];
    });

    if (!filterSearch.year || !filterSearch.month) {
      setGenExlErr("Required");
    } else {
      setGenExlErr("");
      const exportField = new URLSearchParams(filterSearch).toString();
      try {
        const res = await apiRequest({
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
          responseType: "blob",
        }).get(
          `${API_ENDPOINT_PREFIX}/diamond-deduction/exportExcel?${exportField}`
        );

        var blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        const date = Object.entries(filterSearch)
          .reduce((pre, cur) => {
            return pre + cur[0] + "-" + cur[1] + "/";
          }, "")
          .slice(0, -1);

        fileDownload(blob, `DiamondDeduction-${date}.xlsx`);
      } catch (error) {
        console.log(error);
      } finally {
      }
    }
  };

  //IN count
  const inCount = useMemo(() => {
    return formik.values.diamonds.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        (currentValue.type === "IN" ? Number(currentValue.amount) : 0)
      );
    }, 0);
  }, [formik.values.diamonds]);

  //OUT count
  const outCount = useMemo(() => {
    return formik.values.diamonds.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        (currentValue.type === "OUT" ? Number(currentValue.amount) : 0)
      );
    }, 0);
  }, [formik.values.diamonds]);

  //Toolbar COMPONENT
  const Toolbar = (e) => {
    return (
      <Box
        display="flex"
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          padding: "0.5rem 0 ",
        }}
      >
        <Box flex="1"></Box>
        <Box flex="1"></Box>
        <Box flex="1"></Box>
        <Box flex="1"></Box>
        <Box flex="1"></Box>
        <Box flex="1">
          <Typography variant="h6">入 / IN：{inCount}</Typography>
        </Box>
        <Box flex="1">
          <Typography variant="h6">出 / OUT：{outCount}</Typography>
        </Box>
        <Box flex="1">
          <Typography variant="h6">總：{inCount - outCount}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          xs={12}
        >
          <ComponentLayout
            renderHeader={(props) => (
              <Button
                variant="contained"
                color="primary"
                onClick={props.openForm}
              >
                新建
              </Button>
            )}
            renderCreateForm={(props) => <Createform {...props} />}
          />
          <Box>
            <Typography style={{ lineHeight: "36.5px" }}>
              <TextField
                style={{ width: 70 }}
                select
                value={filterSearch.type}
                onChange={(e) => {
                  setFilterSearch((state) => ({
                    type: e.target.value,
                    year: state.year,
                    month: state.month,
                  }));
                }}
              >
                <MenuItem value="">BOTH</MenuItem>
                <MenuItem value="IN">IN</MenuItem>
                <MenuItem value="OUT">OUT</MenuItem>
              </TextField>
              TYPE &nbsp;&nbsp;
              <TextField
                style={{ width: 70 }}
                type="number"
                value={filterSearch.year}
                onChange={(e) => {
                  setFilterSearch((state) => ({
                    type: state.type,
                    year: e.target.value,
                    month: state.month,
                  }));
                }}
                error={genExlErr}
                helperText={genExlErr}
              ></TextField>
              年 &nbsp;&nbsp;
              <TextField
                style={{ width: 50 }}
                type="number"
                value={filterSearch.month}
                onChange={(e) => {
                  setFilterSearch((state) => ({
                    type: state.type,
                    year: state.year,
                    month: e.target.value,
                  }));
                }}
                error={genExlErr}
                helperText={genExlErr}
              ></TextField>
              月 &nbsp;&nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={searchDiamondDeduction}
              >
                查找
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={exportExl}
                disabled={!formik.values.diamonds.length}
              >
                EXPORT
              </Button>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataTable
            rows={formik.values.diamonds}
            components={{ Toolbar }}
            {...props}
            height="calc(100vh - 180px)"
            columns={[
              {
                field: "record_date",
                headerName: "Date",
                flex: 1,
              },
              {
                field: "company_name",
                headerName: "公司",
                flex: 1,
              },
              {
                field: "ref_no",
                headerName: "Reference No",
                flex: 1,
                editable: true,
              },
              {
                field: "id_no",
                headerName: "訂單號",
                flex: 1,
              },
              {
                field: "identifier",
                headerName: "款號",
                flex: 1,
              },
              {
                field: "type_in",
                headerName: "入/IN",
                flex: 1,
                valueGetter: (params) =>
                  params.row.type === "IN" ? params.row.amount : "---",
              },
              {
                field: "type_out",
                headerName: "出/OUT",
                flex: 1,
                valueGetter: (params) =>
                  params.row.type === "OUT" ? params.row.amount : "---",
              },
              {
                field: "action",
                headerName: "動作",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <>
                      <ButtonWithLoading
                        variant="contained"
                        color="primary"
                        loading={params.id === clickID && loadingSave}
                        onClick={() => handleSave(params)}
                      >
                        保存
                      </ButtonWithLoading>
                      &nbsp;&nbsp;
                      <ButtonWithLoading
                        variant="contained"
                        color="primary"
                        loading={params.id === clickID && loadingDel}
                        onClick={() => handleDel(params)}
                      >
                        刪除
                      </ButtonWithLoading>
                    </>
                  );
                },
              },
            ]}
          ></DataTable>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const DiamondDeductionPage = () => {
  const {
    entities: diamondDeduction,
    isLoading,
    fetchEntity: fetchDiamondDeduction,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/diamond-deduction`,
    paged: true,
  });

  const formik = useFormik({
    initialValues: { diamonds: diamondDeduction },
    enableReinitialize: true,
    onSubmit: async (data, { setSubmitting }) => {
      console.log(data);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <FormikProvider
      value={{ ...formik, fetchDiamondDeduction, handleFilterChange }}
    >
      <Grid container>
        <Grid item xs={12}>
          <DiamondDeductionTable
            onPageChange={setPage}
            totalNumberOfRows={total}
            paginationMode="server"
            pageSize={pageSize}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

export default DiamondDeductionPage;
