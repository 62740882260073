import { useState, useEffect } from "react";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../helper/fetching";
import useFetch from "../../hook/useFetch";
import DataTable from "../../component/DataTable";
import PopupWidget from "../../component/PopupWidget";
import { format } from "date-fns";

import { TextField, Button, Grid } from "@material-ui/core";

import AutoCompleteInput from "../../component/AutoCompleteInput";

import moment from "moment";

var fileDownload = require("js-file-download");

const GenPage = (params) => {
  const [genConfig, setGenConfig] = useState({
    factory_id: "",
    gold_type_id: "",
  });

  return (
    <PopupWidget
      dialogTitle="PourGold"
      fullWidth={false}
      confirmButtonText="確定"
      buttonSize="small"
      handleSubmit={async () => {
        Object.keys(genConfig).forEach((key) => {
          if (genConfig[key] === "" || genConfig[key] === undefined)
            delete genConfig[key];
        });
        const searchString = new URLSearchParams(genConfig).toString();

        try {
          const res = await apiRequest({
            headers: {
              "Content-Type": "*/*",
              Accept: "*/*",
            },
            responseType: "blob",
          }).get(
            `${API_ENDPOINT_PREFIX}/purchase-order/${params.row.id}/pour-gold-export?${searchString}`
          );

          var blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });

          const date = moment().format("MM_DD_YYYY_hh_mm_ss");

          fileDownload(blob, `PourGold_${date}.xlsx`);
        } catch (error) {
          console.log(error);
        } finally {
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AutoCompleteInput
            id="factory_id"
            getOptionLabel={(option) => (option ? option.name : "")}
            optionUrl={`${API_ENDPOINT_PREFIX}/factory`}
            label="廠方"
            name="factory_id"
            onChange={(e) =>
              setGenConfig((state) => ({ ...state, factory_id: e.id }))
            }
            noOptionsText="沒有選項"
            style={{ width: 200 }}
          />
          &nbsp;&nbsp;&nbsp;
        </Grid>
        <Grid item xs={6}>
          <AutoCompleteInput
            id="gold_type_id"
            getOptionLabel={(option) => (option ? option.name : "")}
            optionUrl={`${API_ENDPOINT_PREFIX}/gold-type`}
            label="成色"
            name="gold_type_id"
            onChange={(e) =>
              setGenConfig((state) => ({ ...state, gold_type_id: e.id }))
            }
            noOptionsText="沒有選項"
            style={{ width: 200 }}
          />
        </Grid>
      </Grid>
    </PopupWidget>
  );
};

const PourGoldPage = () => {
  const columns = [
    {
      field: "po_no",
      headerName: "PO",
      flex: 1,
    },
    {
      field: "client",
      headerName: "client name",
      flex: 1,
      valueGetter: (params) => params.value.name,
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      valueGetter: (params) => format(new Date(params.value), "MM-dd-yyyy"),
    },
    {
      field: "action",
      headerName: "動作",
      flex: 1,
      renderCell: (params) => GenPage(params),
    },
  ];

  const {
    entities: purchaseOrder,
    isLoading,
    fetchEntity: fetchPurchaseOrder,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/purchase-order`,
    paged: true,
    throttleTime: 500,
  });

  return (
    <>
      <TextField
        label="搜索訂單"
        variant="outlined"
        onChange={(e) => handleFilterChange({ po_no: e.target.value })}
      />
      <DataTable
        rows={purchaseOrder}
        columns={columns}
        onPageChange={setPage}
        paginationMode="server"
        pageSize={pageSize}
        loading={isLoading}
        rowCount={total}
        height={"calc(100vh - 200px)"}
      />
    </>
  );
};

export default PourGoldPage;
