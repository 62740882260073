import { useState, useEffect } from "react";

const useRandomString = (length = 6) => {
  const [randomString, setRandomString] = useState("");

  useEffect(() => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setRandomString(result);
  }, [length]);

  return randomString;
};

export default useRandomString;
