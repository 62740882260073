import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  FieldArray,
  FormikProvider,
  useFormik,
  useFormikContext,
  Form,
} from "formik";
import { Fragment, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import AutoCompleteInput from "../../../../component/AutoCompleteInput";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
  SERVER_URL,
} from "../../../../helper/fetching";
import CloseIcon from "@material-ui/icons/Close";
import FormTextInput from "../../../../component/Form/FormTextInput";
import ButtonWithLoading from "../../../../component/ButtonWithLoading";
import { PageContext } from "../../../../context/PageContext";
import ProductRowCellSection from "./FormSection/ProductRowCellSection";
import QuotationRemarkSection from "./FormSection/QuotationRemarkSection";
import TextField from "@material-ui/core/TextField";
import { createFormSchema } from "./Schema/CreateFormSchema";
import ErrorMessage from "../../../../component/ErrorMessage";
import CreateNewWorkOrderSection from "./FormSection/CreateNewWorkOrderSection";
import AdditionalCostSection from "./FormSection/AdditionalCostSection";
import MarkUpSection from "./FormSection/MarkUpSection";
import UploadImageArea from "../../../../component/UploadImageArea";
import PopupWidget from "../../../../component/PopupWidget";
import TableWithCheckBox from "./TableWithCheckBox";
import DetailSection from "./FormSection/DetailSection/DetailSection";
import QuotationPriceSection from "./FormSection/QuotationPriceSection/QuotationPriceSection";
import SearchSection from "./FormSection/SearchSection";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  informationWrapper: {
    marginBottom: 20,
  },
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
  tableRoot: {
    marginTop: 20,
  },
  goldSection: {
    margin: "10px 0 0 0",
  },
  clientSimpleName: {
    padding: "10px 0 0 0",
  },
}));

const GoldPriceSection = () => {
  const formik = useFormikContext();
  const classes = useStyles();
  return (
    <Grid container className={classes.goldSection}>
      <Grid item xs={3}>
        <FormTextInput
          name={`gold_price`}
          label="金價"
          value={formik.values.gold_price}
          onChange={formik.handleChange}
          type={"number"}
          required={true}
          validateOnChange={false}
        />
      </Grid>
    </Grid>
  );
};

const HandleByAndDateSection = () => {
  const formik = useFormikContext();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormTextInput
            name={`handle_by`}
            label="經手人"
            value={formik.values.handle_by}
            onChange={formik.handleChange}
            required={true}
            validateOnChange={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="date"
            label="提交日期"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            name={`quotation_order_date`}
            value={formik.values.quotation_order_date}
            onChange={formik.handleChange}
            required={true}
            error={formik.errors.quotation_order_date}
            helperText={formik.errors.quotation_order_date}
          />
        </Grid>
      </Grid>
    </>
  );
};

const WorkOrderSection = ({ arrayHelpers, ...rest }) => {
  //moduleData use to submit to api
  const [moduleData, setModuleData] = useState("");

  //selectedOption is for storing search result
  const [selectedOption, setSelectedOption] = useState("");

  const formik = useFormikContext();
  const classes = useStyles();

  const quotationCellObj = {
    quotation_item: "new item",
    quotation_qutity: 0,
    quotation_price: 0,
  };

  const handleAddItem = () => {
    arrayHelpers.push(quotationCellObj);
  };

  const otherItemObj = {
    itemName: "",
    price: 0,
  };

  const handleAddOtherItem = () => {
    arrayHelpers.push(otherItemObj);
  };

  const [openWorkOrderForm, setOpenWorkOrderForm] = useState(false);

  //Toggle Work Order Form
  const toggleWorkOrderForm = () => {
    setOpenWorkOrderForm(!openWorkOrderForm);
  };

  //Adding blank work order
  const handleOpenWorkOrderForm = () => {
    toggleWorkOrderForm();
  };

  return (
    <Fragment>
      <Grid container alignItems="center" spacing={3}>
        <Grid item>
          <PopupWidget
            dialogTitle="搜索報價單"
            // handleSubmit={handleAddQuotation}
          >
            <TableWithCheckBox
              optionUrl={`${API_ENDPOINT_PREFIX}/quotation/template`}
              setSelectedOption={setSelectedOption}
              mouleData={moduleData}
              setModuleData={setModuleData}
            />
          </PopupWidget>
        </Grid>

        {/* <Grid item >
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenWorkOrderForm}
          >
            新增空白製工單
          </Button>
        </Grid> */}
      </Grid>

      {/* ↓↓↓ ProductRowCellSection is the work order section ↓↓↓  */}
      {/* <ProductRowCellSection arrayHelpers={arrayHelpers} /> */}

      <CreateNewWorkOrderSection
        arrayHelpers={arrayHelpers}
        openWorkOrderForm={openWorkOrderForm}
        toggleWorkOrderForm={toggleWorkOrderForm}
        client={formik.values.client}
      />

      {typeof formik.errors.existProducts === "string" ? (
        <ErrorMessage errorMessage={formik.errors.existProducts} />
      ) : (
        ""
      )}
    </Fragment>
  );
};

const CreateForm = ({
  closeForm,
  callInfo = {
    by: "",
    id: "",
    setValueForTable: () => {},
    default_gold_price: null,
  },
  ...rest
}) => {
  // const [workOrderFieldValue, setWorkOrderFieldValue] = useState({});
  const classes = useStyles();
  const { fetchQuotations, fetchPurchaseOrder } = useContext(PageContext);
  const [preSetValues, setPreSetValues] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const mark_up = 21.045;

  const formik = useFormik({
    initialValues: preSetValues
      ? callInfo.default_gold_price
        ? {
            ...preSetValues,
            gold_price: callInfo.default_gold_price,
          }
        : preSetValues
      : {
          remark1: "",
          remark2: "",
          handle_by: "",
          quotation_order_date: "",
          customer_item_no: null,
          mark_up: mark_up,
          gold_price: callInfo.default_gold_price || 1847,
          diamond1: [],
          diamond2: [],
          stones1: [],
          stones2: [],
          mold: [],
          cutting: [],
          en: [],
          labour: [],
          other: [],
          // po_no: "",
        },

    // initialValues: testValues,
    validationSchema: Yup.object().shape(createFormSchema),
    enableReinitialize: true,
    onSubmit: async (data, { setSubmitting }) => {
      const formattedData = {
        ...data,
        // client_id: data.client.id,
        image_id: data.images?.id || null,
        golds: data.golds || [],
      };

      try {
        let url =
          callInfo.by === "Purchase-Order"
            ? `${API_ENDPOINT_PREFIX}/purchase-order/${callInfo.id}/quotation`
            : `${API_ENDPOINT_PREFIX}/quotation`;

        const res = await apiRequest().post(url, formattedData);

        const json = res.data;

        formik.resetForm();
        closeForm();
        window.scrollTo(0, 0);
        callInfo.setValueForTable(json.data);
        fetchPurchaseOrder && (await fetchPurchaseOrder());
      } catch (err) {
      } finally {
        fetchQuotations && (await fetchQuotations());
        setSubmitting(false);
      }
    },
  });

  // handle image change
  const handleImageChange = async (file) => {
    var formData = new FormData();
    formData.append("image", file);
    try {
      const res = await apiRequest().post(
        `${API_ENDPOINT_PREFIX}/upload/image`,
        formData
      );
      const imageData = res.data;
      formik.setFieldValue("images", imageData.data);
      formik.setFieldValue("stamp_image_id", imageData.id);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    const { id, ...cloneData } = rest.entity;
    formik.setValues(cloneData);
  }, []);

  return (
    <FormikProvider value={formik}>
      <div {...rest}>
        <div>
          <Typography variant="h3" className={classes.formTitle}>
            複製報價單
          </Typography>
          <Typography variant="h5" className={classes.formTitle}>
            報價單詳情
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} md={3} lg={3}>
              <FormTextInput
                name="identifier"
                label="報價單號碼"
                value={formik.values.identifier}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* <Grid item xs={6} md={3} lg={3}>
              <FormTextInput
                name="po_no"
                label="PO number"
                value={formik.values.po_no}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid> */}

            {/* <Grid item xs={6} md={3} lg={3}>
              <FormTextInput
                name="customer_item_no"
                label="Customer Item Number"
                value={formik.values.customer_item_no}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={formik.errors.customer_item_no}
                helperText={formik.errors.customer_item_no}
              />
            </Grid> */}
          </Grid>

          {/* <SearchSection
            preSetValues={preSetValues}
            setPreSetValues={setPreSetValues}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          /> */}

          {/* removed: add work order function
          <FieldArray
            name="work_orders"
            render={(arrayHelpers) => (
              <WorkOrderSection arrayHelpers={arrayHelpers} />
            )}
            validateOnChange={false}
          /> */}

          <Grid item xs={12}>
            <UploadImageArea
              value={formik.values.images}
              onChange={handleImageChange}
            />
          </Grid>

          <GoldPriceSection />

          <DetailSection />

          <AdditionalCostSection />

          <Grid item xs={6} md={2} lg={2} className={classes.clientSimpleName}>
            <AutoCompleteInput
              id="client"
              getOptionLabel={(option) => (option ? option.fake_name : "")}
              optionUrl={`${API_ENDPOINT_PREFIX}/client`}
              label="客名(簡稱)"
              name="client.fake_name"
              value={formik.values.client?.fake_name}
              defaultValue={formik.values.client}
              onChange={(client) => formik.setFieldValue("client", client)}
              noOptionsText="沒有選項"
              error={formik.errors?.client?.id}
              helperText={formik.errors?.client?.id}
            />
          </Grid>

          <MarkUpSection />

          <QuotationPriceSection />

          <QuotationRemarkSection />

          <HandleByAndDateSection />

          <Grid container justifyContent="flex-end">
            <Grid item>
              <ButtonWithLoading
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                loading={formik.isSubmitting}
              >
                提交
              </ButtonWithLoading>
            </Grid>
          </Grid>
        </div>
      </div>
    </FormikProvider>
  );
};

export default CreateForm;
