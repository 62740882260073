import PageHeadline from "../../../../../component/PageHeadline";
import PageSubHeadline from "../../../../../component/PageSubHeadline";
import { Button, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const PageHeader = ({ openForm }) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <PageHeadline>客人名單</PageHeadline>
        <PageSubHeadline>總覽</PageSubHeadline>

        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={openForm}>
          新增客人
        </Button>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
