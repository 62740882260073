import {
  useFormik,
  FormikProvider,
  FieldArray,
  useFormikContext,
  Formik,
} from "formik";
import { useState, useEffect, Fragment, useContext } from "react";
import * as Yup from "yup";
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Input,
  Chip,
  TableRow,
  TableCell,
  TextField,
} from "@material-ui/core";
import AutoCompleteInput from "../../../component/AutoCompleteInput";
import FormSelectInput from "../../../component/Form/FormSelectInput";
import FormTextInput from "../../../component/Form/FormTextInput";
import FormCheckbox from "../../../component/Form/FormCheckbox";
import FormTable from "../../../component/Form/FormTable";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
  SERVER_URL,
} from "../../../helper/fetching";
import moment from "moment";
import { PageContext } from "../../../context/PageContext";

import { DataGrid } from "@mui/x-data-grid";

import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  informationWrapper: {
    marginBottom: 20,
  },
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
  tableRoot: {
    marginTop: 20,
  },
  goldSection: {
    margin: "10px 0 0 0",
  },
  clientSimpleName: {
    padding: "10px 0 0 0",
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const createFormSchema = {
  client_id: Yup.number().nullable().required("必填"),
  work_order_ids: Yup.array().of(Yup.number().required("必填")),
  invoice_date: Yup.date().required("必填"),
  invoice_no: Yup.string().required("必填"),
  country: Yup.string().required("必填"),
  harmonized_code: Yup.string().required("必填"),
  mawb: Yup.string().required("必填"),
  additional_costs: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("必填"),
      cost: Yup.number().required("必填"),
    })
  ),
  default_remark_ids: Yup.array().of(Yup.number().required("必填")),
  contains_ruby_stone: Yup.bool(),
  remark2_1: Yup.string().nullable(),
  remark2_2: Yup.string().nullable(),
  remark2_3: Yup.string().nullable(),
  remark2_4: Yup.string().nullable(),
  remark2_5: Yup.string().nullable(),
  remark3: Yup.string().nullable(),
};

const CostTableRow = ({ index, arrayHelpers, value, ...rest }) => {
  const formik = useFormikContext();

  const classes = useStyles();

  const arrayFieldName = "additional_costs";
  return (
    <TableRow {...rest} className={classes.tableRow}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <FormTextInput
          name={`${arrayFieldName}.${index}.title`}
          value={value.title}
          onChange={formik.handleChange}
          required
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].title &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].title
          }
        />
      </TableCell>
      <TableCell>
        <FormTextInput
          name={`${arrayFieldName}.${index}.cost`}
          value={value.cost}
          onChange={formik.handleChange}
          required
          type="number"
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].cost &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].cost
          }
        />
      </TableCell>
      <TableCell>
        <Button onClick={() => arrayHelpers.remove(index)}> X 刪除 </Button>
      </TableCell>
    </TableRow>
  );
};

const AdditionalSection = ({ arrayHelpers, ...rest }) => {
  const tableHeader = ["#", "Title", "Cost", "Action"];

  const formik = useFormikContext();

  const handleCosts = () =>
    arrayHelpers.push({
      title: "",
      cost: "",
    });

  const renderRows = () =>
    formik.values.additional_costs?.map((additional_cost, index) => (
      <CostTableRow
        key={index}
        index={index}
        arrayHelpers={arrayHelpers}
        value={additional_cost}
      />
    ));

  return (
    <Fragment>
      <Button variant="contained" color="primary" onClick={handleCosts}>
        新增 Costs
      </Button>
      <br />
      <br />
      <FormTable headerNames={tableHeader} renderRows={renderRows} />
    </Fragment>
  );
};

const TableWithCheckbox = ({ items, getWorkOrder, loading }) => {
  const formik = useFormikContext();

  const [goldTypes, setGoldTypes] = useState([]);

  const [selectionModel, setSelectionModel] = useState([]);

  const [filter, setFilter] = useState({ identifier: "", initials: "", idNO: "" });

  useEffect(() => {
    const fetchGoldTypes = async () => {
      const res = await apiRequest().get(`${API_ENDPOINT_PREFIX}/gold-type`);
      const data = res.data;
      setGoldTypes(data);
    };

    fetchGoldTypes();
  }, []);

  const debounced = debounce((callBack) => callBack(), 500);

  const onFilterSubmit = (value) => {
    debounced(() => {
      const params = new URLSearchParams(value).toString();
      getWorkOrder(formik.values.client_id, params);
    });
  };

  useEffect(
    () => formik.setFieldValue("work_order_ids", selectionModel),
    [selectionModel]
  );

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "identifier",
      headerName: "款號",
      flex: 1,
    },
    {
      field: "wrist_size",
      headerName: "手寸",
      flex: 1,
    },
    {
      field: "gold_style",
      headerName: "成色",
      flex: 1,
      valueGetter: (params) =>
        `${
          goldTypes.find((type) => type.id === params.row.gold_type_id)?.name ||
          ""
        }${params.row.gold_style || ""}`,
    },
    {
      field: "chain_length",
      headerName: "鏈長",
      flex: 1,
    },
    {
      field: "diamonds.stone_initial",
      headerName: "鑽石類",
      width: 250,
      valueGetter: (params) => {
        var concatString = params.row.diamonds.reduce(
          (accumulator, current) => {
            if (current.stone_initial === null) {
              current.stone_initial = "";
            }

            return accumulator.concat(current.stone_initial);
          },
          ""
        );
        return concatString ? concatString : "----";
      },
    },
    {
      field: "stones.stone_initial",
      headerName: "其他石類",
      width: 250,
      valueGetter: (params) => {
        var concatString = params.row.stones.reduce((accumulator, current) => {
          if (current.stone_initial === null) current.stone_initial = "";
          return accumulator.concat(current.stone_initial);
        }, "");
        return concatString ? concatString : "----";
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Formik initialValues={filter} validate={onFilterSubmit}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <TextField
                label="ID"
                name="id"
                variant="outlined"
                margin="dense"
                value={props.values.idNO}
                onChange={props.handleChange}
              />
              <span>&nbsp;&nbsp;&nbsp;</span>
              <TextField
                label="款號"
                name="identifier"
                variant="outlined"
                margin="dense"
                value={props.values.identifier}
                onChange={props.handleChange}
              />
              <span>&nbsp;&nbsp;&nbsp;</span>
              <TextField
                label="石類"
                name="initials"
                variant="outlined"
                margin="dense"
                value={props.values.initials}
                onChange={props.handleChange}
              />
            </form>
          )}
        </Formik>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={!loading ? items : []}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            loading={loading}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const CreateForm = ({ closeForm, ...rest }) => {
  const classes = useStyles();

  const [preSetValues, setPreSetValues] = useState("");

  const currentContext = useContext(PageContext);

  const formik = useFormik({
    initialValues: preSetValues
      ? preSetValues
      : {
          client_id: null,
          work_order_ids: [],
          invoice_date: moment().format("YYYY-MM-DD"),
          invoice_no: "",
          country: "",
          harmonized_code: "",
          mawb: "",
          additional_costs: [],
          contains_ruby_stone: false,
          default_remark_ids: [],
          remark2_1: null,
          remark2_2: null,
          remark2_3: null,
          remark2_4: null,
          remark2_5: null,
          remark3: null,
        },
    validationSchema: Yup.object().shape(createFormSchema),
    enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: async (data, action) => {
      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/invoice`,
          data
        );
        action.resetForm();
        currentContext.fetchInvoice();
        closeForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  //for select workOrder
  const [items, setItems] = useState([]);
  const [loadingWorkOrder, setLoadingWorkOrder] = useState(false);
  const getWorkOrder = async (clientId, param = "") => {
    if (!clientId) return setItems([]);
    try {
      setLoadingWorkOrder(true);
      const res = await apiRequest().get(
        // `${API_ENDPOINT_PREFIX}/work-order?clientId=${clientId}`
        `${API_ENDPOINT_PREFIX}/work-order?confirmed=1&completed=0&clientId=${clientId}&${param}`
      );

      setItems(res.data.data);
    } catch (error) {
    } finally {
      setLoadingWorkOrder(false);
    }
  };

  //for remark_ids
  const [remark_ids, setRemark_ids] = useState([]);
  const getRemarkIDS = async () => {
    try {
      const res = await apiRequest().get(
        `${API_ENDPOINT_PREFIX}/invoice-remark`
      );
      setRemark_ids(
        res.data.data.map((remark_id) => ({
          label: remark_id.remark,
          value: remark_id.id,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => getRemarkIDS(), []);

  return (
    <FormikProvider value={formik}>
      <div {...rest}>
        <Typography variant="h5" className={classes.formTitle}>
          Create Invoice
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4} lg={4} className={classes.clientSimpleName}>
            <AutoCompleteInput
              id="client_id"
              getOptionLabel={(option) => (option ? option.name : "")}
              optionUrl={`${API_ENDPOINT_PREFIX}/client`}
              label="客名"
              name="client_id"
              onChange={(client) => {
                formik.setFieldValue("client_id", client.id);
                formik.setFieldValue("work_order_ids", []);
                getWorkOrder(client.id);
              }}
              noOptionsText="沒有選項"
              error={formik.errors?.client_id}
              helperText={formik.errors?.client_id}
            />
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                {/* <FormSelectInput
                  id="work_order_ids"
                  name="work_order_ids"
                  label="工單 (Is Confirmed)"
                  labelId="work_order_ids"
                  multiple
                  input={<Input />}
                  getOptionLabel={(option) => (option ? option.label : "")}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            items.find((item) => item.value === value)?.label
                          }
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  items={items}
                  value={formik.values.work_order_ids}
                  onChange={formik.handleChange}
                  MenuProps={MenuProps}
                /> */}
                <TableWithCheckbox
                  items={items}
                  getWorkOrder={getWorkOrder}
                  loading={loadingWorkOrder}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              id="invoice_date"
              label="Invoice Date"
              name="invoice_date"
              InputLabelProps={{ shrink: true }}
              type="date"
              value={formik.values.invoice_date}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              variant="outlined"
              id="invoice_no"
              label="Invoice No"
              name="invoice_no"
              onChange={formik.handleChange}
              value={formik.values.invoice_no}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              label="Country"
              name="country"
              onChange={formik.handleChange}
              value={formik.values.country}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              label="Harmonized Code"
              name="harmonized_code"
              onChange={formik.handleChange}
              value={formik.values.harmonized_code}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              label="MAWB"
              name="mawb"
              onChange={formik.handleChange}
              value={formik.values.mawb}
            />
          </Grid>
          <Grid item xs={6}>
            <FormCheckbox
              label="Contains Ruby Stone"
              name="contains_ruby_stone"
              onChange={formik.handleChange}
              checked={formik.values.contains_ruby_stone}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelectInput
              id="default_remark_ids"
              name="default_remark_ids"
              label="Default Remark Ids"
              labelId="default_remark_ids"
              multiple
              input={<Input />}
              getOptionLabel={(option) => (option ? option.remark : "")}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={
                        remark_ids.find(
                          (remark_id) => remark_id.value === value
                        )?.label
                      }
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              items={remark_ids}
              value={formik.values.default_remark_ids}
              onChange={formik.handleChange}
              MenuProps={MenuProps}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldArray
              name="additional_costs"
              render={(arrayHelpers) => (
                <AdditionalSection arrayHelpers={arrayHelpers} />
              )}
            ></FieldArray>
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              multiline
              label="Remark2-1"
              name="remark2_1"
              rows={4}
              onChange={formik.handleChange}
              checked={formik.values.remark2_1}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              label="Remark2-2"
              name="remark2_2"
              rows={4}
              multiline
              onChange={formik.handleChange}
              checked={formik.values.remark2_2}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              label="Remark2-3"
              name="remark2_3"
              rows={4}
              multiline
              onChange={formik.handleChange}
              checked={formik.values.remark2_3}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              label="Remark2-4"
              name="remark2_4"
              rows={4}
              multiline
              onChange={formik.handleChange}
              checked={formik.values.remark2_4}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              label="Remark2-5"
              name="remark2_5"
              rows={4}
              multiline
              onChange={formik.handleChange}
              checked={formik.values.remark2_5}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              label="Remark3"
              name="remark3"
              rows={4}
              multiline
              onChange={formik.handleChange}
              checked={formik.values.remark3}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </div>
    </FormikProvider>
  );
};

export default CreateForm;
