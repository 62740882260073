import {
  Button,
  Grid,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {
  FieldArray,
  FormikProvider,
  useFormik,
  useFormikContext,
} from "formik";
import { Fragment, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import AutoCompleteInput from "../../../../component/AutoCompleteInput";
import ButtonWithDialog from "../../../../component/ButtonWithDialog";
import ButtonWithLoading from "../../../../component/ButtonWithLoading";
import FormTextInput from "../../../../component/Form/FormTextInput";
import PopupWidget from "../../../../component/PopupWidget";
import UploadImageArea from "../../../../component/UploadImageArea";
import { PageContext } from "../../../../context/PageContext";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../../helper/fetching";
import AdditionalCostSection from "./FormSection/AdditionalCostSection";
import CreateNewWorkOrderSection from "./FormSection/CreateNewWorkOrderSection";
import DetailSection from "./FormSection/DetailSection/DetailSection";
import MarkUpSection from "./FormSection/MarkUpSection";
import ProductLabelPrintingSection from "./FormSection/ProductLabelPrintingSection/ProductLabelPrintingSection";
import ProductRowCellSection from "./FormSection/ProductRowCellSection";
import QuotationPriceSection from "./FormSection/QuotationPriceSection/QuotationPriceSection";
import QuotationRemarkSection from "./FormSection/QuotationRemarkSection";
import SearchSection from "./FormSection/SearchSection";
import { editFormSchema } from "./Schema/EditFormSchema";
import TableWithCheckBox from "./TableWithCheckBox";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  informationWrapper: {
    marginBottom: 20,
  },
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
  tableRoot: {
    marginTop: 20,
  },

  section_margin: {
    margin: "10px 0 0 0 ",
  },
  clientSimpleName: {
    padding: "10px 0 0 0",
  },
}));

const GoldPriceSection = () => {
  const formik = useFormikContext();
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={3} className={classes.section_margin}>
        <FormTextInput
          name={`gold_price`}
          label="金價"
          value={formik.values.gold_price}
          onChange={formik.handleChange}
          type={"number"}
          required={true}
        />
      </Grid>
    </Grid>
  );
};

const HandleByAndDateSection = () => {
  const formik = useFormikContext();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormTextInput
            name={`handle_by`}
            label="經手人"
            value={formik.values.handle_by}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="date"
            label="提交日期"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            name={`quotation_order_date`}
            value={formik.values.quotation_order_date}
            onChange={formik.handleChange}
            error={formik.errors.quotation_order_date}
            helperText={formik.errors.quotation_order_date}
          />
        </Grid>
      </Grid>
    </>
  );
};

const EditForm = ({ entity: quotation, closeForm, PO_ID, ...rest }) => {
  const classes = useStyles();
  const [preSetValues, setPreSetValues] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [originalClientGoldMarkUp, setOriginalClientGoldMarkUp] = useState("");
  const pageContext = useContext(PageContext);
  
  const formik = useFormik({
    initialValues: quotation,
    validationSchema: Yup.object().shape(editFormSchema),

    onSubmit: async (data, { setSubmitting }) => {
      const formattedData = {
        ...data,
        client_id: data.client?.id || null,
        image_id: data.images?.id || null,
        factory_id: data.factory?.id,
        // stones: data.stones1.concat(data.stones2),
        work_orders: data.work_orders?.map((work_order) => {
          //delete factory and images data just before submit to backend
          const { factory, ...restWorkOrder } = work_order;

          // add sum of the total qty diamonds used and total weight of the diamonds
          const newDiamonds = work_order.diamonds.map((diamond) => {
            return {
              ...diamond,
              stone_total_qty: work_order.product_count * diamond.stone_qty,
              stone_total_weight:
                work_order.product_count * diamond.stone_weight,
            };
          });

          // add sum of the total qty gems(stone) used and total weight of the gems(stone)
          const newStones = work_order.stones.map((stone) => {
            return {
              ...stone,
              stone_total_qty: work_order.product_count * stone.stone_qty,
              stone_total_weight: work_order.product_count * stone.stone_weight,
            };
          });

          return {
            ...restWorkOrder,
            diamonds: newDiamonds,
            stones: newStones,
            factory_id: factory.id,
          };
        }),
      };

      try {
        let res;

        if (PO_ID) {
          // res = await apiRequest().put(
          //   `${API_ENDPOINT_PREFIX}/purchase-order/${formattedData.id}/quotation`,
          //   formattedData
          // );
          res = await apiRequest().put(
            `${API_ENDPOINT_PREFIX}/quotation/${formattedData.id}`,
            formattedData
          );
        } else {
          res = await apiRequest().put(
            `${API_ENDPOINT_PREFIX}/quotation/${quotation.id}`,
            formattedData
          );
        }

        const json = res.data;

        closeForm();
        window.scrollTo(0, 0);
      } catch (err) {
      } finally {
        pageContext.fetchPurchaseOrder
          ? await pageContext.fetchPurchaseOrder()
          : await pageContext.fetchQuotations();

        setSubmitting(false);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  // handle image change
  const handleImageChange = async (file) => {
    var formData = new FormData();
    formData.append("image", file);
    try {
      const res = await apiRequest().post(
        `${API_ENDPOINT_PREFIX}/upload/image`,
        formData
      );
      const imageData = res.data;
      formik.setFieldValue("images", imageData.data);
      formik.setFieldValue("stamp_image_id", imageData.id);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => setOriginalClientGoldMarkUp(formik.values.client_gold_mark_up), [])
  
  //set the search quotation into edit form
  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes('order')) {
      const { gold_price, ...getPreSetValuesFromPO } = preSetValues;
      
      preSetValues ? formik.setValues(getPreSetValuesFromPO) : formik.setValues(quotation)

    } else {
      preSetValues ? formik.setValues(preSetValues) : formik.setValues(quotation)
    }

  }, [preSetValues])

  return (
    <FormikProvider value={formik}>
      <div {...rest}>
        <div>
          <Typography variant="h3" className={classes.formTitle}>
            編輯報價單
            {quotation.is_confirmed ? (
              <span style={{ color: "red", fontSize: "1.5rem" }}>
                {quotation.is_confirmed
                  ? "（The quotation is not editable once it is confirmed）"
                  : ""}
              </span>
            ) : (
              ""
            )}
          </Typography>
          <Typography variant="h5" className={classes.formTitle}>
            報價單號碼:
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} md={3} lg={3}>
              <FormTextInput
                name={`identifier`}
                value={formik.values.identifier}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid item xs={6} md={2} lg={2}>
              <AutoCompleteInput
                id="factory.name"
                getOptionLabel={(option) => (option ? option.name : "")}
                optionUrl={`${API_ENDPOINT_PREFIX}/factory`}
                label="負責廠方"
                name="factory.name"
                value={formik.values.factory?.name}
                defaultValue={formik.values.factory}
                onChange={(factory) => {
                  if(!factory) {
                    const {factory ,...rest} = formik.values
                    formik.setValues({
                      ...rest,
                      factory: {
                        id: null,
                        name: "",
                      }
                    })
                  } else {
                    formik.setFieldValue("factory", factory)
                  }
                }}
                noOptionsText="沒有選項"
                error={formik.errors.factory}
                onInputEmpty={() => {
                  const {factory ,...rest} = formik.values
                    formik.setValues({
                      ...rest,
                      factory: {
                        id: null,
                        name: "",
                      }
                    })
                }}
              />
            </Grid>

            <Grid item xs={6} md={2} lg={2}>
              <FormTextInput
                variant="outlined"
                id="wrist_size"
                label="手寸"
                name="wrist_size"
                value={formik.values.wrist_size}
                onChange={formik.handleChange}
              />
            </Grid>

            {/* <Grid item xs={6} md={2} lg={2}>
              <FormTextInput
                variant="outlined"
                id="client_nickname"
                label="客別名"
                name="client_nickname"
                value={formik.values.client_nickname}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}
            {/* <Grid item xs={6} md={2} lg={2}>
              <FormTextInput
                name="po_no"
                label="PO number"
                value={formik.values.po_no}
                onChange={formik.handleChange}
                required={true}
              />
            </Grid> */}

            {/* <Grid item xs={6} md={2} lg={2}>
              <FormTextInput
                name="customer_item_no"
                label="Customer Item Number"
                value={formik.values.customer_item_no}
                onChange={formik.handleChange}
              />
            </Grid> */}
          </Grid>

          <SearchSection
            preSetValues={preSetValues}
            setPreSetValues={setPreSetValues}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />

          {/* <FieldArray
            name="work_orders"
            render={(arrayHelpers) => (
              <ProductSection arrayHelpers={arrayHelpers} />
            )}
            validateOnChange={false}
          /> */}

          <Grid item xs={12}>
            <UploadImageArea
              value={formik.values.images}
              onChange={handleImageChange}
            />
          </Grid>

          <GoldPriceSection />

          <DetailSection />

          <AdditionalCostSection />

          <Grid item xs={6} md={2} lg={2} className={classes.clientSimpleName}>
            <AutoCompleteInput
              id="client"
              getOptionLabel={(option) => (option ? option.fake_name : "")}
              optionUrl={`${API_ENDPOINT_PREFIX}/client`}
              label="客名(簡稱)"
              name="client.fake_name"
              value={formik.values.client?.fake_name || formik.values.client_nickname || ""}
              defaultValue={formik.values.client}
              onChange={(client) => {
                if(!client) {
                  formik.setFieldValue("client_nickname", "");
                  formik.setFieldValue("client", null);
                  formik.setFieldValue("client_gold_mark_up", originalClientGoldMarkUp);
                  return
                }

                formik.setFieldValue("client", client);
                formik.setFieldValue(
                  "client_gold_mark_up",
                  client.gold_mark_up
                );
                formik.setFieldValue("client_nickname", client.fake_name);
              }}
              noOptionsText="沒有選項"
              error={formik.errors?.client?.id}
              helperText={formik.errors?.client?.id}
            />
          </Grid>

          <MarkUpSection />

          <QuotationPriceSection />

          <QuotationRemarkSection />

          <HandleByAndDateSection />

          <Grid container justifyContent="flex-end">
            <Grid item>
              <ButtonWithLoading
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                loading={formik.isSubmitting}
              >
                提交
              </ButtonWithLoading>
            </Grid>
          </Grid>
        </div>
      </div>
    </FormikProvider>
  );
};

export default EditForm;
