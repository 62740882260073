import { Grid, TextField } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import { apiRequest } from "../../../../../helper/fetching";

// column for search table
const columns = [
  {
    field: "po_no",
    headerName: "PO",
    width: 250,
  },
  {
    field: "identifier",
    headerName: "款號",
    width: 250,
  },
  {
    field: "handle_by",
    headerName: "經手人",
    width: 250,
  },
];

function TableWithCheckBox({ optionUrl, setPreSetValues, setSelectedOption }) {
  const [options, setOptions] = useState([]);
  const [searchWord_1, setSearchWord_1] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [page, setPage] = useState(0); // 0-based page index
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [pageInfo, setPageInfo] = useState({}); // Page information
  const formik = useFormikContext();

  useEffect(() => {
    const fetchOptions = async () => {
      const res = await apiRequest().get(
        `${optionUrl}?page=${page + 1}&pageSize=${pageSize}&identifier=${searchWord_1}`
      );
      const data = res.data.data;
      setPageInfo(res.data.meta); // Ensure correct path for meta data
      setOptions(data);
    };
    fetchOptions();
  }, [searchWord_1, page, pageSize]);

  useEffect(() => {
    //parseInt()-1 : is to deal with base different issue
    // setSelectedOption(options[parseInt(selectionModel) - 1]);
    // setSelectedRows(options[parseInt(selectionModel) - 1]);
    let getSelectionModel = parseInt(selectionModel) ? options.find((item) => item.id == parseInt(selectionModel)) : {};

    if (parseInt(selectionModel)) {
      setSelectedOption(getSelectionModel);
      setSelectedRows(getSelectionModel);
    } else {
      setSelectedOption(getSelectionModel);
      setSelectedRows(getSelectionModel);
    }
  }, [selectionModel]);

  return (
    <>
      <Grid container>
        <Grid item xs={6} md={2} lg={2}>
          <TextField
            label="款號"
            variant="outlined"
            margin="dense"
            value={searchWord_1}
            onChange={(event) => {
              setSearchWord_1(() => event.target.value);
            }}
          />
        </Grid>
      </Grid>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={options}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10]}
          rowCount={pageInfo.total} // Set total row count
          pagination
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            setPage(0); // Reset to first page when page size changes
          }}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectionModel}
          //***  Multi-select function ***
          // onSelectionModelChange={
          //     (ids) => {
          //         const selectedIDs = new Set(ids);
          //         const selectedRowData = options.filter((option) => selectedIDs.has(option.id))
          //         setWorkOrder(selectedRowData)
          //         setSelectedRows(selectedRowData);
          //     }
          // }

          //***  Single-select function ***
          onSelectionModelChange={(selection) => {
            //Get the latest selected option
            if (selection.length > 1) {
              const selectionSet = new Set(selectionModel);
              const result = selection.filter(
                (selectedRowId) => !selectionSet.has(selectedRowId)
              );
              setSelectionModel(result);
            } else {
              setSelectionModel(selection);
            }
          }}
        />
      </div>
    </>
  );
}

export default TableWithCheckBox;
