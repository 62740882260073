import { Grid } from "@material-ui/core";
import { useFormik, useFormikContext } from "formik";
import { useState } from "react";
import PopupWidget from "../../../../../component/PopupWidget";
import { API_ENDPOINT_PREFIX } from "../../../../../helper/fetching";
import TableWithCheckBox from "../TableWithCheckBox";

function SearchSection({
  preSetValues,
  setPreSetValues,
  arrayHelpers,
  selectedOption,
  setSelectedOption,
  ...rest
}) {
  const formik = useFormikContext();

  const handleAddWorkOrder = () => {
    if (selectedOption) {
      setPreSetValues(selectedOption);
    }
  };

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item>
        <PopupWidget dialogTitle="搜索報價單" handleSubmit={handleAddWorkOrder}>
          <TableWithCheckBox
            optionUrl={`${API_ENDPOINT_PREFIX}/quotation/template`}
            preSetValues={preSetValues}
            setPreSetValues={setPreSetValues}
            setSelectedOption={setSelectedOption}
          />
        </PopupWidget>
      </Grid>
    </Grid>
  );
}

export default SearchSection;
