import {
  API_ENDPOINT_PREFIX,
  SERVER_URL,
  apiRequest,
} from "../../../helper/fetching";
import useFetch from "../../../hook/useFetch";
import { PageContext } from "../../../context/PageContext";
import PageHeader from "./component/PageHeader";
import EditForm from "./component/EditForm";
import CloneForm from "./component/CloneForm";
import DeleteForm from "./component/DeleteForm";
import EntityMangeLayout from "../../../layout/EntityMangeLayout";
import CreateForm from "./component/CreateForm";
import { IconButton, Tooltip } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useState } from "react";
import LoadingIcon from "../../../component/LoadingIcon";

var fileDownload = require("js-file-download");

const ProductListingPage = () => {
  const [loading, setLoading] = useState(false);
  const [clickID, setClick] = useState("");

  const exportWorkOrder = async ({ id }) => {
    setClick(id);
    try {
      setLoading(true);
      const res = await apiRequest({
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
        responseType: "blob",
      }).get(`${API_ENDPOINT_PREFIX}/work-order/${id}/exportExcel`);

      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      fileDownload(blob, `Work_Order_${id}.xlsx`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "工單編號",
    //   flex: 1,
    //   valueGetter: (params) => params.value.toString().padStart(5, "0"),
    // },
    {
      field: "identifier",
      headerName: "款號",
      flex: 1,
    },
    {
      field: "client",
      headerName: "客名",
      flex: 1,
      // valueGetter:getFClientName,
      valueGetter: (params) => (params.value ? params.value.fake_name : ""),
    },
    {
      field: "factory",
      headerName: "負責廠方",
      flex: 1,
      valueGetter: (params) => params.value.name,
    },
    {
      field: "wrist_size",
      headerName: "手寸",
      flex: 1,
      valueGetter: (params) => params.value || "-",
    },
    {
      field: "stamp_option",
      headerName: "字印",
      flex: 1,
      renderCell: (params) => {
        if (params.row.stamp_option == "打字印圖" && params.row.client?.stamp_image) {
          return (
            <img
              src={`${SERVER_URL}/${params.row.client.stamp_image.path}`}
              style={{
                maxHeight: "100%",
              }}
              alt=""
            />
          );
        }
        
        return params.value || "-";
      },
    },
    {
      field: "images",
      headerName: "產品圖片",
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return (
            <img
              src={`${SERVER_URL}/${params.value.path}`}
              style={{
                maxHeight: "100%",
              }}
              alt=""
            />
          );
        }

        return "";
      },
    },
    // {
    //   field: "is_completed",
    //   headerName: "是否完成",
    //   flex: 1,
    //   valueGetter: (params) => (params.value ? "已完成" : "未完成"),
    // },
    // {
    //   field: "export",
    //   headerName: "導出",
    //   width: 61,
    //   renderCell: (params) => (
    //     <>
    //       {params.id === clickID && loading ? (
    //         <IconButton color="primary" component="span">
    //           <LoadingIcon />
    //         </IconButton>
    //       ) : (
    //         <Tooltip title="Download Work Order">
    //           <IconButton
    //             color="primary"
    //             component="span"
    //             onClick={() => exportWorkOrder(params.row)}
    //           >
    //             <GetAppIcon />
    //           </IconButton>
    //         </Tooltip>
    //       )}
    //     </>
    //   ),
    // },
  ];

  const {
    entities: rows,
    isLoading,
    fetchEntity: fetchProducts,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/work-order/template`,
    paged: true,
  });

  return (
    <PageContext.Provider
      value={{
        fetchProducts,
        setReady,
        handleFilterChange,
      }}
    >
      <EntityMangeLayout
        columns={columns}
        rows={rows}
        colActions={["edit", "delete", "clone"]}
        renderHeader={(props) => <PageHeader {...props} />}
        renderCreateForm={(props) => <CreateForm {...props} />}
        renderEditForm={(props) => <EditForm {...props} />}
        renderDeleteForm={(props) => <DeleteForm {...props} />}
        renderCloneForm={(props) => <CloneForm {...props} />}
        onPageChange={setPage}
        totalNumberOfRows={total}
        paginationMode="server"
        pageSize={pageSize}
        isLoading={isLoading}
      />
    </PageContext.Provider>
  );
};

export default ProductListingPage;
