import { useEffect, useState } from "react";
import { apiRequest, cleanFilterObject } from "../helper/fetching";

const useFetch = ({
  api,
  paged = false,
  initPageSize = 25,
  initFilter = {},
  lazy = false,
  throttleTime = 0,
}) => {
  const [entities, setEntities] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(initPageSize);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [filter, setFilter] = useState(initFilter);
  const [ready, setReady] = useState(!lazy);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = () => ready && fetchEntity();

    if (throttleTime) {
      let timer;
      timer = setTimeout(() => fetchData(), throttleTime);
      return () => clearTimeout(timer);
    } else {
      fetchData();
    }
  }, [page, pageSize, filter, ready]);

  const fetchEntity = async () => {
    if (!api) return;

    const args = {
      ...cleanFilterObject(filter),
    };

    if (paged) {
      args["page"] = page + 1;
      args["pageSize"] = pageSize;
    }

    const params = new URLSearchParams(args);

    try {
      setLoading(true);
      const res = await apiRequest().get(`${api}?${params.toString()}`);
      setEntities(res.data["data"]);
      if (paged) {
        setTotal(res.data["total"] || res.data["meta"]["total"]);
      }
      // setEntities(res.data["data"]);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setEntities([]);
    setPage(page);
  };

  return {
    entities,
    isLoading,
    page,
    pageSize,
    total,
    fetchEntity,
    setReady,
    handlePageChange,
    handleFilterChange: setFilter,
    setPage: handlePageChange,
  };
};

export default useFetch;
