import { Grid, Typography } from "@material-ui/core";

const NoPermissionPage = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{ height: "calc(100vh - 150px)" }}
    >
      <Grid item>
        <Typography variant="h6" gutterBottom>Sorry, You don't have access right to this page</Typography>
      </Grid>
    </Grid>
  );
};

export default NoPermissionPage;
