import { useState } from "react";
import { API_ENDPOINT_PREFIX, apiRequest } from "../../helper/fetching";
import useFetch from "../../hook/useFetch";
import { Grid, MenuItem } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import DataTable from "../../component/DataTable";

import FormTextInput from "../../component/Form/FormTextInput";

import ButtonWithLoading from "../../component/ButtonWithLoading";

import { format } from "date-fns";

import CreatePage from "./component/CreateForm";

const OutstandingReportPage = () => {
  const {
    entities: outstandingReport,
    isLoading,
    fetchEntity: fetchOutstandingReport,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/outstanding-report/`,
    paged: true,
  });

  const formik = useFormik({
    initialValues: { reports: outstandingReport },
    enableReinitialize: true,
    onSubmit: async (data, { setSubmitting }) => {
      console.log(data);
    },

    validateOnChange: false,
    validateOnBlur: false,
  });

  const [loading, setLoading] = useState(false);
  const [clickID, setClickID] = useState("");
  const [ids, setIds] = useState([]);

  const getIndex = (params) =>
    formik.values.reports.findIndex((report) => report.id === params.id);

  const onSelectionModelChange = (ids) => setIds(ids);

  const [selectionModel, setSelectionModel] = useState([]);

  const saveOneRow = async (params) => {
    const index = getIndex(params);
    setClickID(params.id);
    const data = {
      is_client: params.row.is_client,
      is_urgent: params.row.is_urgent,
      remarks: params.row.remarks,
    };

    try {
      setLoading(true);
      const res = await apiRequest().put(
        `${API_ENDPOINT_PREFIX}/outstanding-report/${params.row.id}`,
        data
      );
      fetchOutstandingReport();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSelectRows = async () => {
    const selectObj = [];

    ids.forEach((id) =>
      selectObj.push(formik.values.reports.find((report) => report.id === id))
    );

    try {
      const res = await apiRequest().put(
        `${API_ENDPOINT_PREFIX}/outstanding-report/massUpdate`,
        {
          outstanding_reports: selectObj.map((report) => ({
            id: report.id,
            is_client: report.is_client,
            is_urgent: report.is_urgent,
            remarks: report.remarks,
          })),
        }
      );
      fetchOutstandingReport();
      setSelectionModel([]);
      setIds([]);
    } catch (error) {
    } finally {
    }
  };

  return (
    <FormikProvider value={{ ...formik, fetchOutstandingReport }}>
      <Grid container>
        <Grid item xs={12}>
          <CreatePage />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            height={"calc(100vh - 240px)"}
            rows={formik.values.reports}
            paginationMode="server"
            rowCount={total}
            loading={isLoading}
            pageSize={pageSize}
            onPageChange={setPage}
            checkboxSelection
            disableSelectionOnClick
            isRowSelectable={() => true}
            onSelectionModelChange={onSelectionModelChange}
            selectionModel={selectionModel}
            columns={[
              {
                field: "po_no",
                headerName: "PO NO",
                flex: 1,
              },
              {
                field: "order_date",
                headerName: "落單日期",
                flex: 1,
                valueGetter: (params) =>
                  format(new Date(params.value), "MM-dd-yyyy"),
              },
              {
                field: "factory",
                headerName: "廠",
                flex: 1,
                valueGetter: (params) => params.value.name,
              },
              {
                field: "id",
                headerName: "ID NO",
                flex: 1,
              },
              {
                field: "invoice_no",
                headerName: "Invoice No",
                flex: 1,
              },
              {
                field: "product_count",
                headerName: "數量",
                flex: 1,
              },
              {
                field: "shipping_date",
                headerName: "寄貨日期",
                flex: 1,
              },
              {
                field: "is_client",
                headerName: "客貨",
                flex: 1,
                renderCell: (params) => {
                  const index = getIndex(params);
                  return (
                    <FormTextInput
                      select
                      id="is_client"
                      name={`reports.${index}.is_client`}
                      value={formik.values.reports[index].is_client}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={true}>YES</MenuItem>
                      <MenuItem value={false}>NO</MenuItem>
                    </FormTextInput>
                  );
                },
              },
              {
                field: "is_urgent",
                headerName: "急貨",
                flex: 1,
                renderCell: (params) => {
                  const index = getIndex(params);
                  return (
                    <FormTextInput
                      select
                      id="is_urgent"
                      name={`reports.${index}.is_urgent`}
                      value={formik.values.reports[index].is_urgent}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={true}>YES</MenuItem>
                      <MenuItem value={false}>NO</MenuItem>
                    </FormTextInput>
                  );
                },
              },
              {
                field: "export_date",
                headerName: "出貨日期",
                flex: 1.5,
              },
              {
                field: "remarks",
                headerName: "Remarks",
                flex: 1.5,
                renderCell: (params) => {
                  const index = getIndex(params);
                  return (
                    <FormTextInput
                      name={`reports.${index}.remarks`}
                      onChange={formik.handleChange}
                      value={formik.values.reports[index].remarks}
                    />
                  );
                },
              },
              {
                field: "save",
                headerName: "操作",
                renderCell: (params) => (
                  <ButtonWithLoading
                    variant="contained"
                    color="primary"
                    onClick={() => saveOneRow(params)}
                    loading={params.id === clickID && loading}
                  >
                    保存
                  </ButtonWithLoading>
                ),
              },
            ]}
          ></DataTable>
        </Grid>
        <Grid item xs={12}>
          <br />
          <ButtonWithLoading
            variant="contained"
            color="primary"
            onClick={handleSelectRows}
            disabled={!ids.length}
          >
            保存 {ids.length} 條
          </ButtonWithLoading>
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

export default OutstandingReportPage;
