import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect, useState } from "react";
import { apiRequest } from "../../helper/fetching";
import FormTextInput from "../Form/FormTextInput";
import TextField from "@material-ui/core/TextField";
const AutoCompleteInput = ({
  id,
  getOptionLabel,
  getOptionValue = (value) => value.id,
  optionUrl,
  name,
  value = "",
  onChange,
  noOptionsText,
  label,
  defaultValue,
  error,
  helperText,
  required,
  style = {},
  onInputEmpty = () => {},
  ...rest
}) => {
  const [options, setOptions] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    const fetchOptions = async () => {
      setFetchingData(true);
      const res = await apiRequest().get(optionUrl);
      const data = res.data.data || res.data;
      setOptions(data);
      setFetchingData(false);
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e, value, reason) => {
    //deal with value of null
    if (onChange) {
      if (value) {
        onChange(value);
      } else {
        onChange("");
      }
    }

    //  onChange(value);
  };

  const handleInputChange = (e, value, reason) => {
    setInputValue(value);
    if(reason == "input" && !value) {
      onInputEmpty()
    }
  };

  return (
    <Autocomplete
      disablePortal
      id={id}
      getOptionLabel={getOptionLabel}
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
      sx={{ width: 300 }}
      //defaultValue={defaultValue ? defaultValue : ""}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            value: inputValue,
          }}
          label={label}
          error={error}
          helperText={helperText}
          required={required}
          style={style}
        />
      )}
      inputValue={inputValue}
      disabled={fetchingData}
    />
  );
};

export default AutoCompleteInput;
