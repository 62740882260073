import {
  useFormik,
  FormikProvider,
  useFormikContext,
  FieldArray,
} from "formik";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../helper/fetching";
import { useState, useEffect, useContext, Fragment, memo } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import PageSubHeadline from "../../../component/PageSubHeadline";
import FormTextInput from "../../../component/Form/FormTextInput";
import PopupWidget from "../../../component/PopupWidget";
import { poWorkOrderColumns } from "../constant";
import DeleteIcon from "@material-ui/icons/Delete";
import AutoCompleteInput from "../../../component/AutoCompleteInput";
import { PageContext } from "../../../context/PageContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    width: "400px",
  },

  div: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.1rem",
  },

  label: {
    width: "150px",
  },

  tableSpace: {
    margin: "1rem 0 0 1rem",
  },
}));

const TableCustomSection = ({ arrayHelpers, ...rest }) => {
  const formik = useFormikContext();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>金石重</TableCell>
          <TableCell>來金重</TableCell>
          <TableCell>石重（ct）</TableCell>
          <TableCell>净重（g）</TableCell>
          <TableCell>操作</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {formik.values.factory_receipt_inputs.map((input, index) => {
          return (
            <Fragment key={index}>
              {!input.hasOwnProperty("identifier") && (
                <TableRow >
                  <TableCell>{input.lin_stone_weight}</TableCell>
                  <TableCell>{input.incoming_gold_weight}</TableCell>
                  <TableCell>{input.diamond_weight}</TableCell>
                  <TableCell>{input.gold_weight}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={() => {
                        arrayHelpers.remove(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

const CreateSection = () => {
  const formik = useFormikContext();

  const customFormik = useFormik({
    initialValues: {
      product_count: "",
      lin_stone_weight: "",
      incoming_gold_weight: "",
      lian_quan: "",
      xia_bi: "",
      er_po: "",
      er_zhen: "",
      diamond_weight: "",
      gold_weight: "",
      total_weight: "",
      car_stone_fee: "",
      work_fee: "",
      mold_fee: "",
      stone_fee: "",
      salary_fee: "",
      total_price: "",
      gem_practical_weight: "",
      diamond_practical_weight: "",
      return_gold: 0,
      manual_remarks: "　",
    },
    enableReinitialize: true,
  });

  const handleAddSelectedOption = async () => {
    formik.setFieldValue("factory_receipt_inputs", [
      ...formik.values.factory_receipt_inputs,
      customFormik.values,
    ]);

    customFormik.resetForm();
  };

  return (
    <PopupWidget
      dialogTitle="新建製工單"
      handleSubmit={handleAddSelectedOption}
      fullWidth={false}
      confirmButtonText="確定"
    >
      <FormikProvider value={customFormik}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormTextInput
              name="product_count"
              label="件數"
              value={customFormik.values.product_count}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="lin_stone_weight"
              label="金石重"
              value={customFormik.values.lin_stone_weight}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="incoming_gold_weight"
              label="來金重"
              value={customFormik.values.incoming_gold_weight}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="lian_quan"
              label="鏈圈"
              value={customFormik.values.lian_quan}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="xia_bi"
              label="虾比"
              value={customFormik.values.xia_bi}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="er_po"
              label="耳迫"
              value={customFormik.values.er_po}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="er_zhen"
              label="耳針"
              value={customFormik.values.er_zhen}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="diamond_weight"
              label="石重(ct)"
              value={customFormik.values.diamond_weight}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="gold_weight"
              label="淨重（g）"
              value={customFormik.values.gold_weight}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="total_weight"
              label="加耗重（g）"
              value={customFormik.values.total_weight}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="car_stone_fee"
              label="车石（¥）"
              value={customFormik.values.car_stone_fee}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="work_fee"
              label="办费（$）"
              value={customFormik.values.work_fee}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="mold_fee"
              label="胶模（$）"
              value={customFormik.values.mold_fee}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="stone_fee"
              label="鑲石（$）"
              value={customFormik.values.stone_fee}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="salary_fee"
              label="胚底（$）"
              value={customFormik.values.salary_fee}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="total_price"
              label="总价（$）"
              value={customFormik.values.total_price}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="gem_practical_weight"
              label="宝石"
              value={customFormik.values.gem_practical_weight}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="diamond_practical_weight"
              label="配石"
              value={customFormik.values.diamond_practical_weight}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              name="return_gold"
              label="回金"
              value={customFormik.values.return_gold}
              onChange={customFormik.handleChange}
              required={true}
              type="number"
            />
          </Grid>
        </Grid>
      </FormikProvider>
    </PopupWidget>
  );
};

const WorkOrderCustomTable = () => {
  const classes = useStyles();
  const formik = useFormikContext();

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <div className={classes.tableSpace}>
          <PageSubHeadline>自定義製工單</PageSubHeadline>
        </div>

        <div className={classes.tableSpace}>
          <CreateSection />
        </div>

        <FieldArray
          name="factory_receipt_inputs"
          validateOnChange={false}
          render={(arrayHelpers) => (
            <TableCustomSection arrayHelpers={arrayHelpers} />
          )}
        />
      </TableContainer>
    </Fragment>
  );
};

const SearchSection = memo(() => {
  const formik = useFormikContext();
  const [loading, setLoading] = useState(false);

  const [selectionModel, setSelectionModel] = useState([]);

  const [searchWords, setSearchWords] = useState({
    identifier: {
      value: "",
      label: "",
    },
  });

  const [options, setOptions] = useState([]);

  const [rowCount, setRowCount] = useState(null);

  const handleAddSelectedOption = async () => {
    const input = options.find((option) => {
      return option.id == selectionModel[0];
    });
    const receipt_input = {
      work_order_id: input.id,
      identifier: input.identifier,
      consumption_percentage: "",
      client_name: input.client?.name,
      factory: input.factory?.name,
      gold_type_id:
        input.gold_type_id == 1 ? "14" : input.gold_type_id == 2 ? "18" : "",
        manual_remarks: "　",
    };

    formik.setFieldValue("factory_receipt_inputs", [
      ...formik.values.factory_receipt_inputs,
      receipt_input,
    ]);
  };

  const fetchOptions = async (page = 1) => {
    const params = new URLSearchParams({
      page,
      pageSize: 5,
    });

    Object.keys(searchWords).forEach((keyword) => {
      if (keyword) {
        params.append(keyword, searchWords[keyword].value);
      }
    });

    setLoading(true);
    setOptions([]);
    const res = await apiRequest().get(
      `${API_ENDPOINT_PREFIX}/work-order/template?${params.toString()}`
    );
    const data = res.data.data;
    setRowCount(res.data.meta.total);

    setOptions(data);
    setLoading(false);
  };

  useEffect(() => fetchOptions(), [searchWords]);

  return (
    <PopupWidget
      dialogTitle="搜索製工單"
      handleSubmit={handleAddSelectedOption}
    >
      <div style={{ height: 500, width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={2} lg={2}>
            <TextField
              label="identifier"
              variant="outlined"
              margin="dense"
              value={searchWords.identifier.value}
              onChange={(event) => {
                setSearchWords({
                  ...searchWords,
                  identifier: {
                    label: searchWords.identifier.label,
                    value: event.target.value,
                  },
                });
              }}
            />
          </Grid>
        </Grid>
        <DataGrid
          rows={options}
          columns={poWorkOrderColumns}
          pageSize={5}
          rowCount={rowCount}
          checkboxSelection
          disableSelectionOnClick
          paginationMode="server"
          selectionModel={selectionModel}
          onPageChange={(page) => fetchOptions(page + 1)}
          loading={loading}
          onSelectionModelChange={(selection) => {
            if (selection.length > 1) {
              const selectionSet = new Set(selectionModel);
              const result = selection.filter(
                (selectedRowId) => !selectionSet.has(selectedRowId)
              );
              setSelectionModel(result);
            } else {
              setSelectionModel(selection);
            }
          }}
        />
      </div>
    </PopupWidget>
  );
});

const TableSection = ({ arrayHelpers, ...rest }) => {
  const formik = useFormikContext();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>耗%</TableCell>
          <TableCell>客人</TableCell>
          <TableCell>廠方</TableCell>
          <TableCell>成色</TableCell>
          <TableCell>操作</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {formik.values.factory_receipt_inputs.map((input, index) => {
          return (
            <Fragment key={index}>
              {input.hasOwnProperty("identifier") && (
                <TableRow>
                  <TableCell>{input.identifier}</TableCell>
                  <TableCell>
                    <FormTextInput
                      name={`factory_receipt_inputs.${index}.consumption_percentage`}
                      onChange={formik.handleChange}
                      value={input.consumption_percentage}
                      label="耗%"
                      type="number"
                    />
                  </TableCell>
                  <TableCell>{input.client_name}</TableCell>
                  <TableCell>{input.factory}</TableCell>
                  <TableCell>{input.gold_type_id}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={() => {
                        arrayHelpers.remove(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

const WorkOrderTable = () => {
  const classes = useStyles();
  const formik = useFormikContext();

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <div className={classes.tableSpace}>
          <PageSubHeadline>製工單</PageSubHeadline>
        </div>

        <div className={classes.tableSpace}>
          <SearchSection />
        </div>

        <FieldArray
          name="factory_receipt_inputs"
          validateOnChange={false}
          render={(arrayHelpers) => (
            <TableSection arrayHelpers={arrayHelpers} />
          )}
        />
      </TableContainer>
    </Fragment>
  );
};

const ReceiptBaseSection = () => {
  const formik = useFormikContext();
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <FormTextInput
          name="receipt_no"
          label="發票號"
          value={formik.values.receipt_no}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
      <Grid item xs={3}>
        <AutoCompleteInput
          id="factory_id"
          getOptionLabel={(option) => (option ? option.name : "")}
          optionUrl={`${API_ENDPOINT_PREFIX}/factory`}
          label={"廠方"}
          name="factory_id"
          noOptionsText="沒有選項"
          onChange={(e) => e?.id && formik.setFieldValue("factory_id", e.id)}
        />
      </Grid>
      <Grid item xs={3}>
        <AutoCompleteInput
          id="gold_type_id"
          getOptionLabel={(option) => (option ? option.name : "")}
          optionUrl={`${API_ENDPOINT_PREFIX}/gold-type`}
          label={"成色"}
          name="gold_type_id"
          noOptionsText="沒有選項"
          onChange={(e) => e?.id && formik.setFieldValue("gold_type_id", e.id)}
        />
      </Grid>
      <Grid item xs={3}>
        <FormTextInput
          name="receipt_date"
          label="日期"
          value={formik.values.receipt_date}
          onChange={formik.handleChange}
          required={true}
          type="date"
        />
      </Grid>
      <Grid item xs={3}>
        <FormTextInput
          name="month_day_client_gold"
          label="ZH: 月日客来金/LF: 客来足金"
          value={formik.values.month_day_client_gold}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
      <Grid item xs={3}>
        <FormTextInput
          name="month_day_client_chain_weight"
          label="ZH:月日客来配件/LF:月日客来链"
          value={formik.values.month_day_client_chain_weight}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
      <Grid item xs={3}>
        <FormTextInput
          name="la_xiang"
          label="腊镶"
          value={formik.values.la_xiang}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
      <Grid item xs={3}>
        <FormTextInput
          name="hu_zu_jin"
          label="回足金"
          value={formik.values.hu_zu_jin}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextInput
          name="remark1"
          label="備注1"
          value={formik.values.remark1}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextInput
          name="remark2"
          label="備注2"
          value={formik.values.remark2}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextInput
          name="remark3"
          label="備注3"
          value={formik.values.remark3}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
    </Grid>
  );
};

const CreateForm = ({ closeForm, ...rest }) => {
  const { fetchReceipt } = useContext(PageContext);

  const formik = useFormik({
    initialValues: {
      factory_id: null,
      gold_type_id: null,
      receipt_date: null,
      receipt_no: null,
      remark1: "",
      remark2: "",
      remark3: "",
      month_day_client_gold: "",
      month_day_client_chain_weight: "",
      la_xiang: "",
      hu_zu_jin: "",
      factory_receipt_inputs: [],
    },

    enableReinitialize: true,
    onSubmit: async (data, { setSubmitting }) => {
      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/factory-receipt`,
          data
        );

        const json = res.data;

        formik.resetForm();
        closeForm();
      } catch (err) {
      } finally {
        setSubmitting(false);
        fetchReceipt();
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <div {...rest}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <br />
            <PageSubHeadline>新建廠發票</PageSubHeadline>
          </Grid>

          <Grid item xs={8}>
            <ReceiptBaseSection />
          </Grid>

          <Grid item xs={12}>
            <WorkOrderTable />
          </Grid>

          <Grid item xs={12}>
            <WorkOrderCustomTable />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            >
              提交
            </Button>
          </Grid>
        </Grid>
      </div>
    </FormikProvider>
  );
};

export default CreateForm;
