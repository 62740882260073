import { apiRequest, API_ENDPOINT_VALIDATE } from "./fetching";

export const validteField = async (endpoint, data) => {
  try {
    const res = await apiRequest().post(
      `${API_ENDPOINT_VALIDATE}/${endpoint}`,
      data
    );

    return {
      status: true,
    };
  } catch (err) {
    return {
      status: false,
      errors: err.data.errors,
    };
  }
};
