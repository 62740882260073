import { Snackbar } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import {React} from 'react';

const StyledSnackBar = ({
    open,
    autoHideDuration,
    onClose,
    severity,
    message
}) => (
    <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        message={message}
    >
        <Alert onClose={onClose} severity={severity}>
            {message}
        </Alert>
    </Snackbar>
);

export default StyledSnackBar;