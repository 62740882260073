
  // PoWorkOrderSection
  export const PoWorkOrderSectionTableHeader = ["ID", "客人", "廠方","是否確認","是否完成", "動作"];
  // PoQuotationSection
  export const PoQuotationSectionTableHeader = ["ID", "客人", "下單日期", "是否確認", "動作"];
  
  export const handleAddPoWorkOrderObj = {};
  
  // Search section of work order in purchase order
  const getDiamondInitail = (params) => {
    return (
      params.row.diamonds.reduce((previousValue, currentValue) => {
        if (currentValue.stone_initial) {
          return previousValue + "," + currentValue.stone_initial;
        } else {
          return null;
        }
      }, "") ?? "----"
    );
  };
  
  const getStoneInitail = (params) => {
    return params.row.stones.reduce((previousValue, currentValue) => {
      if (currentValue.stone_initial) {
        return currentValue.stone_initial + ", " + previousValue;
      } else {
        return "";
      }
    }, "");
  };
  
  const getPO = (params) => {
    // return params.row.purchaseOrder.po_no;
    return params.row.purchaseOrder ? params.row.purchaseOrder.po_no : "";
  };
  
  const getInvoiceNo = (params) => {
    return params.row.purchaseOrder
      ? params.row.purchaseOrder.invoice_no ?? "沒有"
      : "";
  };
  
  export const poWorkOrderColumns = [
    {
      field: "id_no",
      headerName: "ID Number",
      width: 250,
    },
    {
      field: "identifier",
      headerName: "identifier",
      width: 250,
    },
    {
      field: "diamond",
      headerName: "鑽石",
      width: 250,
      valueGetter: getDiamondInitail,
    },
    {
      field: "stone",
      headerName: "石類",
      width: 250,
      valueGetter: getStoneInitail,
    },
    {
      field: "purchase_order",
      headerName: "PO",
      width: 250,
      valueGetter: getPO,
    },
    {
      field: "invoice_no",
      headerName: "Invoice Number",
      width: 250,
      valueGetter: getInvoiceNo,
    },
  ];