import React, { useState, useEffect } from "react";

import { apiRequest, API_ENDPOINT_PREFIX } from "../../../helper/fetching";
import { useContext } from "react";
import DataTable from "../../../component/DataTable";
import {
  Button,
  makeStyles,
  Tooltip,
  Grid,
  Typography,
  Select,
  MenuItem,
  Box
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ArchiveIcon from "@material-ui/icons/Archive";
import LoadingIcon from "../../../component/LoadingIcon";

import { FormikProvider, useFormik } from "formik";

import { PageContext } from "../../../context/PageContext";

import PageHeader from "./PageHeader";

import * as Yup from "yup";

import CreateInvoice from "./FormComponent/CreateInvoice";

import InputsFormTable from "./InputsFormTable";

import moment from "moment";

var fileDownload = require("js-file-download");

const InvoiceCreate = (props) => {
  const { fetchPurchaseOrder } = useContext(PageContext);

  const { entity: po } = props;

  const [quotations_id, setQuotations_id] = useState(false);
  const [quotations_confirm, setQuotations_confirm] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [invoice_id, setInvoice_id] = useState(false);
  const [invoice_rows, setInvoice_rows] = useState([]);
  const [format, setFormat] = useState("Default");

  const initialState = () => {
    po.quotations_are_confirmed &&
      setQuotations_confirm(po.quotations_are_confirmed);
    po.invoice_id && setInvoice_id(po.invoice_id);
    po.quotations.length && setQuotations_id(po.quotations[0].id);
    po.invoice_id && getInvoice();
  };
  useEffect(() => {
    initialState();
  }, []);

  const saveInput = async (val = {}) => {
    const idx = invoice.inputs.findIndex((item) => item.id === val.id);
    const ids = invoice.default_remarks.map((item) => item.id);

    const inputs = invoice.inputs;
    Object.keys(val).forEach((key) => (inputs[idx][key] = val[key]));
    const data = { ...invoice, inputs, default_remark_ids: ids };

    try {
      const res = await apiRequest().put(
        `${API_ENDPOINT_PREFIX}/invoice/${invoice_id}`,
        data
      );
      console.log(res);
      fetchPurchaseOrder();
    } catch (error) {
      console.log(error);
    }
  };

  const exportInput = async (invoiceFormat = format) => {
    let formatInput = invoiceFormat === "MC" ? "?format=mc" : "";

    try {
      const res = await apiRequest({
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
        responseType: "blob",
      }).get(
        `${API_ENDPOINT_PREFIX}/invoice/${invoice_id}/excel${formatInput}`
      );

      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      const date = moment().format("MM_DD_YYYY_hh_mm_ss");

      fileDownload(
        blob,
        `Invoice_${invoice_id}_${date}_${
          invoiceFormat === "MC" ? "(MC)" : "(DEFAULT)"
        }.xlsx`
      );
    } catch (error) {}
  };

  const handleFormat = ({ target }) => setFormat(target.value);

  const InvoiceTable = (props) => {
    let inputs = invoice_rows.length ? invoice_rows : [];

    return (
      <>
        <InputsFormTable rows={inputs} saveInput={saveInput} {...props} />
        <br />
        {invoice_rows.length ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Select
                id="format"
                labelId="format"
                value={format}
                onChange={handleFormat}
                label="Invoice format"
                fullWidth
                defaultValue="Default"
              >
                <MenuItem value="MC" name="MC format invoice">
                  MC format invoice
                </MenuItem>
                <MenuItem value="Default" name="Default format invoice">
                  Default format invoice
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => exportInput(format)}
              >
                Invoice Export ( {format} )
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </>
    );
  };


  const formik = useFormik({
    initialValues: {
      invoice_date: "",
      invoice_no: "",
      country: "",
      harmonized_code: "",
      mawb: "",
      additional_costs_cost: "",
      additional_costs_title: "",
      contains_ruby_stone: false,
      default_remark_ids: "",
      remark2_1: "",
      remark2_2: "",
      remark2_3: "",
      remark2_4: "",
      remark2_5: "",
      remark3: "",
    },

    validationSchema: Yup.object({
      invoice_date: Yup.date().required("required"),
      invoice_no: Yup.string().required("required"),
      country: Yup.string().required("required"),
      harmonized_code: Yup.string().required("required"),
      mawb: Yup.string().required("required"),
      additional_costs_title: Yup.string().required("required"),
      additional_costs_cost: Yup.string().required("required"),
      default_remark_ids: Yup.string(),
      contains_ruby_stone: Yup.bool(),
      remark2_1: Yup.string(),
      remark2_2: Yup.string(),
      remark2_3: Yup.string(),
      remark2_4: Yup.string(),
      remark2_5: Yup.string(),
      remark3: Yup.string(),
    }),

    onSubmit: async (value) => {
      let data = {
        ...value,
        additional_costs: [
          {
            title: value.additional_costs_title,
            cost: value.additional_costs_cost,
          },
        ],
        default_remark_ids: value.default_remark_ids
          ? [value.default_remark_ids]
          : [],
      };

      delete data.additional_costs_title;
      delete data.additional_costs_cost;
      Object.keys(data).forEach((key) => data[key] === "" && delete data[key]);

      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/purchase-order/${po.id}/invoice`,
          data
        );
        console.log(res);
        setInvoice_id(res.data.data.id);
        getInvoice(res.data.data.id);
      } catch (error) {
        console.log(error);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const getInvoice = async (id = "") => {
    try {
      const res = await apiRequest().get(
        `${API_ENDPOINT_PREFIX}/invoice/${po.invoice_id || id}`
      );
      setInvoice_rows(res.data.inputs);
      setInvoice(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const invoiceInfo = [
    { title: "Date", value: invoice.invoice_date },
    { title: "Invoice No", value: invoice.invoice_no },
    { title: "Country of manufacture", value: invoice.country },
    { title: "Harmonized Code", value: invoice.harmonized_code },
    { title: "MAWB", value: invoice.mawb },
    { title: "Total Amount", value: invoice.total_amount },
  ];

  return (
    <FormikProvider value={{ ...formik, getInvoice }}>
      <br />

      {quotations_confirm ? (
        invoice_id ? (
          <>
            <br />
            <PageHeader subTitle={`Invoice`} />
            <br />
            <Grid container spacing={0}>
              {invoiceInfo.map((item) => (
                <Grid item xs={6}>
                  <Typography variant="subtitle1">
                    {item.title}: {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>

            <InvoiceTable invoice={invoice} />
          </>
        ) : (
          <CreateInvoice />
        )
      ) : <Box
          sx={{display: "flex", height: "50vh", width: '100%', justifyContent: "center", alignItems: "center"}}
      >{"THIS QUOTATION IS NOT CONFIRMED"}</Box>}
    </FormikProvider>
  );
};
export default InvoiceCreate;
