import {
  API_ENDPOINT_PREFIX,
  SERVER_URL,
  apiRequest,
} from "../../helper/fetching";
import useFetch from "../../hook/useFetch";
import { PageContext } from "../../context/PageContext";
import EntityMangeLayout from "../../layout/EntityMangeLayout";
import { useContext, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useFormik, FormikProvider } from "formik";
import FormTextInput from "../../component/Form/FormTextInput";
import AutoCompleteInput from "../../component/AutoCompleteInput";

import LoadingIcon from "../../component/LoadingIcon";

import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";

import ViewForm from "./component/ViewForm";
import CreateForm from "./component/CreateForm";

var fileDownload = require("js-file-download");

const useStyles = makeStyles({
  paper: {
    textAlign: "end",
  },
  to: {
    textAlign: "center",
    lineHeight: "51px",
  },
});

const HeaderComponent = (props) => {
  const { handleFilterChange } = useContext(PageContext);

  const classes = useStyles();

  const [factoryName, setFactoryName] = useState("");
  const [goldTypeName, setGoldTypeName] = useState("");

  const formik = useFormik({
    initialValues: {
      receipt_no: "",
      gold_type_id: "",
      factory_id: "",
      start_date: "",
      end_date: "",
    },
    validateOnChange: true,
    validate: (value) => {
      handleFilterChange(value);
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <AutoCompleteInput
                  id="receipt_no"
                  optionUrl={`${API_ENDPOINT_PREFIX}/factory-receipt`}
                  label="發票號"
                  name="receipt_no"
                  noOptionsText="沒有選項"
                  getOptionLabel={(option) => (option ? option.receipt_no : "")}
                  onChange={(e) => {
                    e.factory?.id
                      ? setFactoryName(`廠方：${e.factory?.name}`)
                      : setFactoryName("");
                    e.gold_type_id
                      ? setGoldTypeName(
                          e.gold_type_id === 1
                            ? "成色：14"
                            : e.gold_type_id === 2
                            ? "成色：18"
                            : ""
                        )
                      : setGoldTypeName("");

                    formik.setFieldValue("receipt_no", e ? e.receipt_no : "");
                    formik.setFieldValue("factory_id", e ? e.factory?.id : "");
                    formik.setFieldValue(
                      "gold_type_id",
                      e ? e.gold_type_id : ""
                    );
                  }}
                  value={formik.values.receipt_no}
                />
              </Grid>
              <Grid item xs={4}>
                <AutoCompleteInput
                  id="factory_id"
                  getOptionLabel={(option) => (option ? option.name : "")}
                  optionUrl={`${API_ENDPOINT_PREFIX}/factory`}
                  label={factoryName || "廠方"}
                  name="factory_id"
                  noOptionsText="沒有選項"
                  onChange={(e) => {
                    e?.id && formik.setFieldValue("factory_id", e.id);
                    setFactoryName("");
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <AutoCompleteInput
                  id="gold_type_id"
                  getOptionLabel={(option) => (option ? option.name : "")}
                  optionUrl={`${API_ENDPOINT_PREFIX}/gold-type`}
                  label={goldTypeName || "成色"}
                  name="gold_type_id"
                  noOptionsText="沒有選項"
                  onChange={(e) => {
                    e?.id && formik.setFieldValue("gold_type_id", e.id);
                    setGoldTypeName("");
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextInput
                  id="start_date"
                  label="Start Date"
                  name="start_date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  value={formik.values.start_date}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={1} className={classes.to}>
                To
              </Grid>
              <Grid item xs={4}>
                <FormTextInput
                  id="end_date"
                  label="End Date"
                  name="end_date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  value={formik.values.end_date}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} justifyContent="flex-end" className={classes.paper}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.openForm}
            >
              新增廠發票
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
};

const FactoryReceiptPage = () => {
  const [loading, setLoading] = useState(false);
  const [clickID, setClick] = useState("");

  const {
    entities: rows,
    isLoading,
    fetchEntity: fetchReceipt,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/factory-receipt`,
    paged: true,
  });

  const columns = [
    {
      field: "receipt_no",
      headerName: "發票號",
      flex: 1,
    },
    {
      field: "factory",
      headerName: "負責廠方",
      flex: 1,
      valueGetter: (params) => params.value.name,
    },
    {
      field: "receipt_date",
      headerName: "日期",
      flex: 1,
    },
    {
      field: "download",
      headerName: "下載",
      width: 61,
      renderCell: (params) => (
        <>
          {params.id === clickID && loading ? (
            <IconButton color="primary" component="span">
              <LoadingIcon />
            </IconButton>
          ) : (
            <Tooltip title="Download Receipt">
              <IconButton
                color="primary"
                component="span"
                onClick={() => exportReceipt(params.row)}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: "delete",
      headerName: "刪除",
      width: 61,
      renderCell: (params) => (
        <Tooltip title="Delete The Latest Version Of Receipt">
          <IconButton
            color="primary"
            component="span"
            onClick={() => deleteReceipt(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const exportReceipt = async ({ id }) => {
    setClick(id);
    try {
      setLoading(true);
      const res = await apiRequest({
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
        responseType: "blob",
      }).get(`${API_ENDPOINT_PREFIX}/factory-receipt/${id}/excel`);

      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      fileDownload(blob, `Receipt_${id}.xlsx`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const deleteReceipt = async ({ id }) => {
    try {
      setLoading(true);
      await apiRequest().delete(`${API_ENDPOINT_PREFIX}/factory-receipt/${id}`);
    } catch (error) {
      console.log(error);
    } finally {
      fetchReceipt();
    }
  };

  return (
    <PageContext.Provider
      value={{
        fetchReceipt,
        setReady,
        handleFilterChange,
        rows,
      }}
    >
      <EntityMangeLayout
        columns={columns}
        rows={rows}
        renderHeader={(props) => <HeaderComponent {...props} />}
        renderCreateForm={(props) => <CreateForm {...props} />}
        pageSize={pageSize}
        onPageChange={setPage}
        colActions={["view"]}
        renderViewForm={(props) => <ViewForm {...props} />}
        paginationMode="server"
        totalNumberOfRows={total}
        isLoading={isLoading}
      ></EntityMangeLayout>
    </PageContext.Provider>
  );
};

export default FactoryReceiptPage;
