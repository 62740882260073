import EntityMangeLayout from "../../../layout/EntityMangeLayout";
import ComponentLayout from "../../../layout/EntityMangeLayout/ComponentLayout";
import DataTable from "../../../component/DataTable";
import { invoiceColumns } from "../Constants";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import EditInputContext from "./EditInput";
import CreateInput from "./CreateInput";
import { Grid, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import PageSubHeadline from "../../../component/PageSubHeadline";
import * as yup from "yup";

/***********EditInput */
const EditInput = (props) => {
  const { description, id, aa_no, style_no, diamonds, client_nickname, item_no } = props.entity;

  const formik = useFormik({
    initialValues: {
      client_nickname,
      id,
      description,
      aa_no,
      style_no,
      diamonds,
      item_no
    },
    onSubmit: async (value) => props.saveInput(value),
    validationSchema: yup.object({
      description: yup.string().nullable(),
      aa_no: yup.string().nullable(),
      style_no: yup.string().nullable(),
      diamonds: yup.array().of(
        yup.object().shape({
          created_at: yup.string().nullable(),
          updated_at: yup.string().nullable(),
          id: yup.number().nullable(),
          qty: yup.number().required("必填"),
          stone_initial: yup.string().required("必填"),
          unit: yup.string().required("必填"),
          weight: yup.string().required("必填"),
          stone_full_name: yup.string().nullable(),
        })
      ),
    }),
    validateOnChange: true,
    validateOnBlur: true,
  });
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <br />
          <PageSubHeadline>EDIT</PageSubHeadline>
        </Grid>

        <Grid item xs={12}>
          <FormikProvider value={formik}>
            <EditInputContext {...props} />
          </FormikProvider>
        </Grid>
      </Grid>
    </>
  );
};

/********************CreateInputButton */
const CreateInputButton = ({ openForm }) => {
  return (
    <Button startIcon={<AddIcon />} onClick={openForm} variant="contained" color="primary">
      新增
    </Button>
  );
};

/***********main layout */
const InputsFormTable = ({ rows, saveInput, deleInput, ...rest }) => {
  const formik = useFormikContext();
  
  return (
    <>
      <ComponentLayout
        renderHeader={(props) => <CreateInputButton {...props} />}
        renderCreateForm={(props) => (
          <CreateInput
            rows={rows}
            {...props}
            invoice={rest.entity}
            getInvoice={formik.getInvoice}
          />
        )}
      />
      {rows.length ? (
        <EntityMangeLayout
          rows={rows}
          columns={invoiceColumns}
          renderEditForm={(props) => (
            <EditInput saveInput={saveInput} deleInput={deleInput} rows={rows} {...props} />
          )}
          colActions={["edit"]}
          autoHeight
          hideFooter
        />
      ) : null}
    </>
  );
};

export default InputsFormTable;