import { useState } from "react";
import {
  apiRequest,
  API_ENDPOINT_AUTH_PREFIX,
  API_ENDPOINT_PREFIX,
} from "../helper/fetching";

const useAuth = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [currUser, setCurrUser] = useState(null);

  const [permission_id, setPermission_id] = useState(null);

  const [permission, setPermission] = useState({});

  const getPermissionList = async (id) => {
    let permission_unavailable_page = [
      {
        permission_id: 1,
        unavailable_page: [],
      },
      {
        permission_id: 2,
        unavailable_page: ["user-management"],
      },
      {
        permission_id: 3,
        unavailable_page: [
          "user-management",
          "quotation",
          "order",
          "invoice",
          "client",
          "outstanding-report",
          "factories"
        ],
      },
      {
        permission_id: 4,
        unavailable_page: [
          "user-management",
          "quotation",
          "order",
          "invoice",
          "client",
          "outstanding-report",
          "factories"
        ],
      },
    ];

    try {
      const res = await apiRequest().get(`${API_ENDPOINT_PREFIX}/permission`);

      setPermission({
        ...res.data.find((page) => page.id === id),
        unavailable_page: permission_unavailable_page.find(
          (page) => page.permission_id === id
        ).unavailable_page,
      });

    } catch (err) {
      return null;
    }
  };

  const login = async (credential) => {
    try {
      const res = await apiRequest().post(
        `${API_ENDPOINT_AUTH_PREFIX}/login`,
        credential
      );

      const { access_token } = res.data;
      localStorage.setItem("token", access_token);
      setToken(access_token);
    } catch (err) {}
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  const verifyToken = async () => {
    try {
      const res = await apiRequest().post(`${API_ENDPOINT_AUTH_PREFIX}/me`);
      switch (res.status) {
        case 200:
          setCurrUser(res.data);
          setPermission_id((state) => {
            return res.data.permission_id
          });
          
          getPermissionList(res.data.permission_id);
          return true;
        default:
          setCurrUser(null);
          return false;
      }
    } catch (err) {
      setCurrUser(null);
      return false;
    }
  };

  return {
    token,
    permission,
    login,
    logout,
    verifyToken,
  };
};

export default useAuth;
