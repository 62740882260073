import { useState, useContext, useLayoutEffect } from "react";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../helper/fetching";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { PageContext } from "../../../context/PageContext";
import AddIcon from "@material-ui/icons/Add";
import { useFormikContext } from "formik";
import FormTextInput from "../../../component/Form/FormTextInput";
import FormSection from "../../../component/Form/FormSection";
import { useParams, useHistory } from "react-router";

import AutoCompleteInput from "../../../component/AutoCompleteInput";

import DataTable from "../../../component/DataTable";

import LoadingIcon from "../../../component/LoadingIcon";

// import { purchase_order } from "../mock";

const useStyles = makeStyles((_) => {
  return {
    clientSimpleName: {
      padding: "10px 0 0 0",
    },
  };
});

const MainContent = (props) => {
  const { fetchPurchaseOrder, handleFilterChange } = useContext(PageContext);
  const [createState, setCreateState] = useState({
    id: 0,
    po_no: "",
    client: {},
    gold_price: "",
  });
  const [responseError, setResponseError] = useState("");
  const [loading, setLoading] = useState(false);

  const FormikContext = useFormikContext();
  const classes = useStyles();
  const history = useHistory();

  const validatePO = async () => {
    let gold_price = "";
    if (!FormikContext.values.po_no || !FormikContext.values.client.id)
      return setResponseError("檢查PO是否重複及建立新的PO號碼");

    FormikContext.values.client &&
      (FormikContext.values.client_id = FormikContext.values.client.id);

    setLoading(true);
    try {
      const res = await apiRequest().post(
        `${API_ENDPOINT_PREFIX}/purchase-order`,
        {
          po_no: FormikContext.values.po_no,
          client_id: FormikContext.values.client.id,
          gold_price: FormikContext.values.gold_price,
        }
      );
      gold_price = FormikContext.values.gold_price;
      //---------Mock data-------------
      // const res = { data: purchase_order };
      //---------Mock data-------------

      const PO_ID = res.data.data.id;
      const PO_NO = res.data.data.po_no;
      setCreateState(res.data.data);

      handleFilterChange({
        page: 1,
      });

      if (PO_ID) {
        props.setPO_ID(PO_ID);
        props.setPO_NO(PO_NO);
        history.push(`?po_no=${PO_NO}`);
      } else {
        // setResponseError();
      }
      FormikContext.resetForm();

      FormikContext.setFieldValue("gold_price", gold_price);
      setResponseError("");
      props.closeForm();
    } catch (error) {
      setResponseError(error.data.errors.po_no);
    }
    setLoading(false);
  };

  useLayoutEffect(() => {
    if (props.PO_ID && FormikContext.values.po_no) {
      const { id, po_no, client, gold_price } = FormikContext.values;
      setCreateState({
        id,
        po_no,
        client,
        gold_price,
      });
    }
  }, [FormikContext.values]);

  return (
    <>
      {props.PO_ID ? (
        <DataTable
          columns={[
            { field: "id", headerName: "ID", width: 250 },
            { field: "po_no", headerName: "款號", width: 250 },
            {
              field: "client",
              headerName: "客名(簡稱)",
              width: 250,
              renderCell: (params) => params.row?.client?.fake_name,
            },
            {
              field: "gold_price",
              headerName: "金價",
              width: 250,
              renderCell: (params) => params.row?.gold_price,
            },
          ]}
          rows={[createState]}
          autoHeight={true}
          hideFooter={true}
        />
      ) : (
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={6} md={2} lg={2}>
            <FormTextInput
              name="po_no"
              label="PO"
              value={FormikContext.values.po_no}
              onChange={FormikContext.handleChange}
              required={true}
            />
          </Grid>
          <Grid item xs={7} md={3} lg={3} className={classes.clientSimpleName}>
            <AutoCompleteInput
              id="client"
              getOptionLabel={(option) => (option ? option.name : "")}
              optionUrl={`${API_ENDPOINT_PREFIX}/client`}
              label="客名"
              name="client_name"
              value={FormikContext.values.client?.name}
              defaultValue={FormikContext.values.client}
              onChange={(client) =>
                FormikContext.setFieldValue("client", client)
              }
              noOptionsText="noOptionsText"
              error={FormikContext.errors?.client?.id}
              helperText={FormikContext.errors?.client?.id}
              required={true}
            />
          </Grid>
          <Grid item xs={6} md={2} lg={2}>
            <FormTextInput
              name="gold_price"
              label="金價"
              value={FormikContext.values.gold_price}
              onChange={FormikContext.handleChange}
              type="number"
            />
          </Grid>

          <Button
            variant="contained"
            color="primary"
            startIcon={loading ? <LoadingIcon /> : <AddIcon />}
            disabled={loading ? true : false}
            onClick={validatePO}
          >
            新增訂單
          </Button>

          <Grid item xs={6} md={3} lg={3}>
            <Typography color="error">{responseError}</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const PoBasicSection = (props) => {
  const { title = "PO基本資料" } = props;

  return (
    <FormSection
      renderContent={() => <MainContent {...props} />}
      title={title}
      collaspe
    />
  );
};

export default PoBasicSection;
