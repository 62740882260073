import React from "react";
import { useFormikContext } from "formik";
import { TextField, makeStyles } from "@material-ui/core";
import BasicInfoListSection from "./FormSection/BasicInfoListSection";
import GoldSection from "./FormSection/GoldSection";
import StoneSection from "./FormSection/StoneSection";
import RemarkSection from "./FormSection/RemarkSection";
import FormSection from "../../../../component/Form/FormSection";
import AdditionalCostSection from "./FormSection/AdditionalCostSection";
import { Grid } from "@material-ui/core";
import FormSelectInput from "../../../../component/Form/FormSelectInput";
import { genericSelectItemsMapper } from "../../../../helper/model";
import FormTextInput from "../../../../component/Form/FormTextInput";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    position: "absolute",
    zIndex: 2,
    backgroundColor: "#fafafa",
    width: `calc(100% + ${2 * theme.spacing(3)}px)`,
    minHeight: "100vh",
    padding: theme.spacing(3),
    top: 0,
    left: -theme.spacing(3),
  },
  container: {
    display: "flex",
  },
  paper: {
    margin: theme.spacing(1),
  },
  section: {
    margin: "15px 0 0 0 ",
  },
}));

const MarkUpSection = (props) => {
  const { existProductIndex } = props;
  const formik = useFormikContext();
  const currentEditingProduct = formik.values.existProducts[existProductIndex];
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <FormTextInput
          name={`existProducts.${existProductIndex}.mark_up`}
          label="Mark Up"
          value={currentEditingProduct.mark_up}
          onChange={formik.handleChange}
          className={classes.section}
        />
      </Grid>
    </Grid>
  );
};

const SingleProductEditForm = (props) => {
  const { existProductIndex } = props;

  return (
    <>
      <BasicInfoListSection existProductIndex={existProductIndex} />
      <GoldSection existProductIndex={existProductIndex} />
      <StoneSection existProductIndex={existProductIndex} />
      <AdditionalCostSection existProductIndex={existProductIndex} />
      <MarkUpSection existProductIndex={existProductIndex} />
      <RemarkSection existProductIndex={existProductIndex} />
    </>
  );
};

export default SingleProductEditForm;
