import { useState, useEffect } from "react";
import { API_ENDPOINT_PREFIX, apiRequest } from "../../../helper/fetching";
import { Button, Grid, MenuItem } from "@material-ui/core";
import { useFormik, useFormikContext } from "formik";

import FormTextInput from "../../../component/Form/FormTextInput";

import FormSelectInput from "../../../component/Form/FormSelectInput";

import * as Yup from "yup";

import moment from "moment";

import AutoCompleteInput from "../../../component/AutoCompleteInput";

var fileDownload = require("js-file-download");

//Create Page
const Createform = ({ closeForm }) => {
  const [selectID, setSelectID] = useState("");

  const { fetchGoldDeduction } = useFormikContext();

  const formik = useFormik({
    initialValues: {
      record_date: "",
      company_name: "",
      ref_no: "",
      id_no: "",
      identifier: "",
      type: "",
      amount: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      record_date: Yup.date().required("必填"),
      company_name: Yup.string().required("必填"),
      ref_no: Yup.string(),
      id_no: Yup.string(),
      identifier: Yup.string(),
      type: Yup.string().required("必填"),
      amount: Yup.string().required("必填"),
    }),
    onSubmit: async (data, { setSubmitting }) => {
      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/gold-deduction`,
          { ...data, factory_id: null }
        );
        closeForm(formik.handleReset);
        fetchGoldDeduction();
      } catch (error) {
        console.log(error);
      } finally {
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(
    () => formik.setFieldValue("record_date", moment().format("YYYY-MM-DD")),
    []
  );

  useEffect(() => formik.setFieldValue("identifier", selectID), [selectID]);

  // useEffect(() => {
  //   formik.values.type === "OUT"
  //     ? formik.setFieldValue("company_name", "震星")
  //     : formik.setFieldValue("company_name", "Matthew Jewellery");
  // }, [formik.values.type]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormSelectInput
              id="type"
              name="type"
              label="類型"
              labelId="type"
              items={[
                { label: "IN", value: "IN" },
                { label: "OUT", value: "OUT" },
              ]}
              value={formik.values.type}
              onChange={formik.handleChange}
              defaultValue="NO"
            ></FormSelectInput>
          </Grid>

          <Grid item xs={6}>
            <FormTextInput
              name="amount"
              label="amount"
              onChange={formik.handleChange}
              value={formik.values.amount}
              error={formik.touched.amount && formik.errors.amount}
              helperText={formik.touched.amount && formik.errors.amount}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <FormTextInput
          name="record_date"
          onChange={formik.handleChange}
          label="Date"
          value={formik.values.record_date}
          type="date"
          error={formik.touched.record_date && formik.errors.record_date}
          helperText={formik.touched.record_date && formik.errors.record_date}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={4}>
        {formik.values.type === "OUT" ? (
          <AutoCompleteInput
            id="company_name"
            getOptionLabel={(option) => (option ? option.name : "")}
            optionUrl={`${API_ENDPOINT_PREFIX}/factory`}
            label="公司"
            name="company_name"
            onChange={(e) => formik.setFieldValue("company_name", e.name)}
            noOptionsText="沒有選項"
          />
        ) : (
          <FormTextInput
            name="company_name"
            label="公司"
            onChange={formik.handleChange}
            value={formik.values.company_name}
            error={formik.touched.company_name && formik.errors.company_name}
            helperText={
              formik.touched.company_name && formik.errors.company_name
            }
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <FormTextInput
          name="ref_no"
          label="Reference No"
          onChange={formik.handleChange}
          value={formik.values.ref_no}
          error={formik.touched.ref_no && formik.errors.ref_no}
          helperText={formik.touched.ref_no && formik.errors.ref_no}
        />
      </Grid>
      <Grid item xs={4}>
      <AutoCompleteInput
          id="id_no"
          getOptionLabel={(option) =>
            option ? `ID: ${String(option.id)} --- ${option.po_no}` : ""
          }
          optionUrl={`${API_ENDPOINT_PREFIX}/purchase-order`}
          value={formik.values.id_no}
          label="訂單號"
          name="id_no"
          onChange={(e) => {
            formik.setFieldValue("id_no", String(e.id));
            setSelectID(e.po_no);
          }}
          noOptionsText="沒有選項"
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextInput
          name="identifier"
          label="款號"
          onChange={formik.handleChange}
          value={formik.values.identifier}
          error={formik.touched.identifier && formik.errors.identifier}
          helperText={formik.touched.identifier && formik.errors.identifier}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
        >
          新建
        </Button>
      </Grid>
    </Grid>
  );
};

export default Createform;
