import { Button, makeStyles, Slide } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formRoot: {
    position: "absolute",
    zIndex: 2,
    backgroundColor: "#fafafa",
    width: `calc(100% + ${theme.spacing(3)}px)`,
    minHeight: "100vh",
    padding: theme.spacing(3)/2,
    top: 0,
    left: -theme.spacing(3)/2,
  },
}));

const SlideFormWrapper = (props) => {
  const { formIn, closeForm, children } = props
  const classes = useStyles();
  
  return (
    <Slide direction="down" in={formIn}>
      <div className={classes.formRoot}>
        <Button variant="contained" color="primary" onClick={closeForm}>
          返回
        </Button>
        {children}
      </div>
    </Slide>
  );
};

export default SlideFormWrapper;
