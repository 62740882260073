import PageHeadline from "../../../../component/PageHeadline";
import PageSubHeadline from "../../../../component/PageSubHeadline";
import { Button, Grid, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { PageContext } from "../../../../context/PageContext";
import { useContext } from "react";

const PageHeader = (props) => {
  const { openForm } = props;
  const { handleFilterChange } = useContext(PageContext);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <PageHeadline>報價單（總）</PageHeadline>
        <PageSubHeadline>總覽</PageSubHeadline>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openForm}
        >
          新報價單
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="報價單編號"
          variant="outlined"
          onChange={(e) => handleFilterChange({ identifier: e.target.value })}
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default PageHeader;
