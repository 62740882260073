import { useState, useEffect, useContext, Fragment } from "react";
import {
  useFormik,
  useFormikContext,
  FormikProvider,
  FieldArray,
} from "formik";
import { Grid, Button, TableCell, TableRow } from "@material-ui/core";
import FormTextInput from "../../../component/Form/FormTextInput";
import AddIcon from "@material-ui/icons/Add";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../helper/fetching";

import { PageContext } from "../../../context/PageContext";
import * as yup from "yup";

import AutoCompleteInput from "../../../component/AutoCompleteInput";

import PageSubHeadline from "../../../component/PageSubHeadline";

import FormTable from "../../../component/Form/FormTable";

const DiamondTableRow = ({ index, arrayHelpers, value, ...rest }) => {
  const formik = useFormikContext();

  const arrayFieldName = "diamonds";

  return (
    <TableRow {...rest}>
      <TableCell>{value.id}</TableCell>
      <TableCell>{value.full_name}</TableCell>
      <TableCell>{value.stone_code}</TableCell>
      <TableCell>{value.stone_from_hk ? "YES" : "NO"}</TableCell>
      <TableCell>{value.stone_initial}</TableCell>
      <TableCell>{value.stone_is_client ? "YES" : "NO"}</TableCell>
      <TableCell>{value.stone_practical_weight}</TableCell>
      <TableCell>{value.stone_price_code}</TableCell>
      <TableCell>{value.stone_qty}</TableCell>
      <TableCell>{value.stone_size}</TableCell>
      <TableCell>{value.stone_total_qty}</TableCell>
      <TableCell>{value.stone_total_weight}</TableCell>
      <TableCell>{value.stone_type}</TableCell>
      <TableCell>{value.stone_weight}</TableCell>
      <TableCell>{value.unit_price}</TableCell>
    </TableRow>
  );
};

const DiamondsTable = ({ arrayHelpers, ...rest }) => {
  const tableHeader = [
    "ID",
    "Full Name",
    "Stone Code",
    "From HK",
    "Initial",
    "Is Client",
    "Practical Weight",
    "Price Code",
    "QTY",
    "Stone Size",
    "Total Qty",
    "Total Weight",
    "Stone Type",
    "Stone Weight",
    "Unit Price",
  ];

  const formik = useFormikContext();

  const renderRows = () =>
    formik.values.diamonds?.map((diamond, index) => (
      <DiamondTableRow
        key={index}
        index={index}
        arrayHelpers={arrayHelpers}
        value={diamond}
      />
    ));

  return (
    <Fragment>
      <FormTable headerNames={tableHeader} renderRows={renderRows} />
    </Fragment>
  );
};

const CreateInput = ({ rows, invoice, getInvoice, ...rest }) => {
  const { fetchInvoice, rows: poRows } = useContext(PageContext);
  const getPO = poRows.filter((poRow) => poRow.invoice_id === invoice.id)[0];

  const formik = useFormik({
    initialValues: {
      item_no: "",
      po_no: getPO?.po_no,
      style_no: "",
      aa_no: "",
      identifier: "",
      gold_style: "",
      description: "",
      gold_weight: "",
      product_count: "",
      total_gold_weight: "",
      unit_price: "",
      total_price: "",
      diamonds: [],
      client_nickname: "",
    },
    onSubmit: async (value, action) => {
      let diamonds = value.diamonds.map((diamond) => ({
        id: null,
        invoice_input_id: invoice.id,
        qty: diamond.stone_qty,
        stone_initial: diamond.stone_initial || "　",
        weight: diamond.stone_weight,
        unit: "　",
        created_at: null,
        updated_at: null,
      }));

      const ids = invoice.default_remarks.map((item) => item.id);
      const inputs = invoice.inputs;
      const input = { ...value, diamonds: diamonds };
      let arr = [];
      arr.push(...inputs);
      arr.push(input);

      const uploadData = { ...invoice, inputs: arr, default_remark_ids: ids };
      try {
        const res = await apiRequest().put(
          `${API_ENDPOINT_PREFIX}/invoice/${invoice.id}`,
          uploadData
        );
        fetchInvoice();
        rest.closeForm();
        action.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
    validationSchema: yup.object({
      item_no: yup.string().nullable(),
      po_no: yup.string().required("required"),
      style_no: yup.string().nullable(),
      aa_no: yup.string().nullable(),
      identifier: yup.string().required("required"),
      client_nickname: yup.string().nullable(),
      gold_style: yup.string().required("required"),
      description: yup.string().nullable(),
      gold_weight: yup.number().required("required"),
      product_count: yup.number().required("required"),
      total_gold_weight: yup.number().required("required"),
      unit_price: yup.number().required("required"),
      total_price: yup.number().required("required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    formik.setFieldValue(
      "total_price",
      String(formik.values.unit_price * 1 * (formik.values.product_count * 1))
    );
  }, [formik.values.unit_price, formik.values.product_count]);

  return (
    <FormikProvider value={formik}>
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <FormTextInput
            variant="outlined"
            name="identifier"
            label="STYLE"
            onChange={formik.handleChange}
            value={formik.values.identifier}
          /> */}

          <AutoCompleteInput
            id="identifier"
            getOptionLabel={(option) => (option ? option.identifier : "")}
            optionUrl={`${API_ENDPOINT_PREFIX}/work-order/template`}
            label="STYLE"
            name="identifier"
            value={formik.values.identifier}
            defaultValue={formik.values.identifier}
            onChange={(wo) => {
              let replaceItem = [
                {
                  fieldName: "client_nickname",
                  value: wo.client_nickname,
                },
                {
                  fieldName: "aa_no",
                  value: wo.aa_no,
                },
                {
                  fieldName: "gold_style",
                  value: `${
                    wo.gold_type_id === 1
                      ? "14"
                      : wo.gold_type_id === 2
                      ? "18"
                      : ""
                  }${wo.gold_style}`,
                },
                {
                  fieldName: "style_no",
                  value: wo.style_no,
                },
                {
                  fieldName: "diamonds",
                  value: wo.diamonds,
                },
                {
                  fieldName: "product_count",
                  value: wo.product_count,
                },
                {
                  fieldName: "gold_weight",
                  value: wo.gold_weight,
                },
                {
                  fieldName: "identifier",
                  value: wo.identifier,
                },
              ];

              replaceItem.forEach((item) => item.value && formik.setFieldValue(item.fieldName, item.value));
            }}
            noOptionsText="沒有選項"
            error={formik.errors?.identifier}
            helperText={formik.errors?.identifier}
          />
        </Grid>

        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="client_nickname"
            label="Client Nickname"
            onChange={formik.handleChange}
            value={formik.values.client_nickname}
          />
        </Grid>

        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="item_no"
            label="ID no"
            onChange={formik.handleChange}
            value={formik.values.item_no}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="po_no"
            label="PO/PI no"
            onChange={formik.handleChange}
            value={formik.values.po_no}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="style_no"
            label="STYLE no"
            onChange={formik.handleChange}
            value={formik.values.style_no}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="aa_no"
            label="AA no"
            onChange={formik.handleChange}
            value={formik.values.aa_no}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="gold_style"
            label="KT"
            onChange={formik.handleChange}
            value={formik.values.gold_style}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="gold_weight"
            label="G.W.(GM)"
            onChange={formik.handleChange}
            value={formik.values.gold_weight}
          />
          {/* &nbsp; GM: {formik.values.gold_weight * formik.values.product_count} */}
        </Grid>
        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="total_gold_weight"
            label="Total Gold Weight"
            onChange={formik.handleChange}
            value={formik.values.total_gold_weight}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="product_count"
            label="PC"
            onChange={formik.handleChange}
            value={formik.values.product_count}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="unit_price"
            label="UNIT PRICE"
            onChange={formik.handleChange}
            value={formik.values.unit_price}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextInput
            variant="outlined"
            name="total_price"
            label="TOTAL(USD)"
            onChange={formik.handleChange}
            value={formik.values.total_price}
          />
        </Grid>
        <Grid item xs={12}>
          <PageSubHeadline>Diamonds</PageSubHeadline>
          <FieldArray
            name="diamonds"
            render={(arrayHelpers) => (
              <DiamondsTable arrayHelpers={arrayHelpers} />
            )}
          ></FieldArray>
        </Grid>
        {/* <Grid item xs={12}>
          <FormTextInput
            variant="outlined"
            rows={4}
            multiline
            name="description"
            label="Description"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
        </Grid> */}

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
          >
            Create Inputs
          </Button>
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

export default CreateInput;
