import { useState } from "react"

const useFormAnimation = () => {

    // formIn : True:open form, False: close form
    const [formIn, setFormIn] = useState(false);
    const [renderForm, setRenderForm] = useState(() => ()=> {});

    const openForm = (e) => {
        setFormIn(true);
        window.scrollTo(0,0)
    }

    const closeForm = ( onClose = () => {} ) => {
        if(typeof onClose === 'function'){ onClose() }
        setFormIn(false);
    }

    return {
        formIn,
        openForm,
        closeForm,
        renderForm,
        setRenderForm
    }
}

export default useFormAnimation;