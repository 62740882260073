import { FieldArray, useFormikContext } from "formik";
import {
  Button,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import FormTable from "../../../../../component/Form/FormTable";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import CloseIcon from "@material-ui/icons/Close";

const AdditionaCostItem = {
  title: "",
  cost: 0,
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: " 10px 0",
  },
}));

const AdditionCostTableRow = (props) => {
  const { value, existProductIndex, otherItemIndex, arrayHelpers } =
    props;

  const formik = useFormikContext();

  const handleDeleteButtonClick = () => {
    arrayHelpers.remove(otherItemIndex);
  };

  return (
    <TableRow>
      <TableCell>
        <FormTextInput
          name={`other.${otherItemIndex}.title`}
          onChange={formik.handleChange}
          label="項目名稱"
          value={formik.values.other[otherItemIndex].title}
          error={
            formik.touched['other'] &&
            formik.touched['other'][otherItemIndex] &&
            formik.touched['other'][otherItemIndex]['title'] &&
            formik.errors['other'] &&
            formik.errors['other'][otherItemIndex] &&
            formik.errors['other'][otherItemIndex]['title']
          }
          helperText={
            formik.touched['other'] &&
            formik.touched['other'][otherItemIndex] &&
            formik.touched['other'][otherItemIndex]['title'] &&
            formik.errors['other'] &&
            formik.errors['other'][otherItemIndex] &&
            formik.errors['other'][otherItemIndex]['title']
          }
        />
      </TableCell>
      <TableCell>
        <FormTextInput
          name={`other.${otherItemIndex}.cost`}
          onChange={formik.handleChange}
          label="費用"
          value={formik.values.other[otherItemIndex].cost}
          error={
            formik.touched['other'] &&
            formik.touched['other'][otherItemIndex] &&
            formik.touched['other'][otherItemIndex]['cost'] &&
            formik.errors['other'] &&
            formik.errors['other'][otherItemIndex] &&
            formik.errors['other'][otherItemIndex]['cost']
          }
          helperText={
            formik.touched['other'] &&
            formik.touched['other'][otherItemIndex] &&
            formik.touched['other'][otherItemIndex]['cost'] &&
            formik.errors['other'] &&
            formik.errors['other'][otherItemIndex] &&
            formik.errors['other'][otherItemIndex]['cost']
          }
        />
      </TableCell>

      <TableCell>
        <Tooltip title="刪除" arrow>
          <IconButton onClick={handleDeleteButtonClick}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const AdditionCostTable = (props) => {
  const { arrayHelpers, existProductIndex } = props;
  const tableHeader = ["其他費用項目", "費用", "動作"];
  const formik = useFormikContext();
  const classes = useStyles();

  const addAdditionaCostItem = () => {
    arrayHelpers.push(AdditionaCostItem);
  };

  //*** Render single table row ****/
  const renderRows = () => {
    return formik.values.other?.map(
      (otherItem, otherItemIndex) => {
        return (
          <AdditionCostTableRow
            value={otherItem}
            key={otherItemIndex}
            otherItemIndex={otherItemIndex}
            arrayHelpers={arrayHelpers}
          />
        );
      }
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={addAdditionaCostItem}
        className={classes.button}
      >
        新增其他費用項目
      </Button>
      <FormTable headerNames={tableHeader} renderRows={() => renderRows()} />
    </>
  );
};

//**** Main Component ****
const AdditionalCostSection = (props) => {
  const { fieldName = "other" } = props;
  return (
    <FieldArray
      name={fieldName}
      validateOnChange={false}
      render={(arrayHelpers) => (
        <AdditionCostTable arrayHelpers={arrayHelpers} />
      )}
    />
  );
};

export default AdditionalCostSection;
