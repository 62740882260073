import React from "react";
import { useFormikContext } from "formik";
import { makeStyles, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import FormTextInput from "../../../../../component/Form/FormTextInput";

const useStyles = makeStyles(() => ({
  section: {
    margin: "15px 0 0 0 ",
  },
  formTitle1: {
    lineHeight: "63px",
  },
}));

function MarkUpsection() {
  const formik = useFormikContext();
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item>
        <FormTextInput
          name={`mark_up`}
          label="Mark Up"
          value={formik.values.mark_up}
          onChange={formik.handleChange}
          className={classes.section}
        />
      </Grid>
      <Grid item>
        <FormTextInput
          name={`client_gold_mark_up`}
          label="Client Gold Mark Up"
          value={formik.values.client_gold_mark_up}
          onChange={formik.handleChange}
          className={classes.section}
          InputLabelProps={{ shrink: true }}
        />

        {/* <Typography variant="h6" className={classes.formTitle1}>
          Client Gold Mark Up: {formik.values.client_gold_mark_up}
        </Typography> */}
      </Grid>
    </Grid>
  );
}

export default MarkUpsection;
