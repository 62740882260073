import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../../../../helper/fetching";


function ProductLabelPrintingSection() {
    const tableHeaders = ["款號", "件數", "成色", "金重", "石資料1", "石資料2", "石資料3", "牌子價","PO"]
    const formik = useFormikContext();
    const workOrderId = formik.values.id
    const [productLabelData, setProductLabelData] = useState([])

    useEffect(() => {
        (async () => {
            try {
                const res = await apiRequest().get(`${API_ENDPOINT_PREFIX}/work-order/${workOrderId}/card`);
                const json = res.data.data;
                setProductLabelData(json)
            } catch (err) {

            }
        })()
    }, [workOrderId])


    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">

                <TableHead>
                    <TableRow>
                        {tableHeaders?.map((header) => <TableCell>{header}</TableCell>)}
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell>
                            {productLabelData?.identifier}
                            {productLabelData?.stone_initials?.reduce((accumulator, current) => accumulator.concat(current), "")}
                        </TableCell>
                        <TableCell>{productLabelData?.product_count}</TableCell>
                        <TableCell>{productLabelData?.gold_type}</TableCell>
                        <TableCell>{productLabelData?.gold_weight}</TableCell>
                        {productLabelData.stones?.map((stone,index)=>
                            <TableCell>
                                <TableCell>{stone?.qty}</TableCell>
                                <TableCell>{stone?.name}</TableCell>
                                <TableCell>{stone?.weight}</TableCell>
                                <TableCell>{stone?.unit}</TableCell>
                            </TableCell>
                        )}
                        <TableCell>{productLabelData?.net}</TableCell>
                        
                    </TableRow>
                </TableBody>

            </Table>
        </TableContainer>

    );
}

export default ProductLabelPrintingSection;