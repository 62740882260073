import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
} from "../../../../../helper/fetching";
import { useContext } from "react";
import * as Yup from "yup";
import ButtonWithLoading from "../../../../../component/ButtonWithLoading";
import { PageContext } from "../../../../../context/PageContext";
import AddClientInfoSection from "../ComponentSection/AddClientInfoSection";
import { EditFormSchema } from "../../Schema/EditSchema";
import EditClientInfoSection from "../ComponentSection/EditClientInfoSection";

//*style
const useStyles = makeStyles((theme) => ({
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
}));

//*main function Component
function EditForm(props) {
  const { entity: clientInfo, closeForm } = props;
  const { fetchClientList } = useContext(PageContext);

  const formik = useFormik({
    initialValues: clientInfo,
    validationSchema: Yup.object().shape(EditFormSchema),
    onSubmit: async (data, { setSubmitting }) => {
      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/client/${clientInfo.id}?_method=put`,
          data
        );
        const json = res.data;
        closeForm();
      } catch (err) {
      } finally {
        await fetchClientList();
        setSubmitting(false);
      }
    },
  });
  const classes = useStyles();

  return (
    <FormikProvider value={formik}>
      <Typography variant="h3" className={classes.formTitle}>
        編輯客人名單
      </Typography>
      <Typography variant="h5" className={classes.formTitle}>
        客人詳情
      </Typography>
      <EditClientInfoSection />
      <Grid container justifyContent="flex-end">
        <Grid item>
          <ButtonWithLoading
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
            loading={formik.isSubmitting}
          >
            提交
          </ButtonWithLoading>
        </Grid>
      </Grid>
    </FormikProvider>
  );
}

export default EditForm;
