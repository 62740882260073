import * as Yup from "yup";

// schema for order detail section
const orderDetailSection = {
  product_count: Yup.number()
    .typeError("只接受數字")
    .min(0, "數值必須大於0")
    .required("必填"),
  // quotation_gold_weight: Yup.number()
  //   .typeError("只接受數字")
  //   .min(0, "數值必須大於0")
  //   .required("必填"),
  client_nickname: Yup.string().nullable()
};

// schema for basic infomation section
const BasicInfoSection = {
  identifier: Yup.string().required("必填"),
  category: Yup.string().required("必填"),
  id_no: Yup.string().nullable()
};

const diamondInfo = {
  stone_code: Yup.string().required("必填"),
  //   .matches(/((^DT)|(^DR))\d+/, "石碼必須為DT或DR開頭")

  stone_price_code: Yup.string().required("必填"),
  // stone_type: Yup.string().required("必填"),
  stone_size: Yup.string().required("必填"),
  stone_qty: Yup.number()
  .nullable()
  .test("Larger than zero?", "數量必須大於0", (value) => value === undefined || value > 0),
  
  stone_weight: Yup.number()
    .test("Larger than zero?", "重量必須大於0", (value) => value === undefined || value > 0)
    .nullable(),
  unit_price: Yup.number().nullable(),
};

const diamondSection = {
  diamonds: Yup.array().of(Yup.object().shape(diamondInfo)),
};

const GemInfo = {
  stone_code: Yup.string().required("必填"),
  full_name: Yup.string().required("必填"),
  //   .matches(/((^DT)|(^DR))\d+/, "石碼必須為DT或DR開頭")

  stone_price_code: Yup.string().required("必填"),
  // stone_type: Yup.string().required("必填"),
  stone_size: Yup.string().required("必填"),
  stone_qty: Yup.number()
    .required("必填")
    .test("Larger than zero?", "數量必須大於0", (value) => value > 0),
  stone_weight: Yup.number()
    .required("必填")
    .test("Larger than zero?", "重量必須大於0", (value) => value > 0),
  // unit_price: Yup.number().required("必填"),
};

const GemSection = {
  gems: Yup.array().of(Yup.object().shape(GemInfo)),
};

// schema for gold section
const GoldSection = {
  // gold_type: Yup.string().required("必填"),
  gold_type_id: Yup.string().required("必填"),
  gold_style: Yup.string().required("必填"),
  gold_weight: Yup.number()
    .typeError("只接受數字")
    .min(0, "數值必須大於0")
    .required("必填"),
  gold_limit_weight: Yup.number()
    .typeError("只接受數字")
    .min(0, "數值必須大於0")
    .required("必填"),
};

// schema for stone section
const stoneInfo = {
  stone_code: Yup.string().required("必填"),
  //   .matches(/((^DT)|(^DR))\d+/, "石碼必須為DT或DR開頭")

  stone_price_code: Yup.string().required("必填"),
  stone_type: Yup.string().required("必填"),
  stone_size: Yup.string().required("必填"),
  stone_qty: Yup.number()
    .required("必填")
    .test("Larger than zero?", "數量必須大於0", (value) => value > 0),
  stone_weight: Yup.number()
    .required("必填")
    .test("Larger than zero?", "重量必須大於0", (value) => value > 0),
  // unit_price: Yup.number().required("必填"),
};

const stoneSection = {
  stones: Yup.array().of(Yup.object().shape(stoneInfo)),
};

// schema for stone section
const remarkSection = {
  // *** No validation for stone section
};

// schema for factory section
const factorySection = {
  // * suppose no schema in this section since this section is not filled in creating form
  // factory:Yup.string().required("必填"),
  // clientList:Yup.string().required("必填"),
  // shipping_date:Yup.string().required("必填"),
  // order_date:Yup.string().required("必填"),
};

// merge all above section schema

export const createFormSchema = {
  ...orderDetailSection,
  ...BasicInfoSection,
  ...GoldSection,
  ...stoneSection,
  ...remarkSection,
  ...factorySection,
  ...diamondSection,
  ...GemSection,
};
