import PageHeadline from "../../../../component/PageHeadline";
import PageSubHeadline from "../../../../component/PageSubHeadline";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddIcon from "@material-ui/icons/Add";

const PageHeader = (props) => {
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={6}>

        <Button variant="contained" color="primary" onClick={history.goBack}>
          返回
        </Button>

        <PageHeadline>Proformer Report </PageHeadline>
        <PageSubHeadline>總覽</PageSubHeadline>
        
      </Grid>
    </Grid>
  );
};

export default PageHeader;
