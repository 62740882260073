import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

const GoBackButton = ({
    children,
    ...rest
}) => {
    const history = useHistory();

    const handleBackButtonClick = (e) => {
        history.goBack();
    }

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleBackButtonClick}
            {...rest}
        >
            {children}
        </Button>
    );
}

export default GoBackButton;