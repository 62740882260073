export const calQuotationTotal = (formikValues) => {
  const {
    golds,

    diamond1,
    diamond2,

    stones1,
    stones2,

    mold,
    cutting,
    en,
    labour,
    other,

    mark_up,
  } = formikValues;

  let goldPrice = 0;
  let diamon1Price = 0;
  let diamond2Price = 0;

  let stones1Price = 0;
  let stones2Price = 0;

  let moldPrice = 0;
  let cuttingPrice = 0;
  let enPrice = 0;
  let labourPrice = 0;
  let otherPrice = 0;

  let diamond1DiaPrice = 0;
  let diamond2DiaPrice = 0;

  let stones1DiaPrice = 0;
  let stones2DiaPrice = 0;

  let quotationTotal = 0;

  // golds fee
  if (golds) {
    goldPrice = golds.reduce(
      (accumulator, current) => (accumulator += current.weight * current.price),
      0
    );
  }
  //End of golds fee

  // gems fee
  if (diamond1) {
    diamon1Price = diamond1.reduce((accumulator, current) => {
      if (!current.stone_is_client) {
        accumulator += current.weight * current.price;
      }
      return accumulator;
    }, 0);
  }

  if (diamond2) {
    diamond2Price = diamond2.reduce((accumulator, current) => {
      if (!current.stone_is_client) {
        accumulator += current.weight * current.price;
      }
      return accumulator;
    }, 0);
  }

  if (stones1) {
    stones1Price = stones1.reduce((accumulator, current) => {
      if (!current.stone_is_client) {
        accumulator += current.weight * current.price;
      }
      return accumulator;
    }, 0);
  }

  if (stones2) {
    stones2Price = stones2.reduce((accumulator, current) => {
      if (!current.stone_is_client) {
        accumulator += current.weight * current.price;
      }
      return accumulator;
    }, 0);
  }

  //End of gems fee

  // extra fee
  if (mold) {
    moldPrice = mold.reduce(
      (accumulator, current) => (accumulator += parseFloat(current.cost)),
      0
    );
  }

  if (cutting) {
    cuttingPrice = cutting.reduce(
      (accumulator, current) => (accumulator += parseFloat(current.cost)),
      0
    );
  }

  if (en) {
    enPrice = en.reduce(
      (accumulator, current) => (accumulator += parseFloat(current.cost)),
      0
    );
  }
  if (labour) {
    labourPrice = labour.reduce(
      (accumulator, current) => (accumulator += parseFloat(current.cost)),
      0
    );
  }
  if (other) {
    otherPrice = other.reduce(
      (accumulator, current) => (accumulator += parseFloat(current.cost)),
      0
    );
  }
  //End of extra fee

  // dia fee
  if (diamond1) {
    diamond1DiaPrice =
      diamond1.reduce(
        (accumulator, current) =>
          (accumulator +=
            parseFloat(current.name) * parseFloat(current.setting_multiplier)),
        0
      ) || 0;
  }

  if (diamond2) {
    diamond2DiaPrice =
      diamond2.reduce(
        (accumulator, current) =>
          (accumulator += parseFloat(current.setting_fee)),
        0
      ) || 0;
  }

  if (stones1) {
    stones1DiaPrice =
      stones1.reduce(
        (accumulator, current) =>
          (accumulator +=  parseFloat(current.name) * parseFloat(current.setting_multiplier)),
        0
      ) || 0;
  }

  if (stones2) {
    stones2DiaPrice =
      stones2.reduce(
        (accumulator, current) =>
          (accumulator += parseFloat(current.setting_fee)),
        0
      ) || 0;
  }
  //End of dia fee

  // console.log("diamond2Price", diamond2Price);
  // console.log("diamon1Price", diamon1Price);
  // console.log("goldPrice", goldPrice);
  // console.log("stonesPrice", stonesPrice);
  // console.log("moldPrice", moldPrice);
  // console.log("cuttingPrice", cuttingPrice);
  // console.log("enPrice", enPrice);
  // console.log("labourPrice", labourPrice);
  // console.log("otherPrice", otherPrice);
  // console.log("diamond1DiaPrice", diamond1DiaPrice);
  // console.log("diamond2DiaPrice", diamond2DiaPrice);
  // console.log("stonesDiaPrice", stonesDiaPrice);
  // console.log("mark up", mark_up);

  //total fee
  quotationTotal =
    (goldPrice +
      diamon1Price +
      diamond2Price +
      stones1Price +
      stones2Price +
      (moldPrice + cuttingPrice + enPrice + labourPrice + otherPrice) +
      (diamond1DiaPrice +
        diamond2DiaPrice +
        stones1DiaPrice +
        stones2DiaPrice)) *
    (1 + mark_up / 100);

  //End of total fee
  return quotationTotal ? quotationTotal.toFixed(2) : 0;
};
