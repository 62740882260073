import { Grid } from "@material-ui/core";
import { FieldArray } from "formik";
import { useEffect } from "react";
import DetailMainBody from "./component/DetailMainBody";
import { DETAILSECTIONTABLECELL } from "./componentAttribute";

function SingleDetailSection({
  fieldArrayName = "",
  validateOnChange = false,
  mainBodyTitle = "",
  buttonTitle = false,
  detailSectionName = [],
  tableHeader = [],
  pushRowObj = {},
  tableCells = [],
  disableButton = false,
  maxRow = 20,
  calculateTotal = false,
}) {
  return (
    <Grid container spacing={1}>
      <FieldArray
        name={fieldArrayName}
        validateOnChange={validateOnChange}
        render={(arrayHelpers) => (
          <DetailMainBody
            //* Formik field array name
            fieldArrayName={fieldArrayName}
            //* Formik field array helper
            arrayHelpers={arrayHelpers}
            sectionTitle={mainBodyTitle}
            //* The title of add column button
            buttonTitle={buttonTitle}
            detailSectionName={detailSectionName}
            tableHeader={tableHeader}
            tableCells={tableCells}
            //* pushRowObj is the object that adding to a new row
            pushRowObj={pushRowObj}
            //* maxRow is the upper limit of the table rows
            maxRow={maxRow}
            //* disable button disable when the maxRow is reaching the maximum number
            disableButton={disableButton}
            //* some section require to calculate total cost
            calculateTotal={calculateTotal}
          />
        )}
      />
    </Grid>
  );
}

function DetailSection() {
  return (
    <>
      {/* gold */}
      <SingleDetailSection
        fieldArrayName="golds"
        mainBodyTitle="golds"
        buttonTitle="新增 golds"
        detailSectionName="golds"
        tableHeader={["成色","" , "重量", "價格", "單位", "總價格", "動作"]}
        tableCells={DETAILSECTIONTABLECELL.golds}
        pushRowObj={{
          gold_style : "",
          name: "",
          weight: "",
          price: "",
          total_price: "",
          unit: "",
        }}
        maxRow={1}
        calculateTotal={true}
      />

      {/* diamond1 */}
      <SingleDetailSection
        fieldArrayName="diamond1"
        mainBodyTitle="diamond1"
        buttonTitle="新增 diamond1"
        detailSectionName="diamond1"
        tableHeader={["粒數", "重量", "價格", "單位", "總價格", "動作"]}
        tableCells={DETAILSECTIONTABLECELL.diamonds1tablecells}
        pushRowObj={{
          name: "",
          weight: "",
          price: "",
          total_price: "",
          unit: "",
        }}
        calculateTotal={true}
      />

      {/* diamond2 */}
      <SingleDetailSection
        fieldArrayName="diamond2"
        mainBodyTitle="diamond2"
        buttonTitle="新增 diamond2"
        detailSectionName="diamond2"
        tableHeader={["粒數", "重量", "價格", "單位", "總價格", "動作"]}
        tableCells={DETAILSECTIONTABLECELL.diamonds2tablecells}
        pushRowObj={{
          name: "",
          weight: "",
          price: "",
          total_price: "",
          unit: "",
        }}
        calculateTotal={true}
      />

      {/* stones1 */}
      <SingleDetailSection
        fieldArrayName="stones1"
        mainBodyTitle="stones1"
        buttonTitle="新增 stones1"
        detailSectionName="stones1"
        tableHeader={["粒數", "重量", "價格", "單位", "總價格", "動作"]}
        tableCells={DETAILSECTIONTABLECELL.stones1tablecells}
        pushRowObj={{
          name: "",
          weight: "",
          price: "",
          total_price: "",
          unit: "",
        }}
        calculateTotal={true}
      />

      {/* stones2 */}
      <SingleDetailSection
        fieldArrayName="stones2"
        mainBodyTitle="stones2"
        buttonTitle="新增 stones2"
        detailSectionName="stones2"
        tableHeader={["粒數", "重量", "價格", "單位", "總價格", "動作"]}
        tableCells={DETAILSECTIONTABLECELL.stones2}
        pushRowObj={{
          name: "",
          weight: "",
          price: "",
          total_price: "",
          unit: "",
        }}
        calculateTotal={true}
      />

      {/*  mold */}
      <SingleDetailSection
        fieldArrayName="mold"
        mainBodyTitle="mold"
        buttonTitle="新增 mold"
        detailSectionName="mold"
        tableHeader={["價格", "動作"]}
        tableCells={DETAILSECTIONTABLECELL.mold}
        pushRowObj={{ cost: "" }}
      />

      {/*  cutting */}
      <SingleDetailSection
        fieldArrayName="cutting"
        mainBodyTitle="cutting"
        buttonTitle="新增 cutting"
        detailSectionName="cutting"
        tableHeader={["價格", "動作"]}
        tableCells={DETAILSECTIONTABLECELL.cutting}
        pushRowObj={{ cost: "" }}
      />

      {/*  en */}
      <SingleDetailSection
        fieldArrayName="en"
        mainBodyTitle="en"
        buttonTitle="新增 en"
        detailSectionName="en"
        tableHeader={["價格", "動作"]}
        tableCells={DETAILSECTIONTABLECELL.en}
        pushRowObj={{ cost: "" }}
      />

      {/*  labour */}
      <SingleDetailSection
        fieldArrayName="labour"
        mainBodyTitle="labour"
        buttonTitle="新增 labour"
        detailSectionName="labour"
        tableHeader={["價格", "動作"]}
        tableCells={DETAILSECTIONTABLECELL.labour}
        pushRowObj={{ cost: "" }}
      />

      {/* stone */}
      <SingleDetailSection
        fieldArrayName="stones1"
        mainBodyTitle="stones1"
        buttonTitle="新增 stones1"
        detailSectionName="stones1"
        tableHeader={["Setting Multiplier", "STONE1 SETTING"]}
        tableCells={DETAILSECTIONTABLECELL.stone1setting}
        pushRowObj={{ setting_multiplier: "",  setting_fee: "" }}
        disableButton={true}
      />

      <SingleDetailSection
        fieldArrayName="stones2"
        mainBodyTitle="stones2"
        buttonTitle="新增 stones2"
        detailSectionName="stones2"
        tableHeader={["STONE2 SETTING"]}
        tableCells={DETAILSECTIONTABLECELL.stonesetting}
        pushRowObj={{ setting_fee: "" }}
        disableButton={true}
      />

      {/*dia setting diamond1 */}
      <SingleDetailSection
        fieldArrayName="diamond1"
        mainBodyTitle="diamond1"
        buttonTitle="Diamond1 setting"
        detailSectionName="diamond1"
        tableHeader={["Setting Multiplier", "Setting Fee"]}
        tableCells={DETAILSECTIONTABLECELL.diamond1diasetting}
        pushRowObj={{ setting_multiplier: "", setting_fee: "" }}
        disableButton={true}
      />

      {/*dia setting diamond2 */}
      <SingleDetailSection
        fieldArrayName="diamond2"
        mainBodyTitle="diamond2"
        buttonTitle="新增 diamond2 setting"
        detailSectionName="diamond2"
        tableHeader={["Setting Fee"]}
        tableCells={DETAILSECTIONTABLECELL.diamond2diasetting}
        pushRowObj={{ setting_fee: "" }}
        disableButton={true}
      />
    </>
  );
}

export default DetailSection;
