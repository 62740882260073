import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const FactorySection = (props) => {
  const formik = useFormikContext();

  const factoryItemRows = [
    {
      label: "連石重",
      price_name: "factory_field.factory_lin_stone_weight_price",
      order_name: "factory_field.factory_lin_stone_weight_order",
      price: formik.values.factory_field?.factory_lin_stone_weight_price,
      order: formik.values.factory_field?.factory_lin_stone_weight_order,
    },
    {
      label: "計金",
      price_name: "factory_field.factory_ji_gold_price",
      order_name: "factory_field.factory_ji_gold_order",
      price: formik.values.factory_field?.factory_ji_gold_price,
      order: formik.values.factory_field?.factory_ji_gold_order,
    },
    {
      label: "人工-1",
      price_name: "factory_field.factory_salary_price_1",
      price_name_remark: "factory_field.factory_salary_price_remarks_1",
      order_name: "factory_field.factory_salary_order_1",
      order_name_remark: "factory_field.factory_salary_order_remarks_1",

      price: formik.values.factory_field?.factory_salary_price_1,
      price_remark: formik.values.factory_field?.factory_salary_price_remarks_1,
      order: formik.values.factory_field?.factory_salary_order_1,
      order_remark: formik.values.factory_field?.factory_salary_order_remarks_1,
    },
    {
      label: "人工-2",
      price_name: "factory_field.factory_salary_price_2",
      price_name_remark: "factory_field.factory_salary_price_remarks_2",
      order_name: "factory_field.factory_salary_order_2",
      order_name_remark: "factory_field.factory_salary_order_remarks_2",

      price: formik.values.factory_field?.factory_salary_price_2,
      price_remark: formik.values.factory_field?.factory_salary_price_remarks_2,
      order: formik.values.factory_field?.factory_salary_order_2,
      order_remark: formik.values.factory_field?.factory_salary_order_remarks_2,
    },
    {
      label: "人工-3",
      price_name: "factory_field.factory_salary_price_3",
      price_name_remark: "factory_field.factory_salary_price_remarks_3",
      order_name: "factory_field.factory_salary_order_3",
      order_name_remark: "factory_field.factory_salary_order_remarks_3",

      price: formik.values.factory_field?.factory_salary_price_3,
      price_remark: formik.values.factory_field?.factory_salary_price_remarks_3,
      order: formik.values.factory_field?.factory_salary_order_3,
      order_remark: formik.values.factory_field?.factory_salary_order_remarks_3,
    },
    {
      label: "人工-4",
      price_name: "factory_field.factory_salary_price_4",
      price_name_remark: "factory_field.factory_salary_price_remarks_4",
      order_name: "factory_field.factory_salary_order_4",
      order_name_remark: "factory_field.factory_salary_order_remarks_4",

      price: formik.values.factory_field?.factory_salary_price_4,
      price_remark: formik.values.factory_field?.factory_salary_price_remarks_4,
      order: formik.values.factory_field?.factory_salary_order_4,
      order_remark: formik.values.factory_field?.factory_salary_order_remarks_4,
    },
    {
      label: "人工-5",
      price_name: "factory_field.factory_salary_price_5",
      price_name_remark: "factory_field.factory_salary_price_remarks_5",
      order_name: "factory_field.factory_salary_order_5",
      order_name_remark: "factory_field.factory_salary_order_remarks_5",

      price: formik.values.factory_field?.factory_salary_price_5,
      price_remark: formik.values.factory_field?.factory_salary_price_remarks_5,
      order: formik.values.factory_field?.factory_salary_order_5,
      order_remark: formik.values.factory_field?.factory_salary_order_remarks_5,
    },

    {
      label: "爪/釘/微",
      price_name: "factory_field.factory_gwa_or_den_or_mai_price",
      order_name: "factory_field.factory_gwa_or_den_or_mai_order",
      price: formik.values.factory_field?.factory_gwa_or_den_or_mai_price,
      order: formik.values.factory_field?.factory_gwa_or_den_or_mai_order,
    },
    {
      label: "加鏈",
      price_name: "factory_field.factory_ga_lin_price",
      order_name: "factory_field.factory_ga_lin_order",
      price: formik.values.factory_field?.factory_ga_lin_price,
      order: formik.values.factory_field?.factory_ga_lin_order,
    },
    {
      label: "辦費",
      price_name: "factory_field.factory_ban_fei_price",
      order_name: "factory_field.factory_ban_fei_order",
      price: formik.values.factory_field?.factory_ban_fei_price,
      order: formik.values.factory_field?.factory_ban_fei_order,
    },
    {
      label: "石價",
      price_name: "",
      order_name: "factory_field.factory_stone_price",
      price: null,
      order: formik.values.factory_field?.factory_stone_price,
    },
    {
      label: "车石",
      price_name: "factory_field.car_stone_fee_price",
      order_name: "factory_field.car_stone_fee_order",
      price: formik.values.factory_field?.car_stone_fee_price,
      order: formik.values.factory_field?.car_stone_fee_order,
    },
    {
      label: "配件",
      price_name: "factory_field.accessories_fee_price",
      order_name: "factory_field.accessories_fee_order",
      price: formik.values.factory_field?.accessories_fee_price,
      order: formik.values.factory_field?.accessories_fee_order,
    },
    {
      label: "胶模",
      price_name: "factory_field.mold_fee_price",
      order_name: "factory_field.mold_fee_order",
      price: formik.values.factory_field?.mold_fee_price,
      order: formik.values.factory_field?.mold_fee_order,
    },
    {
      label: "總數",
      price_name: "",
      order_name: "factory_field.factory_total_count",
      price: null,
      order: formik.values.factory_field?.factory_total_count,
    },
    {
      label: "OLD WG",
      price_name: "",
      order_name: "factory_field.factory_old_wg",
      price: null,
      order: formik.values.factory_field?.factory_old_wg,
    },
    {
      label: "OLD T/P",
      price_name: "",
      order_name: "factory_field.factory_t_or_p",
      price: null,
      order: formik.values.factory_field?.factory_t_or_p,
    },
    {
      label: "NET",
      price_name: "",
      order_name: "factory_field.factory_net",
      price: null,
      order: formik.values.factory_field?.factory_net,
    },
    {
      label: "OLD NO",
      price_name: "",
      order_name: "factory_field.factory_old_no",
      price: null,
      order: formik.values.factory_field?.factory_old_no,
    },
  ];

  return (
    <>
      <Grid container spacing={1}>
        {/* <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="factory_po_no"
            label="客PO"
            name="factory_po_no"
            value={formik.values.factory_field?.factory_po_no}
            onChange={formik.handleChange}
            required={false}
          />
        </Grid> */}
        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="factory_shipping_date"
            label="出貨日期"
            name="factory_field.factory_shipping_date"
            value={formik.values.factory_field?.factory_shipping_date}
            onChange={formik.handleChange}
            required={false}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="factory_stone_weight"
            label="石重"
            name="factory_field.factory_stone_weight"
            value={formik.values.factory_field?.factory_stone_weight}
            onChange={formik.handleChange}
            required={false}
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="factory_lin_stone_weight"
            label="連石重"
            name="factory_field.factory_lin_stone_weight"
            value={formik.values.factory_field?.factory_lin_stone_weight}
            onChange={formik.handleChange}
            required={false}
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="factory_gold_weight"
            label="淨金重"
            name="factory_field.factory_gold_weight"
            value={formik.values.factory_field?.factory_gold_weight}
            onChange={formik.handleChange}
            required={false}
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="factory_gold_limit_weight"
            label="限金重"
            name="factory_field.factory_gold_limit_weight"
            value={formik.values.factory_field?.factory_gold_limit_weight}
            onChange={formik.handleChange}
            required={false}
          />
        </Grid>

        <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="b_b_po"
          label="BB迫"
          name="b_b_po"
          value={formik.values.b_b_po}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="zhi_mo"
          label="執模"
          name="zhi_mo"
          value={formik.values.zhi_mo}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="ding_ban"
          label="釘板"
          name="ding_ban"
          value={formik.values.ding_ban}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="zhao_xiang"
          label="爪鑲"
          name="zhao_xiang"
          value={formik.values.zhao_xiang}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="qi_ding"
          label="起釘"
          name="qi_ding"
          value={formik.values.qi_ding}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="ao_zhou_xiang"
          label="澳洲鑲"
          name="ao_zhou_xiang"
          value={formik.values.ao_zhou_xiang}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="po_xiang"
          label="迫鑲"
          name="po_xiang"
          value={formik.values.po_xiang}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="other"
          label="其他"
          name="other"
          value={formik.values.other}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="la_zhong"
          label="臘重"
          name="la_zhong"
          value={formik.values.la_zhong}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="zhi_mo_zhong"
          label="執模重"
          name="zhi_mo_zhong"
          value={formik.values.zhi_mo_zhong}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="zhi_bian_zhong"
          label="執邊重"
          name="zhi_bian_zhong"
          value={formik.values.zhi_bian_zhong}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="tu_zhi"
          label="圖紙"
          name="tu_zhi"
          value={formik.values.tu_zhi}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="mo"
          label="模"
          name="mo"
          value={formik.values.mo}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="la"
          label="臘"
          name="la"
          value={formik.values.la}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="qi_ya"
          label="氣壓"
          name="qi_ya"
          value={formik.values.qi_ya}
          onChange={formik.handleChange}
        />
      </Grid>
      {/* //////////////////////////////////////// */}

      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="diamond_practical_qty"
          label="鑽石實用石粒"
          name="diamond_practical_qty"
          value={formik.values.diamond_practical_qty}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="diamond_practical_weight"
          label="鑽石實用石重 (3小數位)"
          name="diamond_practical_weight"
          value={formik.values.diamond_practical_weight}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="gem_practical_qty"
          label="寶石實用石粒"
          name="gem_practical_qty"
          value={formik.values.gem_practical_qty}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="gem_practical_weight"
          label="寶石實用石重 (3小數位)"
          name="gem_practical_weight"
          value={formik.values.gem_practical_weight}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="chain_weight"
          label="链重"
          name="chain_weight"
          value={formik.values.chain_weight}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="hook_weight"
          label="鱼勾"
          name="hook_weight"
          value={formik.values.hook_weight}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="incoming_gold_weight"
          label="来金重"
          name="incoming_gold_weight"
          value={formik.values.incoming_gold_weight}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="accessories_weight"
          label="配件重"
          name="accessories_weight"
          value={formik.values.accessories_weight}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="er_po"
          label="耳迫"
          name="er_po"
          value={formik.values.er_po}
          onChange={formik.handleChange}
        />
      </Grid>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>打價</TableCell>
                <TableCell>打價 - Remarks</TableCell>
                <TableCell>落貨</TableCell>
                <TableCell>落貨 - Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {factoryItemRows.map((factoryItemRow) => (
                <TableRow key={factoryItemRow.label}>
                  <TableCell component="th" scope="row">
                    {factoryItemRow.label}
                  </TableCell>

                  {/* //*some input fields require fill order only */}
                  {factoryItemRow.price_name ? (
                    <TableCell align="right">
                      <FormTextInput
                        variant="outlined"
                        id={factoryItemRow.price_name}
                        label={factoryItemRow.label}
                        name={factoryItemRow.price_name}
                        value={factoryItemRow.price}
                        onChange={formik.handleChange}
                        required={false}
                      />
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}

                  {factoryItemRow.price_name_remark ? (
                    <TableCell align="right">
                      <FormTextInput
                        variant="outlined"
                        id={factoryItemRow.price_name_remark}
                        label={factoryItemRow.label + " Remarks"}
                        name={factoryItemRow.price_name_remark}
                        value={factoryItemRow.price_remark}
                        onChange={formik.handleChange}
                        required={false}
                      />
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}

                  <TableCell align="right">
                    <FormTextInput
                      variant="outlined"
                      id={factoryItemRow.order_name}
                      label={factoryItemRow.label}
                      name={factoryItemRow.order_name}
                      value={factoryItemRow.order}
                      onChange={formik.handleChange}
                      required={false}
                    />
                  </TableCell>

                  {factoryItemRow.order_name_remark ? (
                    <TableCell align="right">
                      <FormTextInput
                        variant="outlined"
                        id={factoryItemRow.order_name_remark}
                        label={factoryItemRow.label + " Remarks"}
                        name={factoryItemRow.order_name_remark}
                        value={factoryItemRow.order_remark}
                        onChange={formik.handleChange}
                        required={false}
                      />
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default FactorySection;
