import PageHeadline from "../../../../component/PageHeadline";
import PageSubHeadline from "../../../../component/PageSubHeadline";
import { Button, Grid, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useContext } from "react";
import { PageContext } from "../../../../context/PageContext";

const PageHeader = ({ openForm }) => {
  const { handleFilterChange } = useContext(PageContext);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <PageHeadline>製工單（總）</PageHeadline>
        <PageSubHeadline>總覽</PageSubHeadline>
        <TextField
          label="款號"
          variant="outlined"
          onChange={(e) => handleFilterChange({ identifier: e.target.value })}
          size="small"
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openForm}
        >
          新製工單
        </Button>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
