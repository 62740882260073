export const fields = [
    { item: "Itemnumber", data: "demo data" },
    { item: "Image", data: "demo data" },
    { item: "款號", data: "demo data" },
    { item: "成色", data: "demo data" },
    { item: "手寸/鏈長", data: "demo data" },
    { item: "石類全名", data: "demo data" },
    { item: "ID", data: "demo data" },
    { item: "數量", data: "demo data" },
    { item: "數量_total", data: "demo data" },
    { item: "Gold/Casting_金價", data: "demo data" },
    { item: "Gold/Casting_Price/G", data: "demo data" },
    { item: "Gold/Casting_Wt", data: "demo data" },
    { item: "Gold/Casting_Amt.", data: "demo data" },
    { item: "Diamond_pcs", data: "demo data" },
    { item: "Diamond_wt", data: "demo data" },
    { item: "Diamond_u_price", data: "demo data" },
    { item: "Diamond_Amt.", data: "demo data" },
    { item: "Setting_pcs(1)", data: "demo data" },
    { item: "Setting_u_price(1)", data: "demo data" },
    { item: "Setting_Amt.(1)", data: "demo data" },
    { item: "Setting_pcs(2)", data: "demo data" },
    { item: "Setting_u_price(2)", data: "demo data" },
    { item: "Setting_Amt.(2)", data: "demo data" },
    { item: "Labour_Basic", data: "demo data" },
    { item: "Labour_Extra", data: "demo data" },
    { item: "Labour_Amt", data: "demo data" },
    { item: "U_Cost", data: "demo data" },
    { item: "U_Total", data: "demo data" },
    { item: "TotalCost", data: "demo data" },
    { item: "Preview_showquotationquotation", data: "demo data" },
    { item: "PROINVOICENO", data: "demo data" },
    { item: "YOURPO", data: "demo data" },
    { item: "Date", data: "demo data" },
]


export let dataOrderFormat = {
        item:"",
        picture:"",
        product_no:"",
        id:"",
        qt: "",
        gold_casting: [] ,
        diamond: [],
        colorstone_pearl: [] ,
        setting: [],
        labour: [] ,
        u_cost: "",
        u_total: ""
}

export const demoData = [
    {
        item:1,
        picture:"image",
        product_no:"product_no",
        id:"ID",
        qt: "QT",
        gold_casting: ["Gold/oz","Price/G","Wt.","Amt"] ,
        diamond: ["Pcs","Wt.","U-Price","Amt."],
        colorstone_pearl: ["Pcs","Wt.","U-Price","Amt."] ,
        setting: ["Pcs","U-Price","Amt."],
        labour: ["Basic","Extra","Amt."] ,
        u_cost: "U-COST" ,
        u_total: "U-TOTAL"

    },
    {
        picture:"image",
        item:2,
        product_no:"product_no",
        id:"ID",
        qt: "QT",
        gold_casting: ["Gold/oz","Price/G","Wt.","Amt"] ,
        diamond: ["Pcs","Wt.","U-Price","Amt."],
        colorstone_pearl: ["Pcs","Wt.","U-Price","Amt."] ,
        setting: ["Pcs","U-Price","Amt."],
        labour: ["Basic","Extra","Amt."] ,
        u_cost: "U-COST" ,
        u_total: "U-TOTAL"

    },
]
// export const headers = [
//     { item: "ITEM" },
//     { picture: "PICTURE" },
//     { productno: "PRODUCT NO" },
//     { id: "ID" },
//     { qt: "QT" },
//     { gold_casting: "GOLD/CASTING" },
//     { diamond: "DIAMOND" },
//     { colorstone_pearl: "COLORSTONE/PEARL" },
//     { setting: "SETTING" },
//     { labour: "LABOUR" },
//     { u_cost: "U-COST" },
//     { u_total: "U-TOTAL" },
// ]


export const proformerReportHeaders =
    [
        {
            header: "ITEM",
            subHeaders: [],
        },
        {
            header: "PICTURE",
            subHeaders: [],
        },
        {
            header: "PRODUCT NO",
            subHeaders: [],
        },
        {
            header: "ID",
            subHeaders: []
        },
        {
            header: "QT",
            subHeaders:[]
        },
        {
            header: "GOLD/CASTING",
            subHeaders: ["Gold/oz", "Price/G", "Wt.", "Amt"]
        },
        {
            header: "DIAMOND",
            subHeaders: ["Pcs", "Wt.", "U-price.", "Amt"]
        },
        {
            header: "COLOR STONE/PEARL",
            subHeaders: ["Pcs", "Wt.", "U-price.", "Amt"]
        },
        {
            header: "SETTING",
            subHeaders: ["Pcs", "U-price.", "Amt"]
        },
        {
            header: "LABOUR",
            subHeaders: ["Basic", "Extra", "Amt"]
        },
        {
            header: "U-COST",
            subHeaders: ["US$"]
        },
        {
            header: "U-TOTAL",
            subHeaders: ["US$"]
        },

    ]


