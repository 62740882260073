import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core";
import FormTextInput from "../../component/Form/FormTextInput";
import { useHistory } from "react-router-dom";
import { validteField } from "../../helper/validate";
import { apiRequest, API_ENDPOINT_AUTH_PREFIX } from "../../helper/fetching";
import GoBackButton from "../../component/GoBackButton";

const ForgetPasswordPage = () => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email()
        .required("Required")
        .test(
          "Unique User Email",
          "This email do not exist",
          async function (value) {
            const data = {
              email: value,
            };
            const validate = await validteField("user/email", data);
            if (!validate.status) {
              if (validate.errors.email) {
                return true;
              }
              return false;
            }

            return false;
          }
        ),
    }),
    onSubmit: async (data, { setSubmitting }) => {
      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_AUTH_PREFIX}/forget-password`,
          data
        );
      } catch (err) {
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 64,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GoBackButton
            style={{
              alignSelf: "start",
            }}
          >
            返回
          </GoBackButton>
          <Typography component="h1" variant="h5">
            忘記密碼
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormTextInput
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              fullWidth
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ marginTop: 24, marginBottom: 16 }}
              disabled={formik.isSubmitting}
            >
              提交
            </Button>
          </Box>
        </Box>
      </Container>
    </FormikProvider>
  );
};

export default ForgetPasswordPage;
