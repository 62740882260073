import React, { useState } from "react";
import { Grid, makeStyles, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import AutoCompleteInput from "../../../../../component/AutoCompleteInput";
import {
  API_ENDPOINT_PREFIX,
} from "../../../../../helper/fetching";


function OrderDetailSection() {
  const formik = useFormikContext();
  //Gold limit section
  const isUpperLimitPass = (limitValue = 0, factoryLimit = 0) => {
    if (!limitValue || !factoryLimit) {
      return "未有數值"
    }


    let upperLimit = limitValue * 1.04;
    if ((factoryLimit >= limitValue) && factoryLimit <= upperLimit) {
      return "合格";
    } else {
      return "不合格";
    }
  }


  const isLowerLimitPass = (limitValue = 0, factoryLimit = 0) => {
    if (!limitValue || !factoryLimit) {
      return "未有數值"
    }

    if (factoryLimit >= limitValue) {
      return "合格";
    } else {
      return "不合格";
    }

  }


  const goldLimitWeightRows = [
    { name: "gold_limit_weight_product", label: "限金重上限", valueMultipler: 1.04, refField: "gold_limit_weight", refFactoryField: "factory_gold_limit_weight", checkResult: isUpperLimitPass },
    { name: "factory_gold_limit_weight_product", label: "限金重下限", valueMultipler: 1, refField: "gold_limit_weight", refFactoryField: "factory_gold_limit_weight", checkResult: isLowerLimitPass },
  ]

  return (
    <>
      <Grid container spacing={1}>

        <Grid item xs={6} md={2} lg={2}>
          <AutoCompleteInput
            id="factory.name"
            getOptionLabel={(option) => (option ? option.name : "")}
            optionUrl={`${API_ENDPOINT_PREFIX}/factory`}
            label="負責廠方"
            name="factory.name"
            value={formik.values.factory?.name}
            defaultValue={formik.values.factory}
            onChange={factory => formik.setFieldValue('factory', factory)}
            noOptionsText="沒有選項"
            error={formik.errors?.factory?.name}
            helperText={formik.errors?.factory?.name}
          />
        </Grid>

        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="order_date"
            label="落單日期"
            name="order_date"
            value={formik.values.order_date}
            onChange={formik.handleChange}
            required={true}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="shipping_date"
            label="出貨日期"
            name="shipping_date"
            value={formik.values.shipping_date}
            onChange={formik.handleChange}
            required={true}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={6} md={2} lg={2}>
          <FormTextInput
            variant="outlined"
            id="product_count"
            label="工單件數"
            name="product_count"
            value={formik.values.product_count}
            onChange={formik.handleChange}
            required={true}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

      </Grid>


      <TableContainer component={Paper}>
        <Typography variant="h5" id="tableTitle" component="div">
          限金重結果表格
        </Typography>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>項目</TableCell>
              <TableCell>限金重</TableCell>
              <TableCell>限金重(廠)</TableCell>
              <TableCell>限金重(限值)</TableCell>
              <TableCell>結果</TableCell>
            </TableRow>
          </TableHead>
          {
            goldLimitWeightRows.map((goldLimitWeightRow, index) => {

              return (
                <TableRow>
                  <TableCell>
                    <Typography>{goldLimitWeightRow.label}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography>{formik.values[goldLimitWeightRow.refField]}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography>{formik.values[goldLimitWeightRow.refFactoryField]}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography>{formik.values[goldLimitWeightRow.refField] ? (formik.values[goldLimitWeightRow.refField] * goldLimitWeightRow.valueMultipler).toFixed(2) : "未有數值"}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography>{goldLimitWeightRow.checkResult(formik.values[goldLimitWeightRow.refField], formik.values[goldLimitWeightRow.refFactoryField])}</Typography>
                  </TableCell>

                </TableRow>
              )
            })

          }

        </Table>
      </TableContainer>


    </>
  );
}

export default OrderDetailSection;