import { Redirect } from "react-router";
import MainRoute from "../component/MainRoute";
import SignUpPage from "../Page/SignUpPage";
import LoginPage from "../Page/LoginPage";
import ForgetPasswordPage from "../Page/ForgetPasswordPage";
import ProductListingPage from "../Page/ProductPage/ProductListingPage";
import QuotationListingPage from "../Page/QuotationPage/QuotationListingPage";
import ClientListPage from "../Page/ClientPage/ClientListingPage";
import ProformerreportPage from "../Page/ProformerReportPage/ProformerReportListingPage";
import PurchaseOrderPage from "../Page/PurchaseOrderPage";
import OrderPage from "../Page/OrderPage";
import InvoicePage from "../Page/InvoicePage";
import CardPage from "../Page/CardPage";
import OutstandingReport from "../Page/OutstandingReportPage";
import DiamondDeductionPage from "../Page/DiamondDeductionPage";
import GoldDeductionPage from "../Page/GoldDeductionPage";
import PourGoldPage from "../Page/PourGoldPage";
import FactoryReceiptPage from "../Page/FactoryReceiptPage";
import FactoriesPage from "../Page/FactoriesPage";
import PurchaseOrderProcessPage from "../Page/PurchaseOrderProcessPage";
import UserManagementPage from "../Page/UserManagementPage";
import NoPermissionPage from "../Page/NoPermissionPage";

export const privateRoutes = [
  {
    path: "/login",
    exact: true,
    component: () => <Redirect to="/" />,
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/workorder" />,
  },
  {
    navigatorName: "製工單（總）",
    path: "/workorder",
    component: MainRoute,
    routes: [
      {
        path: "/workorder",
        exact: true,
        component: ProductListingPage,
      },
    ],
  },
  {
    navigatorName: "報價單（總）",
    path: "/quotation",
    component: MainRoute,
    routes: [
      {
        path: "/quotation",
        exact: true,
        component: QuotationListingPage,
      },
    ],
  },
  {
    navigatorName: "訂單",
    path: "/order",
    component: MainRoute,
    routes: [
      {
        path: "/order",
        exact: true,
        component: OrderPage,
      },
    ],
  },
  {
    navigatorName: "Invoice",
    path: "/invoice",
    component: MainRoute,
    routes: [
      {
        path: "/invoice",
        exact: true,
        component: InvoicePage,
      },
    ],
  },
  {
    navigatorName: "牌仔",
    path: "/card",
    component: MainRoute,
    routes: [
      {
        path: "/card",
        exact: true,
        component: CardPage,
      },
    ],
  },
  // {
  //   navigatorName: "訂單",
  //   path: "/purchase-order",
  //   component: MainRoute,
  //   routes: [
  //     {
  //       path: "/purchase-order",
  //       exact: true,
  //       component: PurchaseOrderPage,
  //     },
  //   ],
  // },
  {
    navigatorName: "客人名單",
    path: "/client",
    component: MainRoute,
    routes: [
      {
        path: "/client",
        exact: true,
        component: ClientListPage,
      },
    ],
  },
  {
    navigatorName: "貨物進度",
    path: "/purchase-order-process",
    component: MainRoute,
    routes: [
      {
        path: "/purchase-order-process",
        exact: true,
        component: PurchaseOrderProcessPage,
      },
    ],
  },
  {
    // navigatorName: "Proformer Report",
    path: "/proformerreport",
    component: MainRoute,
    routes: [
      {
        path: "/proformerreport",
        exact: true,
        component: ProformerreportPage,
      },
    ],
  },
  {
    navigatorName: "Outstanding Report",
    path: "/outstanding-report",
    component: MainRoute,
    routes: [
      {
        path: "/outstanding-report",
        exact: true,
        component: OutstandingReport,
      },
    ],
  },
  {
    navigatorName: "扣石",
    path: "/diamond-deduction",
    component: MainRoute,
    routes: [
      {
        path: "/diamond-deduction",
        exact: true,
        component: DiamondDeductionPage,
      },
    ],
  },
  {
    navigatorName: "扣金",
    path: "/gold-deduction",
    component: MainRoute,
    routes: [
      {
        path: "/gold-deduction",
        exact: true,
        component: GoldDeductionPage,
      },
    ],
  },
  {
    navigatorName: "倒金表",
    path: "/pour-gold",
    component: MainRoute,
    routes: [
      {
        path: "/pour-gold",
        exact: true,
        component: PourGoldPage,
      },
    ],
  },
  {
    navigatorName: "廠發票",
    path: "/factory-receipt",
    component: MainRoute,
    routes: [
      {
        path: "/factory-receipt",
        exact: true,
        component: FactoryReceiptPage,
      },
    ],
  },
  {
    navigatorName: "廠方",
    path: "/factories",
    component: MainRoute,
    routes: [
      {
        path: "/factories",
        exact: true,
        component: FactoriesPage,
      },
    ],
  },
  {
    navigatorName: "User Management",
    path: "/user-management",
    component: MainRoute,
    routes: [
      {
        path: "/user-management",
        exact: true,
        component: UserManagementPage,
      },
    ],
  },
  {
    path: "/no-permission",
    exact: true,
    component: NoPermissionPage,
  },

];

export const publicRoutes = [
  {
    path: "/login",
    exact: true,
    component: LoginPage,
  },
  {
    path: "/forget-password",
    exact: true,
    component: ForgetPasswordPage,
  },
  {
    path: "/signup",
    exact: true,
    component: SignUpPage,
  },
  {
    path: "*",
    component: () => <Redirect to="/login" />,
  },
];
