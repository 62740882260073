import * as Yup from "yup";

// schema for order detail section
const orderDetailSection = {
  order_date: Yup.string().required("必填"),
  shipping_date: Yup.string().required("必填"),
  factory: Yup.object().shape({
    name: Yup.string().required("必填"),
  }),
};
const markupSection = {
  mark_up: Yup.string().required("必填"),
};

// schema for basic infomation section
const basicInfoSection = {
  identifier: Yup.string().required("必填"),
  category: Yup.string().required("必填"),
};

// schema for gold section
const goldSection = {
  gold_type_id: Yup.string().required("必填"),
  gold_weight: Yup.number()
    .typeError("只接受數字")
    .min(0, "數值必須大於0")
    .required("必填"),
};

// schema for stone section
const stoneInfo = {
  stone_code: Yup.string().nullable(),
  stone_price_code: Yup.string().required("必填"),
  stone_type: Yup.string().required("必填"),
  stone_size: Yup.string().required("必填"),
  stone_qty: Yup.number()
    .required("必填")
    .test("Larger than zero?", "數量必須大於0", (value) => value > 0),
  stone_weight: Yup.number()
    .required("必填")
    .test("Larger than zero?", "重量必須大於0", (value) => value > 0),
};
const stoneSection = {
  stones: Yup.array().of(Yup.object().shape(stoneInfo)),
};
// schema for diamond section
const diamondInfo = {
  stone_code: Yup.string()
    // .matches(/((^DT)|(^DR))\d+/, "石碼必須為DT或DR開頭")
    .required("必填"),
  stone_price_code: Yup.string().required("必填"),
  stone_type: Yup.string().required("必填"),
  stone_size: Yup.string().required("必填"),
  stone_qty: Yup.number()
    .required("必填")
    .test("Larger than zero?", "數量必須大於0", (value) => value > 0),
  stone_weight: Yup.number()
    .required("必填")
    .test("Larger than zero?", "重量必須大於0", (value) => value > 0),
};
const diamondSection = {
  diamonds: Yup.array().of(Yup.object().shape(diamondInfo)),
};

// schema for stone section
const remarkSection = {
  // *** No validation in this section
};

// schema for factory section
const factorySection = {
  // *** No validation in this section
};

//golds
const goldsInfo = {
  gold_style: Yup.string().required("必填"),
  name: Yup.string().required("必填"),
  unit: Yup.string().required("必填"),
  weight: Yup.number()
    .required("必填")
    .test("Larger than zero?", "重量必須大於0", (value) => value > 0),
};

const goldsSection = {
  golds: Yup.array().of(Yup.object().shape(goldsInfo)),
};

const diamond1Info = {
  name: Yup.string().required("必填"),
  price: Yup.string().required("必填"),
  unit: Yup.string().required("必填"),
  weight: Yup.number()
    .required("必填")
    .test("Larger than zero?", "重量必須大於0", (value) => value > 0),
};

const diamond1Section = {
  diamond1: Yup.array().of(Yup.object().shape(diamond1Info)),
};

const diamond2Section = {
  diamond2: Yup.array().of(Yup.object().shape(diamond1Info)),
};

const stones1Section = {
  stones2: Yup.array().of(Yup.object().shape(diamond1Info)),
};

const stones2Section = {
  stones1: Yup.array().of(Yup.object().shape(diamond1Info)),
};

const moldSection = {
  mold: Yup.array().of(
    Yup.object().shape({ cost: Yup.string().required("必填") })
  ),
};

const cuttingSection = {
  cutting: Yup.array().of(
    Yup.object().shape({ cost: Yup.string().required("必填") })
  ),
};

const enSection = {
  en: Yup.array().of(
    Yup.object().shape({ cost: Yup.string().required("必填") })
  ),
};

const labourSection = {
  labour: Yup.array().of(
    Yup.object().shape({ cost: Yup.string().required("必填") })
  ),
};

const otherSection = {
  other: Yup.array().of(
    Yup.object().shape({ cost: Yup.string().required("必填") })
  ),
};

const work_order = {
  ...orderDetailSection,
  ...basicInfoSection,
  ...goldSection,
  ...diamondSection,
  ...stoneSection,
  ...remarkSection,
  ...factorySection,
};

export const createFormSchema = {
  // po_no: Yup.number().required("必填"),
  gold_price: Yup.number().min(1, "數值必須大於0").required("必填"),
  handle_by: Yup.string().required("必填"),
  quotation_order_date: Yup.string().required("必填"),
  // customer_item_no: Yup.string().required("必填"),
  remark1: Yup.string().nullable(),
  remark2: Yup.string().nullable(),
  ...goldsSection,
  ...diamond1Section,
  ...diamond2Section,
  ...stones1Section,
  ...stones2Section,
  ...moldSection,
  ...cuttingSection,
  ...enSection,
  ...labourSection,
  ...otherSection,
  //work_order is removed
  // work_orders:Yup.array().of(Yup.object().shape(work_order)),
};
