import { Route, Switch, Redirect } from "react-router";
import { useContext } from "react";
import { TokenContext } from "../context/TokenContext";

const MainRoute = ({ routes }) => {
  const { permission } = useContext(TokenContext);

  return (
    <Switch>
      {routes.map((route, index) => {
        if (route.submenus) {
          // check if it contains submenus
          route.submenus.map((submenu) => {
            const { path, exact, routes } = submenu;
            return (
              <Route
                key={index}
                path={path}
                exact={exact}
                render={(routeProps) => (
                  <div style={{ position: "relative" }}>
                    <route.component routes={routes} {...routeProps} />
                  </div>
                )}
              />
            );
          });

          const { path, exact, routes } = route;
          return (
            <Route
              key={index}
              path={path}
              exact={exact}
              render={(routeProps) => (
                <div style={{ position: "relative" }}>
                  <route.component routes={routes} {...routeProps} />
                </div>
              )}
            />
          );
        } else {
          const { path, exact, routes } = route;

          return (
            <Route
              key={index}
              path={path}
              exact={exact}
              render={(routeProps) =>
                permission?.unavailable_page?.some(
                  (item) => item === path.replace("/", "")
                ) ? (
                  <Redirect to="/no-permission" />
                ) : (
                  <div style={{ position: "relative" }}>
                    <route.component routes={routes} {...routeProps} />
                  </div>
                )
              }
            />
          );
        }
      })}
    </Switch>
  );
};

export default MainRoute;
