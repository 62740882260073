import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useFormikContext } from "formik";

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    // marginTop: 20,
    tableLayout: "fixed",
  },
}));

const FormTable = ({ headerNames = [], renderRows = () => {} }) => {
  const classes = useStyles();

  const formik = useFormikContext();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.tableRoot}>
        <TableHead>
          <TableRow>
            {headerNames.map((headerName, index) => (
              <TableCell key={index}>{headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ maxHeight: 150 }}>{renderRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default FormTable;
