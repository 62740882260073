import {
  API_ENDPOINT_PREFIX,
  SERVER_URL,
  apiRequest,
} from "../../helper/fetching";
import useFetch from "../../hook/useFetch";
import { PageContext } from "../../context/PageContext";
import EntityMangeLayout from "../../layout/EntityMangeLayout";
import { useContext, useState, Fragment } from "react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  IconButton,
  Tooltip,
  requirePropFactory,
} from "@material-ui/core";
import { useFormik, FormikProvider, useFormikContext } from "formik";
import FormTextInput from "../../component/Form/FormTextInput";
import FormSelectInput from "../../component/Form/FormSelectInput";
import AutoCompleteInput from "../../component/AutoCompleteInput";

import LoadingIcon from "../../component/LoadingIcon";

import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";

const DetailComponent = ({ entity, closeForm }) => {
  const { fetchUser } = useContext(PageContext);

  const [userDetail, setUserDetail] = useState({
    email: entity?.email || "",
    password: entity?.password || "",
    permission_id: entity?.permission_id || "",
  });

  const formik = useFormik({
    initialValues: userDetail,
    onSubmit: async (data, { setSubmitting }) => {
      if (entity === undefined) {
        try {
          const res = await apiRequest().post(
            `${API_ENDPOINT_PREFIX}/auth/register`,
            data
          );
          closeForm();
          fetchUser();
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          const res = await apiRequest().put(
            `${API_ENDPOINT_PREFIX}/user/${entity.id}/permission`,
            {
              permission_id: data.permission_id,
            }
          );
          closeForm();
          fetchUser();
        } catch (err) {
          console.log(err);
        }
      }
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  return (
    <FormikProvider value={formik}>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        {entity === undefined && (
          <Fragment>
            <Grid item xs={6}>
              <FormTextInput
                id="email"
                label="Email"
                name="email"
                type="email"
                InputLabelProps={{ shrink: true }}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextInput
                id="password"
                label="Password"
                name="password"
                InputLabelProps={{ shrink: true }}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
            </Grid>
          </Fragment>
        )}
        <Grid item xs={6}>
          <FormSelectInput
            id="permission_id"
            label="Role"
            name="permission_id"
            InputLabelProps={{ shrink: true }}
            value={formik.values.permission_id}
            onChange={formik.handleChange}
            defaultValue={2}
            items={[
              { label: "SuperAdmin", value: 1 },
              { label: "HK", value: 2 },
              { label: "LF", value: 3 },
              { label: "ZS", value: 4 },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

const RenderHeaderComponent = ({ openForm }) => {
  return (
    <Grid container justifyContent="flex-end">
      <Button variant="contained" color="primary" onClick={openForm}>
        新建用戶
      </Button>
    </Grid>
  );
};

const UserManagementPage = () => {
  const {
    entities: rows,
    isLoading,
    fetchEntity: fetchUser,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/user`,
    paged: true,
  });

  const columns = [
    {
      field: "email",
      headerName: "電郵",
      flex: 1,
    },
    {
      field: "permission_id",
      headerName: "角色",
      flex: 1,
      valueGetter: (params) => {
        switch (params.value) {
          case 1:
            return "SuperAdmin";
          case 2:
            return "HK";
          case 3:
            return "LF";
          case 4:
            return "ZS";
          default:
            return "--";
        }
      },
    },
  ];

  return (
    <PageContext.Provider
      value={{
        fetchUser,
        setReady,
        handleFilterChange,
        rows,
      }}
    >
      <EntityMangeLayout
        columns={columns}
        rows={rows}
        renderHeader={(props) => <RenderHeaderComponent {...props} />}
        renderCreateForm={(props) => <DetailComponent {...props} />}
        renderEditForm={(props) => <DetailComponent {...props} />}
        pageSize={pageSize}
        onPageChange={setPage}
        colActions={["edit"]}
        paginationMode="server"
        totalNumberOfRows={total}
        isLoading={isLoading}
      ></EntityMangeLayout>
    </PageContext.Provider>
  );
};

export default UserManagementPage;
