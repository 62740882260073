import { useState } from "react";
import { API_ENDPOINT_PREFIX, apiRequest } from "../../../helper/fetching";
import useFetch from "../../../hook/useFetch";
import { TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import PopupWidget from "../../../component/PopupWidget";
import DataTable from "../../../component/DataTable";

import { format } from "date-fns";

const CreatePage = ({ fetchOutstandingReport }) => {
  const {
    entities: purchaseOrderRows,
    isLoading,
    fetchEntity: fetchPurchaseOrder,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/purchase-order`,
    paged: true,
    throttleTime: 500,
  });

  const formik = useFormikContext();

  const [ids, setIds] = useState([]);

  const createReportWithIds = async () => {
    if (!ids.length) return;
    try {
      const res = await apiRequest().post(
        `${API_ENDPOINT_PREFIX}/outstanding-report/`,
        {
          purchase_order_ids: ids,
        }
      );
      formik.fetchOutstandingReport();
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectionModelChange = (ids) => setIds(ids);

  return (
    <>
      <PopupWidget
        dialogTitle="Create Report from PO"
        handleSubmit={createReportWithIds}
        confirmButtonText="創建"
        onOpen={fetchPurchaseOrder}
      >
        <TextField
          label="Search PO NO"
          variant="outlined"
          margin="dense"
          onChange={(e) => handleFilterChange({ po_no: e.target.value })}
        />
        <DataTable
          rows={purchaseOrderRows}
          paginationMode="server"
          rowCount={total}
          loading={isLoading}
          pageSize={pageSize}
          onPageChange={setPage}
          checkboxSelection
          disableSelectionOnClick
          isRowSelectable={() => true}
          onSelectionModelChange={onSelectionModelChange}
          density="compact"
          height = "500px"
          columns={[
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "po_no",
              headerName: "PO NO",
              flex: 1,
            },
            {
              field: "created_at",
              headerName: "Date",
              flex: 1,
              valueGetter: (params) =>
                format(new Date(params.value), "MM-dd-yyyy"),
            },
          ]}
        ></DataTable>
      </PopupWidget>
    </>
  );
};

export default CreatePage;
