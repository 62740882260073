// import { Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import PoSearchSection from "./PoSearchSection";
import Paper from "@material-ui/core/Paper";
import { Grid, Typography } from "@material-ui/core";

const LinkUpQuptationSection = ({ index, setFieldValue, formik }) => {
  //confirmed option that add to Formik
  const [preSetValues, setPreSetValues] = useState("");

  // Selected option in search area
  const [selectedOption, setSelectedOption] = useState("");
  useEffect(() => {
    if (preSetValues) {
      //This setFieldValue must use props setFieldValue, dont use formik.setFieldValue directly
      // formik.setFieldValue(`workOrders.${index}.quotation`, preSetValues);
      formik.values.workOrders[index].quotation = preSetValues;
    }
  }, [preSetValues]);

  return (
    <>
      <PoSearchSection
        dialogTitle="本訂單已加入的報價單"
        searchModule="quotation"
        preSetValues={preSetValues}
        setPreSetValues={setPreSetValues}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        columns={[
          {
            field: "id",
            headerName: "ID",
          },
          {
            field: "identifier",
            headerName: "款號",
            flex: 1,
          },
        ]}
      />
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h6">
            已選取的報價單: {preSetValues ? preSetValues.identifier : "----"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default LinkUpQuptationSection;
