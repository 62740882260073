import { FormikProvider, useFormik } from "formik";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../../helper/fetching";
import PoBasicSection from "../../FormSection/PoBasicSection";
import PoWorkOrderSection from "../../FormSection/PoWorkOrderSection";
import {
  initialValues,
  poQuotationColumns,
  poWorkOrderColumns,
} from "../../Constants";
import PoQuotationSection from "../../FormSection/PoQuotationSection";
import { useEffect, useState } from "react";

function CreateForm({ closeForm, ...rest }) {
  const formik = useFormik({
    initialValues: {
      po_no: "",
      client_id: "",
      quotations: [],
      work_orders: [],
    },
    enableReinitialize: true,
    onSubmit: async (data, { setSubmitting }) => {
      const formattedData = {
        ...data,
        client_id: data.client.id,
      };

      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/purchase_order`,
          formattedData
        );

        const json = res.data;

        formik.resetForm();
        closeForm();
      } catch (err) {
      } finally {
        //   await fetchQuotations();

        setSubmitting(false);
      }
    },
  });

  const [PO_ID, setPO_ID] = useState(true);

  const workOrderSearchKeys = {
    identifier: {
      label: "Identifier",
      value: "",
    },
    initials: {
      label: "Initials",
      value: "",
    },
  };

  const QuotationSearchKeys = {
    identifier: {
      label: "Identifier",
      value: "",
    },
  };

  useEffect(() => {}, [PO_ID]);

  return (
    <FormikProvider value={formik}>
      <div {...rest}>
        <div>
          <PoBasicSection setPO_ID={setPO_ID} />

          {PO_ID && (
            <PoWorkOrderSection
              PO_ID={PO_ID}
              searchKeys={workOrderSearchKeys}
              columns={poWorkOrderColumns}
            />
          )}

          {PO_ID && (
            <PoQuotationSection
              PO_ID={PO_ID}
              searchKeys={QuotationSearchKeys}
              columns={poQuotationColumns}
            />
          )}
        </div>
      </div>
    </FormikProvider>
  );
}

export default CreateForm;
