import React from 'react';
import {Grid, Typography,  makeStyles,} from "@material-ui/core";
import {
    FormikProvider,
    useFormik,
} from "formik";
import {
    apiRequest,
    API_ENDPOINT_PREFIX,
} from "../../../../../helper/fetching";
import {  useContext } from "react";
import * as Yup from "yup";
import ButtonWithLoading from "../../../../../component/ButtonWithLoading";
import { PageContext } from "../../../../../context/PageContext";
import AddClientInfoSection from '../ComponentSection/AddClientInfoSection';
import {createFormSchema} from "../../Schema/CreateSchema"

//*style
const useStyles = makeStyles((theme) => ({
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },

}));

//*main function Component
function CreateForm(props) {
    const {closeForm} = props;
    const {fetchClientList} = useContext(PageContext);
    const classes = useStyles();
    const formik = useFormik({
        initialValues:{
            name: "",
            fake_name: "",
            email: "",
            tel1: "",
            tel2: "",
            mobile: "",
            fax: "",
            address1:"" ,
            address2:"" ,
            ups:"" ,
            stamp1:"",
            stamp2:"",
        },
        validationSchema:Yup.object().shape(createFormSchema),
        onSubmit: async(formData, { setSubmitting })=>{
          
        try {
            await apiRequest().post(`${API_ENDPOINT_PREFIX}/client`,formData);
            closeForm();
            formik.resetForm(); 

        } catch (err) {
    
        } finally {
          await fetchClientList();
          setSubmitting(false);
        }
        },
    
    });
  
    return (
    <FormikProvider value={formik}>
       <Typography variant="h3" className={classes.formTitle}>
            新增客人名單
          </Typography>
          <Typography variant="h5" className={classes.formTitle}>
            客人詳情
          </Typography>

        <AddClientInfoSection/>
        
        <Grid container justifyContent="flex-end">
            <Grid item>
              <ButtonWithLoading
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                loading={formik.isSubmitting}
              >
                提交
              </ButtonWithLoading>
            </Grid>
          </Grid>
    </FormikProvider>
    );
}

export default CreateForm;