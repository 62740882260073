import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ReceiptIcon from "@material-ui/icons/Receipt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Fragment } from "react";

import LoadingIcon from "../LoadingIcon";

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    marginTop: 20,
    "& .MuiDataGrid-cell--editable": {
      // backgroundColor: '#ffffff',
      // boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
    },
  },
  linkedRow: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#e6e6e6",
    },
  },
}));

const DataTable = (props) => {
  const classes = useStyles();

  const {
    rows,
    columns,
    colActions = [],
    onEditButtonClick,
    onViewButtonClick,
    onInvoiceButtonClick,
    onDeleteButtonClick,
    onCloneButtonClick,
    onViewReportButtonClick,
    autoHeight = false,
    hideFooter = false,
    isRowSelectable = (params) => false,
    height = "calc(100vh - 260px)",
    loading = false,
    components = {},
  } = props;

  const formattedColumns = columns.map((col) => ({
    ...col,
    disableColumnMenu: true,
    sortable: false,
  }));

  if (
    colActions.length &&
    colActions.every((colAction) => {
      return ["edit", "invoice", "viewReport", "view", "clone", "delete"].some(
        (action) => {
          return action === colAction;
        }
      );
    })
  ) {
    formattedColumns.push({
      field: "actions",
      headerName: "動作",
      flex: 1,
      renderCell: (params) => {
        return (
          <Fragment>
            {colActions.includes("edit") && (
              <Tooltip title="編輯" arrow>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={onEditButtonClick(params.row)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}

            {colActions.includes("view") && (
              <Tooltip title="查看" arrow>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={onViewButtonClick(params.row)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}

            {colActions.includes("invoice") && (
              <Tooltip title="Invoice" arrow>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={onInvoiceButtonClick(params.row)}
                >
                  <ReceiptIcon />
                </IconButton>
              </Tooltip>
            )}

            {colActions.includes("clone") && (
              <Tooltip title="Clone" arrow>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={onCloneButtonClick(params.row)}
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}

            {colActions.includes("delete") && (
              <IconButton
                color="primary"
                component="span"
                onClick={onDeleteButtonClick(params.row)}
              >
                <DeleteIcon />
              </IconButton>
            )}

            {/*colActions.includes("viewReport") && (
              <Tooltip title="Proformer Report" arrow>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={onViewReportButtonClick(params.row)}
                >
                  <DescriptionOutlinedIcon />
                </IconButton>
              </Tooltip>
            )*/}
          </Fragment>
        );
      },
    });
  }

  return (
    <div
      style={{
        height: autoHeight ? "" : height,
      }}
    >
      <DataGrid
        {...props}
        className={classes.tableRoot}
        columns={formattedColumns}
        hideFooterSelectedRowCount={true}
        rowsPerPageOptions={[10]}
        components={{
          ...components,
          [loading ? "Pagination" : ""]: () => (
            <div style={{ margin: "0 36px" }}>
              <LoadingIcon />
            </div>
          ),
        }}
      />
    </div>
  );
};

export default DataTable;
