import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useFormikContext, Formik, Form, Field } from "formik";
import ActionButton from "../../../../../component/Button/ActionButton";
import { SERVER_URL } from "../../../../../helper/fetching";
import { calProductTotal } from "../Calculator/QuotationTotal";
import FormTextInput from "../../../../../component/Form/FormTextInput";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  imageCell: {
    width: "12%",
  },
  image: {
    width: "70% ",
  },
  table: {
    margin: "20px 0 0 0",
  },
});

function createData(
  productID,
  productCategory,
  productCategoryId,
  productImage,
  index
) {
  return {
    productID,
    productCategory,
    productCategoryId,
    productImage,
    index,
  };
}
const tableHeader = [
  //"製工單編號",
  "款號",
  "客人名稱",
  "產品圖片",
  "落單日期",
  "出貨日期",
  "金額",
  "工單數量",
  "動作",
];

const ProductRowCellSection = (props) => {
  const { 
    arrayHelpers,
  } = props;
  const classes = useStyles();
  const formik = useFormikContext();
  

  const workOrderPriceList = formik.values.work_orders?.map((work_order, work_order_index) => {
    // let singleProductPrice = (
    //   work_order.stones?.reduce((sum, {stone_qty, unit_price}) => sum + parseFloat(stone_qty) * parseFloat(unit_price), 0) + 
    //   work_order.additional_costs.reduce((sum, {cost}) => sum + parseFloat(cost), 0) + 
    //   parseFloat(work_order.gold_weight) * formik.values.gold_price
    // ) * (1 + parseFloat(work_order.mark_up) / 100)

    let singleProductPrice = null;
    singleProductPrice = singleProductPrice ? singleProductPrice.toFixed(2) : "----";
    return singleProductPrice;
  });

  const quotationTotal = workOrderPriceList?.reduce((sum, workOrderPrice) => sum + parseFloat(workOrderPrice ? workOrderPrice : 0), 0);


  return (
    <TableContainer component={Paper} spacing={3} className={classes.table}>
      <Table className={classes.table} aria-label="caption table">
        
        <TableHead>
          <TableRow>
            {tableHeader.map((header) => (
              <TableCell>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {
            formik.values.work_orders?.map((work_order, work_order_index) => {
              
            return (
              <TableRow key={work_order.name}>
                {/* <TableCell component="th" scope="row">
                  {work_order.id?.toString().padStart(5, '0')}
                </TableCell> */}
                <TableCell>{work_order.identifier}</TableCell>
                <TableCell>{formik.values.client?.fake_name}</TableCell>
                <TableCell className={classes.imageCell}>
                  {formik.values.images?<img
                    src={`${SERVER_URL}/${formik.values.images?.path}`}
                    className={classes.image}
                  />:"未有圖片"}
                </TableCell>
                <TableCell>{work_order.order_date}</TableCell>
                <TableCell>{work_order.shipping_date}</TableCell>
                <TableCell>{workOrderPriceList[work_order_index]}</TableCell>
                <TableCell>{work_order.product_count}張</TableCell>
                <TableCell>
                  <ActionButton
                    arrayHelpers={arrayHelpers}
                    work_order={work_order}
                    work_order_index={work_order_index}
                    productImgPath={formik.values.images?.path}
                  />
                </TableCell>
              </TableRow>
            );
          })}

        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductRowCellSection;
