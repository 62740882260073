import FormTextInput from "../../../../../../../component/Form/FormTextInput";
import FormSelectInput from "../../../../../../../component/Form/FormSelectInput";
import {
  Grid,
  makeStyles,
  TableRow,
  Typography,
  Button,
  IconButton,
  TableCell,
  Tooltip,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import CloseIcon from "@material-ui/icons/Close";
import FormTable from "../../../../../../../component/Form/FormTable";
import { useEffect, useState } from "react";

const SectionTableCell = ({
  arrayHelpers,
  rowIndex,
  fieldArrayName,
  tableCells,
  isMaxRow,
  setIsMaxRow,
  disableButton,
  calculateTotal,
}) => {
  const formik = useFormikContext();

  const total = (weight = 0, price = 0) => {
    if (weight && price) {
      return (weight * price).toFixed(2);
    } else {
      return "未有數值";
    }
  };

  const handleDeleteButtonClick = () => {
    arrayHelpers.remove(rowIndex);
    //enable button again
    if (isMaxRow) {
      setIsMaxRow(() => !isMaxRow);
    }
  };

  return (
    <TableRow>
      {tableCells.map((cell, index) => {
        if (fieldArrayName === "golds" && index == 3) {
          //only gold section has this special field
          //index==3 means this table cell needs to place at the third column
          formik.values.golds[0].price = (
            (formik.values.gold_price / 31.1035) *
            (formik.values.golds[0].gold_style === 14 ? 0.585 : 0.75) *
            (formik.values.client?.gold_mark_up || 1.15)
          ).toFixed(2);
          return (
            <TableCell>
              {(
                (formik.values.gold_price / 31.1035) *
                (formik.values.golds[0].gold_style === 14 ? 0.585 : 0.75) *
                (formik.values.client?.gold_mark_up || 1.15)
              ).toFixed(2)}
            </TableCell>
          );
        } else if (fieldArrayName === "golds" && index == 0) {
          return (
            <TableCell>
              <FormSelectInput
                name={`${fieldArrayName}.${rowIndex}.${cell.name}`}
                onChange={formik.handleChange}
                label={cell.label.replace("_", " ")}
                value={formik.values[fieldArrayName][rowIndex][cell.name]}
                items={[
                  {
                    label: "14",
                    value: 14,
                  },
                  {
                    label: "18",
                    value: 18,
                  },
                ]}
                error={
                  formik.touched[fieldArrayName] &&
                  formik.touched[fieldArrayName][rowIndex] &&
                  formik.touched[fieldArrayName][rowIndex][cell.name] &&
                  formik.errors[fieldArrayName] &&
                  formik.errors[fieldArrayName][rowIndex] &&
                  formik.errors[fieldArrayName][rowIndex][cell.name]
                }
                helperText={
                  formik.touched[fieldArrayName] &&
                  formik.touched[fieldArrayName][rowIndex] &&
                  formik.touched[fieldArrayName][rowIndex][cell.name] &&
                  formik.errors[fieldArrayName] &&
                  formik.errors[fieldArrayName][rowIndex] &&
                  formik.errors[fieldArrayName][rowIndex][cell.name]
                }
              />
            </TableCell>
          );
        } else {
          return (
            <TableCell>
              <FormTextInput
                name={`${fieldArrayName}.${rowIndex}.${cell.name}`}
                onChange={formik.handleChange}
                label={cell.label.replace("_", " ")}
                value={formik.values[fieldArrayName][rowIndex][cell.name]}
                error={
                  formik.touched[fieldArrayName] &&
                  formik.touched[fieldArrayName][rowIndex] &&
                  formik.touched[fieldArrayName][rowIndex][cell.name] &&
                  formik.errors[fieldArrayName] &&
                  formik.errors[fieldArrayName][rowIndex] &&
                  formik.errors[fieldArrayName][rowIndex][cell.name]
                }
                helperText={
                  formik.touched[fieldArrayName] &&
                  formik.touched[fieldArrayName][rowIndex] &&
                  formik.touched[fieldArrayName][rowIndex][cell.name] &&
                  formik.errors[fieldArrayName] &&
                  formik.errors[fieldArrayName][rowIndex] &&
                  formik.errors[fieldArrayName][rowIndex][cell.name]
                }
              />
            </TableCell>
          );
        }
      })}

      {calculateTotal && (
        <TableCell>
          {total(
            formik.values[fieldArrayName][rowIndex].weight,
            formik.values[fieldArrayName][rowIndex].price
          )}
        </TableCell>
      )}

      {!disableButton && (
        <TableCell>
          <Tooltip title="刪除" arrow>
            <IconButton onClick={handleDeleteButtonClick}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

const Diamond1Section = ({
  arrayHelpers,
  rowIndex,
  fieldArrayName,
  tableCells,
  isMaxRow,
  setIsMaxRow,
  disableButton,
  calculateTotal,
}) => {
  const formik = useFormikContext();
  const handleDeleteButtonClick = () => {
    arrayHelpers.remove(rowIndex);
    //enable button again
    if (isMaxRow) {
      setIsMaxRow(() => !isMaxRow);
    }
  };

  return (
    <TableRow>
      {tableCells.map((cell) => {
        if (cell.name === "Dia setting_fee") {
          return (
            <TableCell>
              <Typography>
                {formik.values[fieldArrayName][rowIndex].setting_multiplier
                  ? (
                      parseFloat(
                        formik.values[fieldArrayName][rowIndex]
                          .setting_multiplier
                      ) *
                      parseFloat(formik.values[fieldArrayName][rowIndex].name)
                    ).toFixed(2)
                  : "----"}
              </Typography>
            </TableCell>
          );
        } else {
          return (
            <TableCell>
              <FormTextInput
                name={`${fieldArrayName}.${rowIndex}.${cell.name}`}
                onChange={formik.handleChange}
                label={cell.label.replace("_", " ")}
                value={formik.values[fieldArrayName][rowIndex][cell.name]}
                error={
                  formik.touched[fieldArrayName] &&
                  formik.touched[fieldArrayName][rowIndex] &&
                  formik.touched[fieldArrayName][rowIndex][cell.name] &&
                  formik.errors[fieldArrayName] &&
                  formik.errors[fieldArrayName][rowIndex] &&
                  formik.errors[fieldArrayName][rowIndex][cell.name]
                }
                helperText={
                  formik.touched[fieldArrayName] &&
                  formik.touched[fieldArrayName][rowIndex] &&
                  formik.touched[fieldArrayName][rowIndex][cell.name] &&
                  formik.errors[fieldArrayName] &&
                  formik.errors[fieldArrayName][rowIndex] &&
                  formik.errors[fieldArrayName][rowIndex][cell.name]
                }
              />
            </TableCell>
          );
        }
      })}

      {calculateTotal && (
        <TableCell>
          {(
            formik.values[fieldArrayName][rowIndex].weight *
            formik.values[fieldArrayName][rowIndex].price
          ).toFixed(2)}
        </TableCell>
      )}

      {!disableButton && (
        <TableCell>
          <Tooltip title="刪除" arrow>
            <IconButton onClick={handleDeleteButtonClick}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

const Stones1Section = ({
  arrayHelpers,
  rowIndex,
  fieldArrayName,
  tableCells,
  isMaxRow,
  setIsMaxRow,
  disableButton,
  calculateTotal,
}) => {
  const formik = useFormikContext();
  const handleDeleteButtonClick = () => {
    arrayHelpers.remove(rowIndex);
    //enable button again
    if (isMaxRow) {
      setIsMaxRow(() => !isMaxRow);
    }
  };

  return (
    <TableRow>
      {tableCells.map((cell) => {
        if (cell.name === "setting_fee") {
          return (
            <TableCell>
              <Typography>
                {formik.values[fieldArrayName][rowIndex].setting_multiplier
                  ? (
                      parseFloat(
                        formik.values[fieldArrayName][rowIndex]
                          .setting_multiplier
                      ) *
                      parseFloat(formik.values[fieldArrayName][rowIndex].name)
                    ).toFixed(2)
                  : "----"}
              </Typography>
            </TableCell>
          );
        } else {
          return (
            <TableCell>
              <FormTextInput
                name={`${fieldArrayName}.${rowIndex}.${cell.name}`}
                onChange={formik.handleChange}
                label={cell.label.replace("_", " ")}
                value={formik.values[fieldArrayName][rowIndex][cell.name]}
                error={
                  formik.touched[fieldArrayName] &&
                  formik.touched[fieldArrayName][rowIndex] &&
                  formik.touched[fieldArrayName][rowIndex][cell.name] &&
                  formik.errors[fieldArrayName] &&
                  formik.errors[fieldArrayName][rowIndex] &&
                  formik.errors[fieldArrayName][rowIndex][cell.name]
                }
                helperText={
                  formik.touched[fieldArrayName] &&
                  formik.touched[fieldArrayName][rowIndex] &&
                  formik.touched[fieldArrayName][rowIndex][cell.name] &&
                  formik.errors[fieldArrayName] &&
                  formik.errors[fieldArrayName][rowIndex] &&
                  formik.errors[fieldArrayName][rowIndex][cell.name]
                }
              />
            </TableCell>
          );
        }
      })}

      {calculateTotal && (
        <TableCell>
          {(
            formik.values[fieldArrayName][rowIndex].weight *
            formik.values[fieldArrayName][rowIndex].price
          ).toFixed(2)}
        </TableCell>
      )}

      {!disableButton && (
        <TableCell>
          <Tooltip title="刪除" arrow>
            <IconButton onClick={handleDeleteButtonClick}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

function DetailMainBody({
  arrayHelpers,
  detailSectionName,
  pushRowObj,
  buttonTitle,
  tableHeader,
  tableCells,
  fieldArrayName,
  maxRow,
  disableButton,
  calculateTotal,
}) {
  const useStyles = makeStyles((theme) => ({
    button: {
      margin: " 10px 0",
    },
    formTitle: {
      marginBottom: 10,
      marginTop: 10,
    },
  }));

  const formik = useFormikContext();
  const [isMaxRow, setIsMaxRow] = useState(false);
  const classes = useStyles();

  const addRow = () => {
    if (maxRow == 1) {
      arrayHelpers.push(pushRowObj);
      setIsMaxRow(() => !isMaxRow);
    } else if (maxRow - 1 == formik.values[fieldArrayName]?.length) {
      arrayHelpers.push(pushRowObj);
      setIsMaxRow(() => !isMaxRow);
    } else {
      arrayHelpers.push(pushRowObj);
    }
  };

  useEffect(() => {
    //if the row has already reached the max row at initial, disable the add button
    if (maxRow == formik.values[fieldArrayName]?.length) {
      setIsMaxRow(() => !isMaxRow);
    }
  }, []);

  const renderRows = () => {
    if (detailSectionName === "diamond1") {
      return formik.values[detailSectionName]?.map((sectionData, rowIndex) => {
        return (
          <Diamond1Section
            key={rowIndex}
            arrayHelpers={arrayHelpers}
            rowIndex={rowIndex}
            fieldArrayName={fieldArrayName}
            tableCells={tableCells}
            isMaxRow={isMaxRow}
            setIsMaxRow={setIsMaxRow}
            disableButton={disableButton}
            calculateTotal={calculateTotal}
          />
        );
      });
    } else if (detailSectionName === "stones1") {
      return formik.values[detailSectionName]?.map((sectionData, rowIndex) => {
        return (
          <Stones1Section
            key={rowIndex}
            arrayHelpers={arrayHelpers}
            rowIndex={rowIndex}
            fieldArrayName={fieldArrayName}
            tableCells={tableCells}
            isMaxRow={isMaxRow}
            setIsMaxRow={setIsMaxRow}
            disableButton={disableButton}
            calculateTotal={calculateTotal}
          />
        );
      });
    } else {
      return formik.values[detailSectionName]?.map((sectionData, rowIndex) => {
        return (
          //data will be fetched from formik, not sectionData
          <SectionTableCell
            arrayHelpers={arrayHelpers}
            rowIndex={rowIndex}
            fieldArrayName={fieldArrayName}
            tableCells={tableCells}
            isMaxRow={isMaxRow}
            setIsMaxRow={setIsMaxRow}
            disableButton={disableButton}
            calculateTotal={calculateTotal}
          />
        );
      });
    }
  };

  return (
    <>
      {!disableButton ? (
        <Button
          variant="contained"
          color="primary"
          onClick={addRow}
          className={classes.button}
          disabled={isMaxRow}
        >
          {buttonTitle}
        </Button>
      ) : (
        <Typography variant="h5" className={classes.formTitle}>
          {buttonTitle}
        </Typography>
      )}

      <FormTable
        headerNames={tableHeader}
        renderRows={() => renderRows()}
        disableButton={disableButton}
      />
    </>
  );
}

export default DetailMainBody;
