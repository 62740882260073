import React, { useContext, useState, useEffect } from "react";
import { PageContext } from "../../context/PageContext";
//import InvoiceSection from "./Component/InvoiceSection";
import EntityMangeLayout from "../../layout/EntityMangeLayout";
import { API_ENDPOINT_PREFIX, SERVER_URL, apiRequest } from "../../helper/fetching";
import useFetch from "../../hook/useFetch";

import InvoiceSection from "./Component/InvoiceSection";

import { format } from "date-fns";
import PageHeader from "./Component/PageHeader";

import { TextField } from "@material-ui/core";

import HeaderWithButton from "./Component/HeaderWithButton";
import CreateForm from "./Component/CreateForm";
import EditForm from "./Component/EditForm";

const InvoicePage = () => {
  const {
    entities: rows,
    isLoading,
    fetchEntity: fetchInvoice,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/invoice`,
    paged: true,
  });

  const [clients, setClients] = useState([]);

  const getClient = async () => {
    try {
      const res = await apiRequest().get(`${API_ENDPOINT_PREFIX}/client`);
      setClients(res.data.data)
    } catch (error) {}
  };

  useEffect(() => getClient(), [])

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "client_id",
      headerName: "Client Name",
      flex: 1,
      valueGetter: (params) => clients.find(client => client.id === params.value)?.name,
    },
    {
      field: "invoice_date",
      headerName: "Date",
      flex: 1,
      valueGetter: (params) => format(new Date(params.value), "MM-dd-yyyy"),
    },
  ];

  return (
    <PageContext.Provider
      value={{
        fetchInvoice,
        setReady,
        handleFilterChange,
        rows,
      }}
    >
      <EntityMangeLayout
        columns={columns}
        rows={rows}
        renderHeader={(props) => (
          // <PageHeader
          //   title="Invoice"
          //   subTitle="總覽"
          //   renderComponent={() => (
          //     <TextField
          //       label="搜尋訂單"
          //       variant="outlined"
          //       size="small"
          //       onChange={(e) => handleFilterChange({ po_no: e.target.value })}
          //     />
          //   )}
          // />
          <HeaderWithButton
            {...props}
            title_context="Invoice"
            button_context="Create Invoice"
          />
        )}
        renderCreateForm={(props) => <CreateForm {...props} />}
        renderInvoice={(props) => <EditForm {...props} />}
        pageSize={pageSize}
        onPageChange={setPage}
        colActions={["invoice"]}
        paginationMode="server"
        totalNumberOfRows={total}
        isLoading={isLoading}
      ></EntityMangeLayout>
    </PageContext.Provider>
  );
};

export default InvoicePage;
