import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
} from "../../../../../../helper/fetching";

import PopupWidget from "../../../../../../component/PopupWidget";

import { DataGrid } from "@mui/x-data-grid";

function ProductLabelPrintingSection() {
  const formik = useFormikContext();

  const workOrderId = formik.values.id;

  const [productLabelData, setProductLabelData] = useState("");
  const [rows, setRows] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([
    "款號",
    "件數",
    "成色",
    "金重",
    "牌子價",
    "PO",
    "石資料",
  ]);

  useEffect(() => {
    (async () => {
      try {
        const res = await apiRequest().get(
          `${API_ENDPOINT_PREFIX}/work-order/${workOrderId}/card`
        );
        const json = res.data.data;
        setProductLabelData(json);

        setRows(
          json.stones?.map((stone, idx) => {
            return {
              id: idx + 1,
              qty: stone.qty,
              name: stone.name,
              weight: stone.weight,
              unit: stone.unit,
            };
          })
        );
      } catch (err) {}
    })();
  }, [workOrderId]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeaders?.map((header) => (
              <TableCell>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              {productLabelData?.identifier}
              {productLabelData?.stone_initials?.reduce(
                (accumulator, current) => accumulator.concat(current),
                ""
              )}
            </TableCell>
            <TableCell>{productLabelData?.product_count}</TableCell>

            <TableCell>
              {productLabelData?.gold_type} {productLabelData?.gold_style}
            </TableCell>

            <TableCell>
              {productLabelData?.gold_weight?.reduce(
                (accumulator, current) => Number(accumulator) + Number(current),
                0
              )}
            </TableCell>

            <TableCell>{productLabelData?.net}</TableCell>

            <TableCell>{productLabelData?.po_no}</TableCell>

            <TableCell>
              <PopupWidget
                dialogTitle={`石資料 - ${rows.length} rows`}
                confirmButtonText=""
              >
                <DataGrid
                  density="compact"
                  autoHeight
                  hideFooter
                  columns={[
                    {
                      field: "qty",
                      headerName: "QTY",
                      flex: 1,
                    },
                    {
                      field: "name",
                      headerName: "Name",
                      flex: 1,
                    },
                    {
                      field: "weight",
                      headerName: "Weight",
                      flex: 1,
                    },
                    {
                      field: "unit",
                      headerName: "Unit",
                      flex: 1,
                    },
                  ]}
                  rows={rows}
                />
              </PopupWidget>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProductLabelPrintingSection;
