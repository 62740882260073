import { Button, CircularProgress } from "@material-ui/core";

const ButtonWithLoading = ({
    loading,
    circularProgressSize = 24,
    children,
    ...rest
}) => {
    if (loading){
        return (
            <Button
                disabled={loading}
                {...rest}
            >
                <CircularProgress
                    style={{
                        height: '100%'
                    }}
                    size={circularProgressSize}
                />
            </Button>
        );
    }

    return (
        <Button
            {...rest}
        >
            {children}
        </Button>
    );
}

export default ButtonWithLoading;