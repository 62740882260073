import {
  API_ENDPOINT_PREFIX,
  SERVER_URL,
  apiRequest,
} from "../../helper/fetching";
import useFetch from "../../hook/useFetch";
import { PageContext } from "../../context/PageContext";
import EntityMangeLayout from "../../layout/EntityMangeLayout";
import PageHeadline from "../../component/PageHeadline";
import PageSubHeadline from "../../component/PageSubHeadline";
import { useContext, useState, useEffect } from "react";
import { TextField, Button, Grid, Typography } from "@material-ui/core";
import { useFormik, FormikProvider } from "formik";
import FormTextInput from "../../component/Form/FormTextInput";
import FormSelectInput from "../../component/Form/FormSelectInput";
import AutoCompleteInput from "../../component/AutoCompleteInput";

import CropFreeIcon from "@material-ui/icons/CropFree";
import GetAppIcon from "@material-ui/icons/GetApp";

import EditForm from "./component/EditForm";
import * as Yup from "yup";
import moment from "moment";

import PopupWidget from "./component/PopupWidget";
import { genericSelectItemsMapper } from "../../helper/model";
import { progressSteps } from "./constant";

var fileDownload = require("js-file-download");

const PopupScanButton = () => {
  const { fetchProcess } = useContext(PageContext);

  const scanFormik = useFormik({
    initialValues: {
      po_no: "",
      id_no: "",
      processes_id: "",
      id: "",
    },

    onSubmit: async (data, { setSubmitting }) => {
      try {
        const res = await apiRequest().put(
          `${API_ENDPOINT_PREFIX}/work-order-processes/${data.id}`,
          {
            processes_id: data.processes_id,
          }
        );

        const json = res.data;
        fetchProcess();
        scanFormik.resetForm();
      } catch (err) {}
    },
  });

  let timer;
  const throttleFunction = (func, delay) => {
    if (timer) return;
    timer = setTimeout(() => {
      func();
      timer = null;
    }, delay);
  };

  const scanAction = (e) => {
    throttleFunction(async () => {
      const fieldData = e.target.value.split("_");
      scanFormik.setFieldValue("id", fieldData[0]);
      scanFormik.setFieldValue("po_no", fieldData[1]);
      scanFormik.setFieldValue("id_no", fieldData[2]);

      try {
        const res = await apiRequest().get(
          `${API_ENDPOINT_PREFIX}/work-order-processes/${fieldData[0]}`
        );

        scanFormik.setFieldValue("processes_id", res.data.processes_id);
      } catch (err) {}

      e.target.value = "";
    }, 500);
  };

  return (
    <FormikProvider value={scanFormik}>
      <PopupWidget
        dialogTitle="掃描貨物進度"
        handleSubmit={scanFormik.submitForm}
        startIcon={<CropFreeIcon />}
        fullWidth={false}
        confirmButtonText="更新"
        onClose={() => scanFormik.resetForm()}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <FormTextInput
              id="id"
              label="Id"
              name="id"
              InputLabelProps={{ shrink: true }}
              value={scanFormik.values.id}
              onChange={scanFormik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              id="po_no"
              label="Po"
              name="po_no"
              InputLabelProps={{ shrink: true }}
              value={scanFormik.values.po_no}
              onChange={scanFormik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              id="id_no"
              label="工單編號"
              name="id_no"
              InputLabelProps={{ shrink: true }}
              value={scanFormik.values.id_no}
              onChange={scanFormik.handleChange}
            />
          </Grid>

          {scanFormik.values.id_no &&
            scanFormik.values.po_no &&
            scanFormik.values.id && (
              <Grid item xs={12}>
                <FormSelectInput
                  id="processes_id"
                  name="processes_id"
                  label="現時貨物進度"
                  labelId="processes_id"
                  items={progressSteps}
                  value={scanFormik.values.processes_id}
                  onChange={scanFormik.handleChange}
                  key={scanFormik.values.processes_id}
                  required={true}
                />
              </Grid>
            )}

          <Grid item xs={12}>
            <FormTextInput
              placeholder={"點擊此處進行掃描"}
              onChange={scanAction}
              autoFocus={true}
              size="small"
            />
          </Grid>
        </Grid>
      </PopupWidget>
    </FormikProvider>
  );
};

const HeaderComponent = (props) => {
  const { handleFilterChange } = useContext(PageContext);
  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      factory_id: "",
    },
    validationSchema: Yup.object().shape({
      factory_id: Yup.string().required("必填"),
      start_date: Yup.date().required("必填"),
      end_date: Yup.date().required("必填"),
    }),
    onSubmit: async (values, action) => {
      const qs = "?" + new URLSearchParams(values).toString();
      try {
        const res = await apiRequest({
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
          responseType: "blob",
        }).get(`${API_ENDPOINT_PREFIX}/exportListExcel${qs}`);
        var blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        const date = moment().format("MM_DD_YYYY_hh_mm_ss");

        fileDownload(blob, `purchase_order_process_${date}.xlsx`);
        alert(`download success`);
      } catch (err) {
        alert(`download fail`);
        console.log(err);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <PageHeadline>貨物進度</PageHeadline>
            <PageSubHeadline>總覽</PageSubHeadline>
            <TextField
              label="PO"
              variant="outlined"
              onChange={(e) => handleFilterChange({ po_no: e.target.value })}
              size="small"
            />
          </Grid>
          <Grid item xs={7} justifyContent="flex-end">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormTextInput
                  id="start_date"
                  label="Start Date"
                  name="start_date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  value={formik.values.start_date}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextInput
                  id="end_date"
                  label="End Date"
                  name="end_date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  value={formik.values.end_date}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <AutoCompleteInput
                  id="factory_id"
                  getOptionLabel={(option) => (option ? option.name : "")}
                  optionUrl={`${API_ENDPOINT_PREFIX}/factory`}
                  label="廠方"
                  name="factory_id"
                  noOptionsText="沒有選項"
                  onChange={(e) =>
                    e?.id && formik.setFieldValue("factory_id", e.id)
                  }
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "end" }}>
                <PopupScanButton />
                &nbsp;&nbsp;&nbsp;
                <Button
                  startIcon={<GetAppIcon />}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  列印進度表
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
};

const PurchaseOrderProcessPage = () => {
  const {
    entities: rows,
    isLoading,
    fetchEntity: fetchProcess,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/work-order`,
    paged: true,
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      valueGetter: (params) => params.value,
    },
    {
      field: "PO",
      headerName: "PO",
      flex: 1,
      valueGetter: (param) => param.row.purchaseOrder?.po_no || "---",
    },
    {
      field: "client",
      headerName: "客名",
      flex: 1,
      valueGetter: (params) => (params.value ? params.value.fake_name : ""),
    },
    {
      field: "factory",
      headerName: "負責工廠",
      flex: 1,
      valueGetter: (params) => params.value.name,
    },
    {
      field: "processes_name",
      headerName: "工序",
      flex: 1,
      valueGetter: (params) => params?.value?.name || "",
    },
    {
      field: "processes",
      headerName: "預計完成日期",
      flex: 1,
      valueGetter: (params) => params?.value?.delivery_date || "",
    },
  ];

  return (
    <PageContext.Provider
      value={{
        fetchProcess,
        setReady,
        handleFilterChange,
        rows,
      }}
    >
      <EntityMangeLayout
        columns={columns}
        rows={rows}
        renderHeader={(props) => <HeaderComponent {...props} />}
        pageSize={pageSize}
        onPageChange={setPage}
        colActions={["edit"]}
        renderEditForm={(props) => <EditForm {...props} />}
        paginationMode="server"
        totalNumberOfRows={total}
        isLoading={isLoading}
      ></EntityMangeLayout>
    </PageContext.Provider>
  );
};

export default PurchaseOrderProcessPage;
