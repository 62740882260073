import {
  Switch,
  FormControlLabel,
  makeStyles,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
} from "@material-ui/core";
import clsx from "clsx";
import { useFormikContext } from "formik";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 50,
    color: "rgba(0,0,0,0.55)",
  },
}));

const FormSwitch = ({ label, name, required = false, legend, ...rest }) => {
  const classes = useStyles();
  const formik = useFormikContext();

  return (
    <FormControl error={formik.errors[name]} helperText={formik.errors[name]}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={formik.values[name]}
              name={name}
              required={required}
              disabled={false}
              {...rest}
            />
          }
          error={formik.errors[name]}
          helperText={formik.errors[name]}
          label={label}
          labelPlacement="start"
          className={clsx([classes.root])}
        />
      </FormGroup>
      <FormLabel component="legend">
        {formik.errors[name] ? "required" : ""}
      </FormLabel>
    </FormControl>
  );
};

export default FormSwitch;
