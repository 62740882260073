import { useState } from "react";

const useSnackBar = () => {
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const setSnackBarClose = () => {
        setSnackBarOpen(false);
    }

    const setMessage = (msg, severity = "success") => {
        setSnackBarMessage(msg);
        setSeverity(severity);
        setSnackBarOpen(true);
    }

    const setSuccessMessage = (msg) => {
        setSnackBarMessage(msg);
        setSeverity("success");
        setSnackBarOpen(true);
    }

    const setErrorMessage = (msg) => {
        setSnackBarMessage(msg);
        setSeverity("error");
        setSnackBarOpen(true);
    }

    return {
        open: snackBarOpen,
        message: snackBarMessage,
        severity,
        setOpen: setSnackBarOpen,
        setClose: setSnackBarClose,
        setMessage: setMessage,
        setSuccessMessage,
        setErrorMessage
    }
}

export default useSnackBar;