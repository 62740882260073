import { PageContext } from "../../../context/PageContext";
import { API_ENDPOINT_PREFIX, SERVER_URL } from "../../../helper/fetching";
import useFetch from "../../../hook/useFetch";
import EntityMangeLayout from "../../../layout/EntityMangeLayout";
import CreateForm from "./component/CreateForm";
import EditForm from "./component/EditForm";
import PageHeader from "./component/PageHeader";
import DeleteForm from "./component/DeleteForm";
import CloneForm from "./component/CloneForm"

const QuotationListingPage = () => {
  const columns = [
    {
      field: "identifier",
      headerName: "報價單編號",
      flex: 1,
    },
    {
      field: "client_nickname",
      headerName: "客名（簡稱）",
      flex: 1,
    },
    {
      field: "quotation_order_date",
      headerName: "提交日期",
      flex: 1,
    },
    {
      field: "quotation_order_date",
      headerName: "提交日期",
      flex: 1,
    },
    {
      field: "wrist_size",
      headerName: "手寸",
      flex: 1,
      valueGetter: (params) => params.value || "-",
    },
    {
      field: "factory",
      headerName: "廠方",
      flex: 1,
      renderCell: (params) => params.value?.name ? params.value?.name : "-"
    },
    {
      field: "images",
      headerName: "產品圖片",
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return (
            <img
              src={`${SERVER_URL}/${params.value.path}`}
              style={{
                maxHeight: "100%",
              }}
              alt=""
            />
          );
        }

        return "";
      },
    },
  ];

  //Using in viewing-report button
  const viewReport = {
    redirecRoute: "proformerreport",
    param: "id",
    paramData: "id",
  };

  const {
    entities: rows,
    isLoading,
    fetchEntity: fetchQuotations,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/quotation/template`,
    paged: true,
  });

  return (
    <PageContext.Provider
      value={{
        fetchQuotations,
        setReady,
        handleFilterChange,
      }}
    >
      <EntityMangeLayout
        columns={columns}
        rows={rows}
        colActions={["edit", "viewReport", "delete", "clone"]}
        renderHeader={(props) => <PageHeader {...props} />}
        renderCreateForm={(props) => <CreateForm {...props} />}
        renderEditForm={(props) => <EditForm {...props} />}
        renderDeleteForm={props => <DeleteForm {...props}/>}
        renderCloneForm={props => <CloneForm {...props}/>}
        onPageChange={setPage}
        totalNumberOfRows={total}
        paginationMode="server"
        isLoading={isLoading}
        pageSize={pageSize}
        viewReport={viewReport}
      />
    </PageContext.Provider>
  );
};

export default QuotationListingPage;
