import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
} from "../../../../../helper/fetching";

function ClientInfoSection() {
  const formik = useFormikContext();

  const handleImageChange = async (file) => {
    var formData = new FormData();
    formData.append("image", file);
    try {
      const res = await apiRequest().post(
        `${API_ENDPOINT_PREFIX}/upload/image`,
        formData
      );
      const imageData = res.data;

      formik.setFieldValue("image_file", imageData);
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} md={4} lg={4}>
          <Typography variant="h6">
            {`客人簡稱: ${formik.values.client.fake_name}`}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Typography variant="h6">
            {`打字印(1): ${
              formik.values.client.stamp1 ? formik.values.client.stamp1 : "沒有"
            }`}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Typography variant="h6">
            {`打字印(2): ${
              formik.values.client.stamp2 ? formik.values.client.stamp2 : "沒有"
            }`}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={6} md={4} lg={12}>
        <Typography variant="h5">打字印(圖)</Typography>

        <Grid item>
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="stamp_option"
              value={formik.values.stamp_option}
              onChange={formik.handleChange}
              defaultValue="打字印圖"
            >
              <FormControlLabel
                value="打字印圖"
                control={<Radio />}
                label="打字印圖"
              />
              <FormControlLabel
                value="無打字印"
                control={<Radio />}
                label="無打字印"
              />
              <FormControlLabel value="AS" control={<Radio />} label="AS" />
            </RadioGroup>
          </FormControl>
        </Grid>

        {(formik.values.stamp_option === "打字印圖" ||
          formik.values.stamp_option === undefined) && (
          <Grid>
            {formik.values.client ? (
              <img
                style={{ maxHeight: 350 }}
                src={`http://matthew-jewellery.demo.digiec.com/${formik.values.client?.stamp_image?.path}`}
              />
            ) : (
              "請選擇客人"
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default ClientInfoSection;
