import { useState, useRef } from "react";
import PoBasicSection from "../FormSection/PoBasicSection";
import PoWorkOrderSection from "../FormSection/PoWorkOrderSection";
import PoQuotationSection from "../FormSection/PoQuotationSection";
import { FormikProvider, useFormik } from "formik";
import { poQuotationColumns, poWorkOrderColumns } from "../constants";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../helper/fetching";
import { Button } from "@material-ui/core";

const CreateForm = ({ closeForm, ...rest }) => {
  const [PO_ID, setPO_ID] = useState(false);
  const [PO_NO, setPO_NO] = useState(false);
  const silderBlock =
    document.querySelector(".makeStyles-formRoot-28") ||
    document.querySelector(".jss28");
  if (silderBlock?.style.transform === "translateY(-1057px)")
    setTimeout(() => setPO_ID(false), 0);

  const formik = useFormik({
    initialValues: {
      po_no: "",
      client_id: "",
      quotations: [],
      work_orders: [],
      gold_price: "",
    },
    es: () => {},
    enableReinitialize: true,
    onSubmit: async (data, { setSubmitting }) => {
      const formattedData = {
        ...data,
        client_id: data.client.id,
      };

      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/purchase_order`,
          formattedData
        );

        const json = res.data;

        formik.resetForm();
        closeForm();
      } catch (err) {
      } finally {
        //   await fetchQuotations();

        setSubmitting(false);
      }
    },
  });

  const QuotationSearchKeys = {
    identifier: {
      label: "Identifier",
      value: "",
    },
  };

  const workOrderSearchKeys = {
    identifier: {
      label: "Identifier",
      value: "",
    },
    initials: {
      label: "Initials",
      value: "",
    },
  };

  return (
    <FormikProvider value={formik}>
      <div>
        {/* Po Basic Section */}
        <PoBasicSection
          setPO_ID={setPO_ID}
          PO_ID={PO_ID}
          setPO_NO={setPO_NO}
          PO_NO={PO_NO}
          closeForm={closeForm}
        />

        {/* Po Quotation Section */}
        {PO_ID && (
          <PoQuotationSection
            PO_ID={PO_ID}
            searchKeys={QuotationSearchKeys}
            columns={poQuotationColumns}
          />
        )}

        {/* Po Work-Order Section */}
        {PO_ID && (
          <PoWorkOrderSection
            PO_ID={PO_ID}
            PO_NO={PO_NO}
            searchKeys={workOrderSearchKeys}
            columns={poWorkOrderColumns}
          />
        )}

        <br />
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={closeForm}>Save</Button>
      </div>
    </FormikProvider>
  );
};

export default CreateForm;
