import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { useContext } from "react";
import { TokenContext } from "../../context/TokenContext";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core";
import FormTextInput from "../../component/Form/FormTextInput";
import { Link } from "react-router-dom";
import ButtonWithLoading from "../../component/ButtonWithLoading";

const LoginPage = () => {
  const { login } = useContext(TokenContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (data, { setSubmitting }) => {
      await login(data);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 64,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            登入
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormTextInput
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              fullWidth
            />

            <FormTextInput
              name="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              fullWidth
            />
            <ButtonWithLoading
              type="submit"
              fullWidth
              variant="contained"
              style={{ marginTop: 24, marginBottom: 16 }}
              loading={formik.isSubmitting}
            >
              登入
            </ButtonWithLoading>
            <Grid container>
              <Grid item xs>
                <Link to="/forget-password" variant="body2">
                  忘記密碼
                </Link>
              </Grid>
              <Grid item>
                <Link to="/signup" variant="body2">
                  註冊帳號
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </FormikProvider>
  );
};

export default LoginPage;
