import { useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../../component/DataTable";
import SlideFormWrapper from "../../component/SlideForm";
import useFormAnimation from "../../hook/useFormAnimation";

const EntityMangeLayout = ({
  columns,
  rows,
  colActions = [],
  isLoading,
  renderHeader = () => {},
  renderCreateForm = () => {},
  renderEditForm = () => {},
  renderCloneForm = () => {},
  renderViewForm = () => {},
  renderDeleteForm = () => {},
  renderInvoice = () => {},
  paginationMode = "client",
  onPageChange,
  totalNumberOfRows,
  pageSize,
  viewReport,
  ...rest
}) => {
  const [entitySelected, setEntitySelected] = useState("");

  const {
    formIn: createFormIn,
    openForm: openCreateForm,
    closeForm: closeCreateForm,
  } = useFormAnimation();

  const {
    formIn: editFormIn,
    openForm: openEditForm,
    closeForm: closeEditForm,
  } = useFormAnimation();

  const {
    formIn: cloneFormIn,
    openForm: openCloneForm,
    closeForm: closeCloneForm,
  } = useFormAnimation();
  
  const {
    formIn: viewFormIn,
    openForm: openViewForm,
    closeForm: closeViewForm,
  } = useFormAnimation();

  const {
    formIn: deleteFormIn,
    openForm: openDeleteForm,
    closeForm: closeDeleteForm,
  } = useFormAnimation();

  const {
    formIn: invoiceFormIn,
    openForm: openInvoice,
    closeForm: closeInvoice,
  } = useFormAnimation();

  const onEditButtonClick = (row) => (e) => {
    setEntitySelected(row);
    openEditForm();
  };

  const onCloneButtonClick = (row) => (e) => {
    setEntitySelected(row);
    openCloneForm();
  };

  const onEditFormClose = () => {
    setEntitySelected("");
    closeEditForm();
  };

  const onCloneFormClose = () => {
    setEntitySelected("");
    closeCloneForm();
  };

  const onViewButtonClick = (row) => (e) => {
    setEntitySelected(row);
    openViewForm();
  };

  const onViewFormClose = () => {
    setEntitySelected("");
    closeEditForm();
  };

  const onDeleteButtonClick = (row) => (e) => {
    setEntitySelected(row);
    openDeleteForm();
  };

  const onDeleteFormClose = () => {
    setEntitySelected("");
    closeDeleteForm();
  };

  const onInvoiceButtonClick = (row) => (e) => {
    setEntitySelected(row);
    openInvoice();
  };

  const onInvoiceClose = () => {
    setEntitySelected("");
    closeInvoice();
  };

  //Redirect to view  Proformer Report by quotation id, row: current clicked quotation data
  let history = useHistory();
  const onViewReportButtonClick = (row) => (e) => {
    history.push(
      `/${viewReport.redirecRoute}?${viewReport.param}=${
        row[viewReport.paramData]
      }`
    );
  };

  return (
    <div>
      {renderHeader({
        openForm: openCreateForm,
      })}

      <DataTable
        columns={columns}
        rows={rows}
        colActions={colActions}
        onEditButtonClick={onEditButtonClick}
        onCloneButtonClick={onCloneButtonClick}
        onViewButtonClick={onViewButtonClick}
        onDeleteButtonClick={onDeleteButtonClick}
        onInvoiceButtonClick={onInvoiceButtonClick}
        onViewReportButtonClick={onViewReportButtonClick}
        loading={isLoading}
        paginationMode={paginationMode}
        onPageChange={onPageChange}
        pageSize={pageSize}
        rowCount={totalNumberOfRows}
        {...rest}
      />

      <SlideFormWrapper formIn={createFormIn} closeForm={closeCreateForm}>
        {renderCreateForm({
          closeForm: closeCreateForm,
        })}
      </SlideFormWrapper>

      {entitySelected && (
        <SlideFormWrapper formIn={editFormIn} closeForm={onEditFormClose}>
          {renderEditForm({
            entity: entitySelected,
            closeForm: onEditFormClose,
          })}
        </SlideFormWrapper>
      )}

      {entitySelected && (
        <SlideFormWrapper formIn={cloneFormIn} closeForm={onCloneFormClose}>
          {renderCloneForm({
            entity: entitySelected,
            closeForm: onCloneFormClose,
          })}
        </SlideFormWrapper>
      )}

      {entitySelected && (
        <SlideFormWrapper formIn={viewFormIn} closeForm={onViewFormClose}>
          {renderViewForm({
            entity: entitySelected,
            closeForm: onViewFormClose,
          })}
        </SlideFormWrapper>
      )}

      {entitySelected && (
        <SlideFormWrapper formIn={invoiceFormIn} closeForm={onInvoiceClose}>
          {renderInvoice({
            entity: entitySelected,
            closeForm: onInvoiceClose,
          })}
        </SlideFormWrapper>
      )}

      {entitySelected && (
        <SlideFormWrapper formIn={deleteFormIn} closeForm={onDeleteFormClose}>
          {renderDeleteForm({
            entity: entitySelected,
            closeForm: onDeleteFormClose,
          })}
        </SlideFormWrapper>
      )}
    </div>
  );
};

export default EntityMangeLayout;
