import { PageContext } from "../../context/PageContext";
import EntityMangeLayout from "../../layout/EntityMangeLayout";
import { API_ENDPOINT_PREFIX, apiRequest } from "../../helper/fetching";
import useFetch from "../../hook/useFetch";
import { useContext, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  IconButton,
  Tooltip,
  requirePropFactory,
  Typography,
} from "@material-ui/core";
import { useFormik, FormikProvider } from "formik";
import FormTextInput from "../../component/Form/FormTextInput";
import PageHeadline from "../../component/PageHeadline";
import PageSubHeadline from "../../component/PageSubHeadline";
import * as Yup from "yup";
import ButtonWithLoading from "../../component/ButtonWithLoading";

const useStyles = makeStyles({
  paper: {
    textAlign: "end",
  },
  to: {
    textAlign: "center",
    lineHeight: "51px",
  },
});

const EditForm = ({ entity, closeForm }) => {
  const { fetchFactoryList } = useContext(PageContext);
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      name: entity.name || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("必填"),
    }),
    onSubmit: async (data, { setSubmitting, resetForm }) => {
      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/factory/${entity.id}?_method=put`,
          {
            ...data,
          }
        );
        const json = res.data;
        resetForm();
        closeForm();
      } catch (err) {
        console.log(err);
      } finally {
        await fetchFactoryList();
        setSubmitting(false);
      }
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  return (
    <FormikProvider value={formik}>
      <Typography variant="h3" className={classes.formTitle}>
        編輯廠方
      </Typography>

      <Grid container justifyContent="flex-start">
        <Grid item xs={12} md={6} lg={6}>
          <FormTextInput
            variant="outlined"
            id="name"
            label="厰名"
            name={`name`}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end">
        <Grid item>
          <ButtonWithLoading
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
            loading={formik.isSubmitting}
          >
            提交
          </ButtonWithLoading>
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

const CreateForm = ({ entity, closeForm }) => {
  const { fetchFactoryList } = useContext(PageContext);
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("必填"),
    }),
    onSubmit: async (data, { setSubmitting, resetForm }) => {
      try {
        const res = await apiRequest().post(`${API_ENDPOINT_PREFIX}/factory`, {
          ...data,
        });
        const json = res.data;
        resetForm();
        closeForm();
      } catch (err) {
        console.log(err);
      } finally {
        await fetchFactoryList();
        setSubmitting(false);
      }
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  return (
    <FormikProvider value={formik}>
      <Typography variant="h3" className={classes.formTitle}>
        新建廠方
      </Typography>

      <Grid container justifyContent="flex-start">
        <Grid item xs={12} md={6} lg={6}>
          <FormTextInput
            variant="outlined"
            id="name"
            label="厰名"
            name={`name`}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end">
        <Grid item>
          <ButtonWithLoading
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
            loading={formik.isSubmitting}
          >
            提交
          </ButtonWithLoading>
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

const HeaderComponent = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={8}>
        <Grid item xs={5}>
          <PageHeadline>廠方</PageHeadline>
          <PageSubHeadline>總覽</PageSubHeadline>
        </Grid>
      </Grid>

      <Grid item xs={4} justifyContent="flex-end" className={classes.paper}>
        <Button variant="contained" color="primary" onClick={props.openForm}>
          新增廠方
        </Button>
      </Grid>
    </Grid>
  );
};

const FactoriesListingPage = () => {
  const factoriesListingTableColunm = [
    {
      field: "name",
      headerName: "廠名",
      flex: 1,
    },
  ];

  const {
    entities: rows,
    isLoading,
    fetchEntity: fetchFactoryList,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch({
    api: `${API_ENDPOINT_PREFIX}/factory`,
    paged: true,
  });

  return (
    <PageContext.Provider
      value={{
        fetchFactoryList,
        setReady,
        handleFilterChange,
      }}
    >
      <EntityMangeLayout
        columns={factoriesListingTableColunm}
        rows={rows}
        colActions={["edit"]}
        renderHeader={(props) => <HeaderComponent {...props} />}
        renderCreateForm={(props) => <CreateForm {...props} />}
        renderEditForm={(props) => <EditForm {...props} />}
        onPageChange={setPage}
        totalNumberOfRows={total}
        paginationMode="server"
        isLoading={isLoading}
        pageSize={pageSize}
      />
    </PageContext.Provider>
  );
};

export default FactoriesListingPage;
