export const invoiceColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 20,
  },
  {
    field: "item_no",
    headerName: "ID no",
    flex:1
  },
  {
    field: "po_no",
    headerName: "PO/PI no",
    flex:1
  },
  {
    field: "style_no",
    headerName: "STYLE no",
    flex:1
  },
  {
    field: "aa_no",
    headerName: "AA no",
    flex:1
  },
  {
    field: "identifier",
    headerName: "STYLE",
    flex:1
  },
  {
    field: "gold_style",
    headerName: "KT",
    flex:1
  },
  {
    field: "description",
    headerName: "Description",
    flex:1,
    editable: true,
  },
  {
    field: "gold_weight",
    headerName: "G.W.(GM)",
    flex:1,
    valueGetter: params => `${params.row.gold_weight} | ${params.row.total_gold_weight}`
    // renderCell: params => (
    //   <div style={{width: "100%",display: 'flex', justifyContent: 'space-between'}}>
    //     <div>{params.row.gold_weight}</div>
    //     <div>|</div>
    //     <div>{params.row.total_gold_weight}</div>
    //   </div>
    // )
  },
  {
    field: "diamonds",
    headerName: "DIAMOND WEIGHT",
    flex:2,
    valueGetter: (params) => {
      return params.value.reduce((previousValue, currentValue) => {
        if (currentValue.weight) {
          return (
            currentValue.qty +
            " " +
            currentValue.stone_initial +
            " " +
            currentValue.weight +
            " " +
            currentValue.unit +
            "/ " +
            previousValue
          );
        } else {
          return "";
        }
      }, "");
    },
  },
  {
    field: "product_count",
    headerName: "PC",
    flex:1,
  },
  {
    field: "unit_price",
    headerName: "UNIT PRICE",
    flex:1,
  },
  {
    field: "total_price",
    headerName: "TOTAL(USD)",
    flex:1,
  },
];
