import {
  Button,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
// import AddIcon from "@material-ui/icons/Add";
import { useFormikContext } from "formik";
import { useEffect, useState, useContext } from "react";
import AutoCompleteInput from "../../../component/AutoCompleteInput";
import FormSection from "../../../component/Form/FormSection";
import FormTextInput from "../../../component/Form/FormTextInput";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../helper/fetching";
// import DataTable from "../../../component/DataTable";
import EditIcon from "@material-ui/icons/Edit";
import LoadingIcon from "../../../component/LoadingIcon";
import { PageContext } from "../../../context/PageContext";

const useStyles = makeStyles((theme) => ({
  clientSimpleName: {
    padding: "10px 0 0 0",
  },
}));

const MainContent = ({ setPO_ID }) => {
  const formik = useFormikContext();
  const classes = useStyles();
  const [responseError, setResponseError] = useState("");
  const [loading, setLoading] = useState(false);
  const { fetchPurchaseOrder } = useContext(PageContext);
  const validatePO = async () => {
    setLoading(true);

    try {
      const res = await apiRequest().put(
        `${API_ENDPOINT_PREFIX}/purchase-order/${formik.values.id}`,
        {
          po_no: formik.values.po_no,
          client_id: formik.values.client.id,
          gold_price: formik.values.gold_price,
        }
      );
      const PO_ID = res.data.id;
      if (PO_ID) {
        setPO_ID(PO_ID);
      } else {
        // setResponseError();
      }
      setResponseError("");
      fetchPurchaseOrder()
    } catch (err) {
      const errorMsg = Object.values(err.data.errors).join(", ");
      setResponseError(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          name={`po_no`}
          label="PO"
          value={formik.values.po_no}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>

      <Grid item xs={6} md={2} lg={2} className={classes.clientSimpleName}>
        <AutoCompleteInput
          id="client"
          getOptionLabel={(option) => (option ? option.fake_name : "")}
          optionUrl={`${API_ENDPOINT_PREFIX}/client`}
          label="客名(簡稱)"
          name="client.fake_name"
          value={formik.values.client?.fake_name}
          defaultValue={formik.values.client}
          onChange={(client) => formik.setFieldValue("client", client)}
          noOptionsText="沒有選項"
          error={formik.errors?.client?.id}
          helperText={formik.errors?.client?.id}
        />
      </Grid>

      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          name={`gold_price`}
          label="金價"
          value={formik.values.gold_price}
          onChange={formik.handleChange}
        />
      </Grid>

      <Tooltip title="檢查PO是否重複及建立新的PO號碼" arrow>
        <Button
          variant="contained"
          color="primary"
          onClick={validatePO}
          startIcon={loading ? <LoadingIcon /> : <EditIcon />}
          disabled={loading ? true : false}
        >
          更新PO
        </Button>
      </Tooltip>

      <Grid item xs={6} md={2} lg={2}>
        <Typography color="error">{responseError}</Typography>
      </Grid>
    </Grid>
  );
};

function PoBasicSection(props) {
  return (
    <FormSection
      title="PO基本資料"
      renderContent={() => <MainContent props={props} />}
      collaspe
    />
  );
}

export default PoBasicSection;
