import { useState, useEffect, useContext, Fragment } from "react";
import { apiRequest, API_ENDPOINT_PREFIX } from "../../../helper/fetching";
import {
  TextField,
  Button,
  Grid,
  Typography,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import PageSubHeadline from "../../../component/PageSubHeadline";
import { PageContext } from "../../../context/PageContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    width: "400px",
  },

  div: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.1rem",
  },

  label: {
    width: "150px",
  },
}));

const TableComponent = ({ title = "", rows = [], columns = [] }) => {
  const ColumnSection = () =>
    columns.map((column) => <TableCell key={column.headerName}>{column.headerName}</TableCell>);

  const RowsSection = () =>
    rows.map((row) => (
      <TableRow key={row.id}>
        {columns.map((column, index) => (
          <TableCell key={index}>
            {row[column.field] === undefined ? column.value : row[column.field]}
          </TableCell>
        ))}
      </TableRow>
    ));

  return (
    <Fragment>
      {rows.length ? (
        <TableContainer component={Paper}>
          {title && (
            <PageSubHeadline style={{ margin: "1rem 0 0 1rem" }}>
              {title}
            </PageSubHeadline>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <ColumnSection />
              </TableRow>
            </TableHead>
            <TableBody>
              <RowsSection />
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Fragment>
  );
};

const TextBox = (props) => (
  <TextField
    variant="outlined"
    size="small"
    disabled={true}
    fullWidth
    InputLabelProps={{
      shrink: true,
    }}
    {...props}
  />
);

const ViewForm = ({ entity, closeForm }) => {
  const { fetchReceipt } = useContext(PageContext);

  const classes = useStyles();

  const [receiptDetail, setReceiptDetail] = useState(entity);

  const [factoryName, setFactoryName] = useState("");

  //Differentiate by whether it have the work_order_id field or not.
  const [receiptWithID, setReceiptWithID] = useState(
    entity.factory_receipt_inputs.filter((receipt) =>
      receipt.identifier !== null
    )
  );

  const [receiptNoID, setReceiptNoID] = useState(
    entity.factory_receipt_inputs.filter(
      (receipt) => receipt.identifier === null
    )
  );

  useEffect(() => {
    setFactoryName(
      entity?.factory.name === "聯豐"
        ? "LF"
        : entity.factory.name === "震興"
        ? "ZH"
        : ""
    );

    (async () => {
      try {
        const res = await apiRequest().get(
          `${API_ENDPOINT_PREFIX}/factory-receipt/${entity.id}`
        );
        const json = res.data.data || res.data;
        setReceiptDetail(json);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <br />
        <PageSubHeadline>廠發票</PageSubHeadline>
      </Grid>

      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextBox label="發票號" value={receiptDetail?.receipt_no} />
          </Grid>
          <Grid item xs={3}>
            <TextBox
              label="日期"
              type="date"
              value={receiptDetail?.receipt_date}
            />
          </Grid>
          <Grid item xs={3}>
            <TextBox label="工廠" value={receiptDetail?.factory?.name} />
          </Grid>
          <Grid item xs={3}>
            <TextBox
              label="成色"
              value={
                receiptDetail?.gold_type_id == 1
                  ? "14"
                  : receiptDetail.gold_type_id == 2
                  ? "18"
                  : ""
              }
            />
          </Grid>

          {receiptDetail.month_day_client_gold ? (
            <Grid item xs={3}>
              <TextBox
                label={factoryName === "LF" ? "客來足金" : "月日客來金"}
                value={receiptDetail.month_day_client_gold}
              />
            </Grid>
          ) : null}

          {receiptDetail.month_day_client_chain_weight ? (
            <Grid item xs={3}>
              <TextBox
                label={factoryName === "LF" ? "月日客來鏈" : "月日客來配件"}
                value={receiptDetail.month_day_client_chain_weight}
              />
            </Grid>
          ) : null}

          {receiptDetail.la_xiang ? (
            <Grid item xs={3}>
              <TextBox label="腊鑲" value={receiptDetail.la_xiang} />
            </Grid>
          ) : null}

          {receiptDetail.hu_zu_jin ? (
            <Grid item xs={3}>
              <TextBox label="回足金" value={receiptDetail.hu_zu_jin} />
            </Grid>
          ) : null}

          {receiptDetail.accessories_weight ? (
            <Grid item xs={3}>
              <TextBox
                label="配件價"
                value={receiptDetail.accessories_weight}
              />
            </Grid>
          ) : null}

          {receiptDetail.overall_price ? (
            <Grid item xs={3}>
              <TextBox label="報关價" value={receiptDetail.overall_price} />
            </Grid>
          ) : null}

          {receiptDetail.total_mold_weight ? (
            <Grid item xs={3}>
              <TextBox label="倒模重" value={receiptDetail.total_mold_weight} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={12}>
        <TableComponent
          title="製工單"
          columns={[
            {
              field: "identifier",
              headerName: "ID",
            },
            {
              field: "incoming_gold_weight",
              headerName: "客入",
              value: receiptDetail.client_incoming_gold,
            },
            {
              field: "factory",
              headerName: "廠方",
              value: receiptDetail.factory.name,
            },
            {
              field: "total_weight",
              headerName: "成色",
              value:
                receiptDetail?.gold_type_id == 1
                  ? "14"
                  : receiptDetail.gold_type_id == 2
                  ? "18"
                  : "",
            },
          ]}
          rows={receiptWithID}
        />
      </Grid>

      <Grid item xs={12}>
        <TableComponent
          title="自定義製工單"
          columns={[
            {
              field: "gold_weight",
              headerName: "金石重",
            },
            {
              field: "incoming_gold_weight",
              headerName: "來金重",
            },
            {
              field: "diamond_weight",
              headerName: "石重（ct）",
            },
            {
              field: "lin_stone_weight",
              headerName: "净重（g）",
            },
          ]}
          rows={receiptNoID}
        />
      </Grid>
    </Grid>
  );
};

export default ViewForm;
