import { PageContext } from "../../../context/PageContext";
import EntityMangeLayout from "../../../layout/EntityMangeLayout";
import { API_ENDPOINT_PREFIX } from "../../../helper/fetching";
import PageHeader from "./Component/PageHeader/PageHeader";
import useFetch from "../../../hook/useFetch";
import EditForm from "./Component/FormikForm/EditForm";
import CreateForm from "./Component/FormikForm/CreateForm";

const ClientListPage = ()=>{

    const clientListingTableColunm =[
        {
            field: "name",
            headerName: "客人名稱",
            flex: 1,
        },
        {
            field: "fake_name",
            headerName: "簡稱",
            flex: 1,
        },
        {
            field: "tel1",
            headerName: "電話(1)",
            flex: 1,
        },
        {
            field: "tel2",
            headerName: "電話(2)",
            flex: 1,
        },
        {
            field: "address1",
            headerName: "地址(1)",
            flex: 1,
        },
        {
            field: "address2",
            headerName: "地址(2)",
            flex: 1,
        },
    ]

    const {
        entities: rows,
        isLoading,
        fetchEntity: fetchClientList,
        setReady,
        handleFilterChange,
        setPage,
        total,
        pageSize,
      } = useFetch({
        api: `${API_ENDPOINT_PREFIX}/client/`,
        paged: true,
      });


    return(
        <PageContext.Provider      
        value={{
            fetchClientList,
            setReady,
            handleFilterChange,
          }}>
            <EntityMangeLayout 
                columns={clientListingTableColunm}
                rows={rows}
                colActions={["edit"]}
                renderHeader={(props) => <PageHeader {...props} />}
                renderCreateForm={(props) => <CreateForm {...props} />}
                renderEditForm={(props) => <EditForm {...props} />}
                onPageChange={setPage}
                totalNumberOfRows={total}
                paginationMode="server"
                isLoading={isLoading}
                pageSize={pageSize}
            />

        </PageContext.Provider>
    )

}
     

export default ClientListPage;