import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import FormTextInput from "../../../../component/Form/FormTextInput";
import * as Yup from "yup";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
  convertToFormData,
} from "../../../../helper/fetching";
import { PageContext } from "../../../../context/PageContext";
import BasicInfoSection from "./FormSection/BasicInfoSection";
import GoldSection from "./FormSection/GoldSection";
import StoneSection from "./FormSection/StoneSection";
import RemarkSection from "./FormSection/RemarkSection";
import FormSection from "../../../../component/Form/FormSection";
import { editFormSchema } from "../Schema/EditFormSchema";
import FactorySection from "./FormSection/FactorySection";
import OrderDetailSection from "./FormSection/OrderDetailSection";
import AdditionalCostSection from "./FormSection/AdditionalCostSection";
import MarkUpsection from "./FormSection/MarkUpSection";
import DiamondSection from "./FormSection/DiamondSection";
import ClientInfoSection from "./FormSection/ClientInfoSection";
import SearchSection from "./FormSection/SearchSection";
import DiamondNotCountSection from "./FormSection/DiamondNotCountSection";
import StoneNotCountSection from "./FormSection/StoneNotCountSection";
import GemSection from "./FormSection/GemSection";
import ButtonWithLoading from "../../../../component/ButtonWithLoading";

var fileDownload = require("js-file-download");

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  informationWrapper: {
    marginBottom: 20,
  },
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
}));

const EditForm = ({
  entity: workOrder,
  closeForm,
  renderPOComponent,
  PO_ID,
  ...rest
}) => {
  //*** Merge each section field for the formik initial values */
  let productFieldValue = {
    stamp_option: workOrder.stamp_option,
    id_no: workOrder.id_no,
    is_confirmed: workOrder.is_confirmed,
    customer_item_no: workOrder.customer_item_no,
    id: workOrder.id,
    identifier: workOrder.identifier,
    full_name: workOrder.full_name,
    category: workOrder.category,
    wrist_size: workOrder.wrist_size,
    chain_length: workOrder.chain_length,
    zhen_fen_se: workOrder.zhen_fen_se,
    dian_fen_se: workOrder.dian_fen_se,
    lu_zhu_bian: workOrder.lu_zhu_bian,
    er_zhen: workOrder.er_zhen,
    xian_pai: workOrder.xian_pai,
    work_order_er_po: workOrder.work_order_er_po,
    shi_mian_dian_bai: workOrder.shi_mian_dian_bai,
    shi_di_dian_bai: workOrder.shi_di_dian_bai,
    dian_huang: workOrder.dian_huang,
    dian_hung: workOrder.dian_hung,
    dian_hei: workOrder.dian_hei,
    po_5_5: workOrder.po_5_5,
    po_4_5: workOrder.po_4_5,
    pi_po: workOrder.pi_po,
    b_b_po: workOrder.b_b_po,
    zhi_mo: workOrder.zhi_mo,
    ding_ban: workOrder.ding_ban,
    zhao_xiang: workOrder.zhao_xiang,
    qi_ding: workOrder.qi_ding,
    ao_zhou_xiang: workOrder.ao_zhou_xiang,
    po_xiang: workOrder.po_xiang,
    other: workOrder.other,
    la_zhong: workOrder.la_zhong,
    zhi_mo_zhong: workOrder.zhi_mo_zhong,
    zhi_bian_zhong: workOrder.zhi_bian_zhong,
    tu_zhi: workOrder.tu_zhi,
    mo: workOrder.mo,
    la: workOrder.la,
    qi_ya: workOrder.qi_ya,
    hong_kong_remark: workOrder.hong_kong_remark,
    molding_remark: workOrder.molding_remark,
    zhi_mo_remark: workOrder.zhi_mo_remark,
    xiang_shi_remark: workOrder.xiang_shi_remark,
    gold_type: workOrder.gold_type_id,
    gold_weight: workOrder.gold_weight,
    stones: workOrder.stones,
    diamonds: workOrder.diamonds,
    images: workOrder.images,
    client_nickname: workOrder.client_nickname,
    deduct_diamonds: workOrder.deduct_diamonds,
    deduct_gems: workOrder.deduct_gems,
    deduct_stones: workOrder.deduct_stones,
    //
    description: workOrder.description,
    aa_no: workOrder.aa_no,
    style_no: workOrder.style_no,
    diamond_practical_qty: workOrder.diamond_practical_qty,
    diamond_practical_weight: workOrder.diamond_practical_weight,
    gem_practical_qty: workOrder.gem_practical_qty,
    gem_practical_weight: workOrder.gem_practical_weight,
    chain_weight: workOrder.chain_weight,
    hook_weight: workOrder.hook_weight,
    incoming_gold_weight: workOrder.incoming_gold_weight,
    accessories_weight: workOrder.accessories_weight,
    xia_bi: workOrder.xia_bi,
    er_po: workOrder.er_po,
  };

  let factoryFieldValue = {
    factory_lin_stone_weight_price: workOrder.factory_lin_stone_weight_price,
    factory_shipping_date: workOrder.factory_shipping_date,
    factory_stone_weight: workOrder.factory_stone_weight,
    factory_lin_stone_weight: workOrder.factory_lin_stone_weight,
    factory_gold_weight: workOrder.factory_gold_weight,
    factory_lin_stone_weight_price: workOrder.factory_lin_stone_weight_price,
    factory_lin_stone_weight_order: workOrder.factory_lin_stone_weight_order,
    factory_ji_gold_price: workOrder.factory_ji_gold_price,
    factory_ji_gold_order: workOrder.factory_ji_gold_order,
    factory_salary_price: workOrder.factory_salary_price,
    factory_salary_order: workOrder.factory_salary_order,
    factory_gwa_or_den_or_mai_price: workOrder.factory_gwa_or_den_or_mai_price,
    factory_gwa_or_den_or_mai_order: workOrder.factory_gwa_or_den_or_mai_order,
    factory_ga_lin_price: workOrder.factory_ga_lin_price,
    factory_ga_lin_order: workOrder.factory_ga_lin_order,
    factory_ban_fei_price: workOrder.factory_ban_fei_price,
    factory_ban_fei_order: workOrder.factory_ban_fei_order,
    factory_stone_price: workOrder.factory_stone_price,
    factory_total_count: workOrder.factory_total_count,
    factory_old_wg: workOrder.factory_old_wg,
    factory_t_or_p: workOrder.factory_t_or_p,
    factory_net: workOrder.factory_net,
    factory_old_no: workOrder.factory_old_no,
    factory_po_no: workOrder.factory_po_no,
  };

  let workOrderBasicFieldValue = {
    order_date: workOrder.order_date,
    shipping_date: workOrder.shipping_date,
    factory: workOrder.factory,
    client: workOrder.client,
    product_count: workOrder.product_count,
    quotation_gold_weight: workOrder.quotation_gold_weight,
    quotation_net: workOrder.quotation_net,
  };

  let additionalCostFieldValue = {
    additional_costs: workOrder.additional_costs,
  };

  let markUpSection = {
    mark_up: workOrder.mark_up,
  };

  let goldSection = {
    gold_style: workOrder.gold_style,
    gold_type_id: workOrder.gold_type_id,
    gold_weight: workOrder.gold_weight,
    gold_limit_weight: workOrder.gold_limit_weight,
  };

  let clientSection = {
    client: workOrder.client,
  };

  let gems = workOrder.gems.length
    ? workOrder.gems.map((item) => {
        return {
          ...item,
          stone_code: item.stone_code || "_",
        };
      })
    : [];

  let mergedFieldValue = {
    ...productFieldValue,
    // ...factoryFieldValue,
    factory_field: workOrder.factory_fields,
    gems,
    ...workOrderBasicFieldValue,
    ...additionalCostFieldValue,
    ...markUpSection,
    ...goldSection,
    ...clientSection,
  };

  const classes = useStyles();

  const { fetchProducts, fetchPurchaseOrder } = useContext(PageContext);
  const [preSetValues, setPreSetValues] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  //**** convert "null" to " "(empty string)  ****//
  for (const property in mergedFieldValue) {
    if (mergedFieldValue[property] === null) {
      mergedFieldValue[property] = "";
    }
  }

  const formik = useFormik({
    initialValues: selectedOption ? selectedOption : mergedFieldValue,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(editFormSchema),

    onSubmit: async (data, { setSubmitting }) => {
      //* Format data for put api
      const { client, factory, images, ...restWorkOrder } = data;
      const formattedData = {
        ...restWorkOrder,
        // client: client,
        client_id: client.id,
        quotation_id: workOrder.quotation?.id || null,
        factory_id: factory.id,
        image_id: images?.id || null,
        // deduct_diamonds: [],
        // deduct_stones: [],
        // deduct_gems: [],
      };

      //* End of format data for put api

      try {
        let res = "";
        if (PO_ID) {
          //Calling API by purchase order

          // res = await apiRequest().put(
          //   `${API_ENDPOINT_PREFIX}/purchase-orders/${formattedData.id}/work-order`,
          //   formattedData
          // );
          res = await apiRequest().put(
            `${API_ENDPOINT_PREFIX}/work-order/${formattedData.id}`,
            formattedData
          );
        } else {
          //Calling API by work order
          res = await apiRequest().put(
            `${API_ENDPOINT_PREFIX}/work-order/${workOrder.id}`,
            formattedData
          );
        }

        closeForm();
        window.scrollTo(0, 0);
      } catch (err) {
      } finally {
        await fetchProducts();

        setSubmitting(false);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const [IsConfirm, setIsConfirm] = useState(formik.values.is_confirmed);
  const handleConfirmed = async () => {
    try {
      setIsConfirm(true);
      const res = await apiRequest().post(
        `${API_ENDPOINT_PREFIX}/work-order/${formik.values.id}/confirm`
      );
    } catch (err) {
      setIsConfirm(false);
    } finally {
      await fetchProducts();
    }
  };
  const [loading, setLoading] = useState(false);
  const handleDownload = async () => {
    try {
      setLoading(true);
      const res = await apiRequest({
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
        responseType: "blob",
      }).get(`${API_ENDPOINT_PREFIX}/work-order/${formik.values.id}/exportExcel`);

      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      fileDownload(blob, `Work_Order_${formik.values.id}.xlsx`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <div {...rest}>
        <div>
          <Typography variant="h3" className={classes.formTitle}>
            編輯產品
            <span style={{ color: "red", fontSize: "1.5rem" }}>
              {workOrder.is_canceled
                ? "（This word-order is canceled can not edit）"
                : ""}
            </span>
          </Typography>

          {/* <Grid item xs={6} md={2} lg={2}>
            <FormTextInput
              variant="outlined"
              id="factory_po_no"
              label="PO Number"
              name="factory_po_no"
              value={formik.values.factory_po_no}
              onChange={formik.handleChange}
              required={false}
            />
          </Grid> */}

          {renderPOComponent?.LinkUpQuotation()}

          <SearchSection
            preSetValues={preSetValues}
            setPreSetValues={setPreSetValues}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />

          <div>
            <ButtonWithLoading
              variant="contained"
              color="primary"
              loading={loading}
              onClick={handleDownload}
            >
              下載工單
            </ButtonWithLoading>
            &nbsp;&nbsp;
            <Button
              onClick={handleConfirmed}
              variant="contained"
              color="primary"
            >
              確認工單
            </Button>{" "}
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {"Current Confirm Status: " +
                (IsConfirm ? "Is Confirmed" : "Not Confirmed")}
            </span>
          </div>

          <FormSection
            title="工單基本信息"
            renderContent={() => <OrderDetailSection mode="edit" />}
            collaspe
          />

          <FormSection
            title="客人資料"
            renderContent={() => <ClientInfoSection />}
            collaspe
          />

          <FormSection
            title="產品基本信息"
            renderContent={() => <BasicInfoSection />}
            collaspe
          />

          <FormSection
            title="金資料"
            renderContent={() => <GoldSection />}
            collaspe
          />

          <FormSection
            title="鑽石資料(扣石)"
            renderContent={() => <DiamondSection />}
            collaspe
          />

          <FormSection
            title="鑽石資料(不扣石)"
            renderContent={() => <DiamondNotCountSection />}
            collaspe
          />

          <FormSection
            title="寶石資料"
            renderContent={() => <GemSection />}
            collaspe
          />

          <FormSection
            title="其他石資料(扣石)"
            renderContent={() => <StoneSection />}
            collaspe
          />

          <FormSection
            title="其他石資料(不扣石)"
            renderContent={() => <StoneNotCountSection />}
            collaspe
          />

          <FormSection
            title="其他費用"
            renderContent={() => <AdditionalCostSection />}
            collaspe
          />

          <FormSection
            title="備註部分"
            renderContent={() => <RemarkSection />}
            collaspe
          />

          <FormSection
            title="廠方部分"
            renderContent={() => <FactorySection />}
            collaspe
          />

          <Grid container>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                確定
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </FormikProvider>
  );
};

export default EditForm;
