import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  errorText: {
    margin: "4px 14px 0 14px ",
  },
}));

const ErrorMessage = (props) => {
  const { errorMessage } = props;
  const classes = useStyles();
  return (
    <>
      {errorMessage && (
        <Typography
          variant="caption"
          color="error"
          className={classes.errorText}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default ErrorMessage;
