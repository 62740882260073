import { useState, useEffect } from "react";
import FormSection from "../../../component/Form/FormSection";
import { useFormikContext } from "formik";
import { FieldArray } from "formik";
import PoSearchSection from "./PoSearchSection";
import FormTable from "../../../component/Form/FormTable";
import { PoWorkOrderSectionTableHeader } from "../constants";
import { IconButton, TableCell, TableRow, Button } from "@material-ui/core";
import useFormAnimation from "../../../hook/useFormAnimation";
import LinkUpQuotationSection from "./LinkUpQuotationSection";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import SlideFormWrapper from "../../../component/SlideForm";
import EditForm from "../../ProductPage/ProductListingPage/component/EditForm";

import ComponentLayout from "../../../layout/EntityMangeLayout/ComponentLayout";

import WordOrderCreateForm from "../../ProductPage/ProductListingPage/component/CreateForm";

const PoWorkOrderTableRow = ({
  key,
  index,
  arrayHelpers,
  values,
  PO_ID,
  PO_NO,
  disabled,
  ...rest
}) => {
  const formik = useFormikContext();

  const handleDeleteButtonClick = () => arrayHelpers.remove(index);

  const {
    formIn: editFormIn,
    openForm: openEditForm,
    closeForm: closeEditForm,
  } = useFormAnimation();

  const renderPOComponent = {
    LinkUpQuotation: () => (
      <LinkUpQuotationSection
        arrayHelpers={arrayHelpers}
        index={index}
        setFieldValue={formik.setFieldValue}
      />
    ),
  };

  return (
    <>
      <TableRow>
        <TableCell>{values.identifier}</TableCell>
        <TableCell>{values.client?.name || ""}</TableCell>
        <TableCell>{values.factory.name}</TableCell>

        <TableCell>
          <IconButton onClick={openEditForm} disabled>
            <EditIcon />
          </IconButton>

          {/* <IconButton onClick={handleDeleteButtonClick}>
            <CloseIcon />
          </IconButton> */}
        </TableCell>
      </TableRow>

      <SlideFormWrapper formIn={editFormIn} closeForm={closeEditForm}>
        <EditForm
          entity={values}
          renderPOComponent={renderPOComponent}
          PO_ID={PO_ID}
        />
      </SlideFormWrapper>
    </>
  );
};

const PoWorkOrderTable = ({
  arrayHelpers,
  PO_ID,
  searchKeys,
  columns,
  PO_NO,
  ...rest
}) => {
  const formik = useFormikContext();

  //confirmed option that add to Formik
  const [preSetValues, setPreSetValues] = useState("");

  // Selected option in search area
  const [selectedOption, setSelectedOption] = useState("");

  const renderRows = () => {
    const rowRecord = formik.values.work_orders || formik.values.workOrders 

    return rowRecord?.map((work_order, index) => (
      <PoWorkOrderTableRow
        key={index}
        index={index}
        arrayHelpers={arrayHelpers}
        values={work_order}
        PO_ID={PO_ID}
        disabled={rest.disabled}
      />
    ));
  };

  const handleAddPoWorkOrder = () => arrayHelpers.push(preSetValues);

  useEffect(() => {
    if (preSetValues) {
      handleAddPoWorkOrder(preSetValues);
    }
  }, [preSetValues]);

  return (
    <>
      {!rest.disabled && (
        <ComponentLayout
          renderHeader={(props) => (
            <Button
              variant="contained"
              color="primary"
              style={{
                position: "absolute",
                width: 104,
                margin: "12px 0",
                transform: "translate(113px , -12px)",
              }}
              onClick={props.openForm}
            >
              新增報價單
            </Button>
          )}
          renderCreateForm={(props) => (
            <WordOrderCreateForm
              {...props}
              callInfo={{
                by: "Purchase-Order",
                id: PO_ID,
                quotation_ids: formik.values.quotations,
                setValueForTable: setPreSetValues,
              }}
            />
          )}
        />
      )}

      {!rest.disabled && (
        <PoSearchSection
          dialogTitle="搜索製工單"
          createTitle="新增製工單"
          searchModule="work-order"
          preSetValues={preSetValues}
          setPreSetValues={setPreSetValues}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          searchFieldLabel="工單ID"
          searchKeys={searchKeys}
          columns={columns}
          PO_ID={PO_ID}
          PO_NO={PO_NO}
        />
      )}
      <FormTable
        headerNames={PoWorkOrderSectionTableHeader}
        renderRows={renderRows}
      />
    </>
  );
};

const MainContent = (props) => {
  return (
    <FieldArray
      name="work_orders"
      validateOnChange={false}
      render={(arrayHelpers) => (
        <PoWorkOrderTable arrayHelpers={arrayHelpers} {...props} />
      )}
    />
  );
};

const PoWorkOrderSection = (props) => {
  return (
    <FormSection
      title="製工單"
      renderContent={() => <MainContent {...props} />}
      collaspe
    />
  );
};

export default PoWorkOrderSection;
