import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import FormTextInput from "../../../../../component/Form/FormTextInput";

const RemarkSection = () => {

    const formik = useFormikContext();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <FormTextInput
                    variant="outlined"
                    id="hong_kong_remark" 
                    label="香港資料"
                    multiline
                    rows={4}
                    name="hong_kong_remark"
                    value={formik.values.hong_kong_remark} 
                    onChange={formik.handleChange}
                />
            </Grid>

            <Grid item xs={12}>
                <FormTextInput
                    variant="outlined"
                    id="molding_remark" 
                    label="倒模資料"
                    multiline
                    rows={4}
                    name="molding_remark"
                    value={formik.values.molding_remark} 
                    onChange={formik.handleChange}
                />
            </Grid>

            <Grid item xs={12}>
                <FormTextInput
                    variant="outlined"
                    id="zhi_mo_remark" 
                    label="執模資料"
                    multiline
                    rows={4}
                    name="zhi_mo_remark"
                    value={formik.values.zhi_mo_remark} 
                    onChange={formik.handleChange}
                />
            </Grid>

            <Grid item xs={12}>
                <FormTextInput
                    variant="outlined"
                    id="xiang_shi_remark" 
                    label="鑲石資料"
                    multiline
                    rows={4}
                    name="xiang_shi_remark"
                    value={formik.values.xiang_shi_remark} 
                    onChange={formik.handleChange}
                />
            </Grid>
        </Grid>
    );
}

export default RemarkSection;