import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { dataOrderFormat } from '../constant/fieldName';

function ReportListingSection(props) {
    const {
        renderHeader = () => { },
        tableHeaders,
        tableRows
    } = props


    // const formattedObject = Object.assign(dataOrderFormat,apiData) 



    return (
        <>
            {renderHeader()}
            <TableContainer component={Paper}>
                <Table aria-label="simple table">

                    <TableHead>
                        {/* //mapping main header */}
                        <TableRow>
                            {tableHeaders.map((tableHeader) => (<TableCell align="center">{tableHeader.header}</TableCell>))}
                        </TableRow>

                        {/* //mapping sub-headers according to the main header */}
                        <TableRow>
                            {tableHeaders.map((tableHeader) => {
                                return (
                                    <TableCell align="center" padding="none">
                                        {tableHeader.subHeaders.map((subHeader) => { return <TableCell >{subHeader}</TableCell > })}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableRows.map((row, rowIndex) => {

                            // changing the data order to the Frontend defined data pattern
                            const formattedData = Object.assign(dataOrderFormat, row)

                            return (
                                <TableRow key={rowIndex} >
                                    {Object.keys(formattedData).map(
                                        (key, rowIndex) => {
                                            return (
                                                <TableCell >
                                                    {Array.isArray(formattedData[key]) ?
                                                        // if is an array put all array data into Table Cell, else put a the string data to the Table Cell. (function())() <- run the function immediately
                                                        (() => formattedData[key].map((arrayData) => { return <TableCell >{arrayData}</TableCell > }))(): formattedData[key]
                                                    }
                                                </TableCell>
                                            )
                                        }
                                    )}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ReportListingSection;