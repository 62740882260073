import { Paper, Typography, makeStyles } from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import { getURLFromPublic } from "../helper/fetching";

const useStyles = makeStyles((theme) => ({
  zone: {
    width: "100%",
    minHeight: 200,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius,
    borderColor: "rgba(0,0,0,0.23)",
    color: theme.palette.grey["500"],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:focus": {
      outline: "none",
    },
    marginTop: 8,
    marginBottom: 4,
  },
  drop: {
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  dragActive: {
    outline: "rgb(26, 115, 232) solid 2px",
  },
  preview: {
    justifyContent: "start",
  },
  previewItem: {
    padding: theme.spacing(1),
  },
  previewImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 50,
    minHeight: 50,
  },
  previewImage: {
    maxWidth: 50,
    maxHeight: 50,
  },
  previewDrop: {
    flexGrow: 1,
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  table: {
    minWidth: 650,
  },
  error: {
    borderColor: "red",
  },
  disabled: {
    pointerEvents: "none",
  },
  helperText: {
    color: "red",
  },
}));

const UploadImageArea = ({
  value,
  onChange,
  defaultImage,
  error,
  disabled = false,
  helperText = "請上傳一張圖片",
}) => {
  const classes = useStyles();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".jpg,.jpeg,.png,.gif",
    onDrop: (acceptedFiles) => {
      if (
        !acceptedFiles ||
        acceptedFiles.length === 0 ||
        acceptedFiles.length > 1
      ) {
        return;
      }
      onChange(acceptedFiles[0]);
    },
    onDropRejected: () => {},
    multiple: false,
  });

  return (
    <Paper
      elevation={0}
      className={clsx([classes.zone, classes.drop], {
        [classes.dragActive]: isDragActive,
        [classes.error]: error,
        [classes.disabled]: disabled,
      })}
      {...getRootProps()}
    >
      {/* {value ? (
        <img style={{ maxHeight: 350 }} src={URL.createObjectURL(value)} />
      ) : defaultImage ? (
        <img
          style={{ maxHeight: 350 }}
          src={getURLFromPublic(defaultImage.path)}
        />
      ) : (
        <CloudUploadOutlinedIcon color="inherit" fontSize="large" />
      )} */}

      <img
        style={{ maxHeight: 350 }}
        src={"http://matthew-jewellery.demo.digiec.com/" + value?.path}
      />

      <Typography color="primary">
        {error
          ? error
          : value
          ? value.name
          : defaultImage
          ? defaultImage.name
          : isDragActive
          ? "拖放到此處"
          : "將文件拖放到此處或單擊以上傳"}
      </Typography>

      <Typography variant="subtitle2">
        {value
          ? ""
          : defaultImage
          ? ""
          : "(僅支持 JPG、PNG。最大文件大小為 2MB。)"}
      </Typography>
      <input {...getInputProps()} />
      <Typography color="error">{value ? "" : helperText}</Typography>
    </Paper>
  );
};

export default UploadImageArea;
