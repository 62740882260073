import { Collapse, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Fragment, useState } from "react";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    contentRoot: {
        overflow: 'hidden'
    },
    formTitle: {
        marginBottom: 10,
        marginTop: 10
    },
}));

const FormSection = ({
    title,
    renderContent,
    collaspe = false,
    defaultExpand = true
}) => {

    const classes = useStyles();

    const [expand, setExpand] = useState(defaultExpand);

    const toggleSection = (expand) => e => {
        setExpand(!expand);
    }
    
    return (
        <div className={classes.root}>
            <Typography variant="h5" className={classes.formTitle}>
                {title}
                {
                    collaspe ? 
                    <IconButton
                        aria-label="expane"
                        size="small"
                        onClick={toggleSection(expand)}
                    >
                        {
                            expand ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>
                        }
                    </IconButton> 
                    : ""
                }
            </Typography>
            <div className={classes.contentRoot}>
            {
                collaspe ? 
                <Collapse in={expand} timeout={500}>
                    {
                        renderContent()
                    }
                </Collapse>
                :
                renderContent()
            }
            </div>       
        </div>    
    );
}

export default FormSection;