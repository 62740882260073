import { Grid } from "@material-ui/core";
import { useFormik, useFormikContext } from "formik";
import { useState } from "react";
import PopupWidget from "../../../../../component/PopupWidget";
import { API_ENDPOINT_PREFIX } from "../../../../../helper/fetching";
import TableWithCheckBox from "../TableWithCheckBox";
import ProductLabelPrintingSection from "./ProductLabelPrintingSection/ProductLabelPrintingSection";

function SearchSection({
  workOrder,
  setWorkOrder,
  arrayHelpers,
  selectedOption,
  setSelectedOption,
  preSetValues,
  setPreSetValues,
  ...rest
}) {
  const formik = useFormikContext();

  const handleAddWorkOrder = () => {
    if (selectedOption) {
      formik.setValues(value => ({...value, ...selectedOption}))
      // setPreSetValues(selectedOption)
    }
  };

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item>
        <PopupWidget dialogTitle="搜索製工單" handleSubmit={handleAddWorkOrder}>
          <TableWithCheckBox
            optionUrl={`${API_ENDPOINT_PREFIX}/work-order`}
            workOrder={workOrder}
            setWorkOrder={setWorkOrder}
            setSelectedOption={setSelectedOption}
          />
        </PopupWidget>
      </Grid>

      {/* <Grid item>
        <PopupWidget dialogTitle="預覽牌仔" confirmButtonText="列印">
          <ProductLabelPrintingSection />
        </PopupWidget>
      </Grid> */}
    </Grid>
  );
}

export default SearchSection;
