import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FieldArray, useFormikContext } from "formik";
import FormSection from "../../../component/Form/FormSection";
import FormTable from "../../../component/Form/FormTable";
import { DataGrid } from "@mui/x-data-grid";
import { PoWorkOrderSectionTableHeader } from "../Constants";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import PopupWidget from "../../../component/PopupWidget";
import TableWithCheckBox from "../../../component/TableWithCheckBox";
import { API_ENDPOINT_PREFIX, apiRequest } from "../../../helper/fetching";
import { useEffect, useState, Fragment } from "react";
// import PoSearchSection from "./PoSearchSection";
import PoSearchSection from "../../OrderPage/FormSection/PoSearchSection";
import SlideFormWrapper from "../../../component/SlideForm";
import useFormAnimation from "../../../hook/useFormAnimation";
import EditForm from "../../ProductPage/ProductListingPage/component/EditForm";
import { makeStyles } from "@material-ui/core/styles";
import LinkUpQuptationSection from "./LinkUpQuptationSection";

import ComponentLayout from "../../../layout/EntityMangeLayout/ComponentLayout";

import WordOrderCreateForm from "../../ProductPage/ProductListingPage/component/CreateForm";

import LoadingIcon from "../../../component/LoadingIcon";
import CheckIcon from "@material-ui/icons/Check";
import GetAppIcon from "@material-ui/icons/GetApp";

import FileCopyIcon from "@material-ui/icons/FileCopy";

var fileDownload = require("js-file-download");

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

/************************* DeleteConfirmActionPage *****************************************/
const DeleteConfirmActionPage = ({
  open,
  handleClose,
  handleConfirm,
  entity,
}) => {
  const [msg, setMsg] = useState("");

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>確認刪除此製工單</DialogTitle>
      <DialogContent>
        <Typography variant="h6">確認刪除：{entity.identifier} ?</Typography>
        {msg && (
          <Typography variant="body1" color="error">
            {msg}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          取消
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/************************* CloneActionPage *****************************************/

const CloneActionPage = ({ open, handleClose, onSelectRow, PO_ID, entity }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [quotationId, setQuotationId] = useState("");

  useEffect(() => {
    const fetchRows = async () => {
      try {
        const response = await apiRequest().get(
          `${API_ENDPOINT_PREFIX}/purchase-order${
            searchTerm ? `?po_no=${searchTerm}&` : "?"
          }page=${page + 1}&pageSize=${pageSize}`
        );
        setRows(response.data.data);
        setRowCount(response.data.meta.total);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRows();
  }, [searchTerm, page, pageSize]);

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };

  const handleConfirm = async () => {
    const quotation = selectedRow.quotations.find(
      (quotation) => quotation.id == quotationId
    );

    if (selectedRow) {
      const { id } = selectedRow;
      try {
        await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/purchase-order/${id}/work-order`,
          {
            ...entity,
            id_no: "",
            quotation_id: quotation.id,
            factory_id: entity.factory.id,
            factory_field: entity.factory_fields,
            quotation: {
              id: quotation.id,
              identifier: quotation.identifier,
              images: quotation.images,
              unit_price: quotation.images,
              is_confirmed: quotation.is_confirmed,
            },
          }
        );
        setSnackbarMessage(`成功複製到${selectedRow.po_no}`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        handleClose();
      } catch (error) {
        setSnackbarMessage(`複製失敗${": " + error.data.message}`);
        setSnackbarSeverity("error");
        handleClose();
        setSnackbarOpen(true);
        console.error(error);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          setSelectedRow(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>選擇訂單</DialogTitle>
        <DialogContent>
          <TextField
            label="搜索PO"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(0);
            }}
            width={50}
            size="small"
            style={{ marginBottom: 10 }}
            variant="outlined"
          />
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={[
                { field: "id", headerName: "ID", width: 150 },
                { field: "po_no", headerName: "PO", width: 200 },
                {
                  field: "quotations_are_confirmed",
                  headerName: "Confirmed",
                  width: 150,
                  valueGetter: (params) => (params.value ? "已確認" : "未確認"),
                },
                {
                  field: "workOrders",
                  headerName: "是否已有製工單",
                  width: 150,
                  valueGetter: (params) =>
                    params.value.length > 0 ? "有" : "未有",
                },
                {
                  field: "quotations",
                  headerName: "Quotations Identifier",
                  width: 300,
                  valueGetter: (params) =>
                    params.value.reduce(
                      (acc, item) =>
                        acc ? `${acc}, ${item.identifier}` : item.identifier,
                      ""
                    ),
                },
              ]}
              pagination
              pageSize={pageSize}
              rowCount={rowCount}
              paginationMode="server"
              onPageChange={(params) => setPage(params)}
              onPageSizeChange={(params) => setPageSize(params.pageSize)}
              onRowClick={handleRowClick}
            />
          </div>
          {selectedRow && (
            <FormControl
              className={classes.formControl}
              style={{ marginTop: 20, width: 200 }}
            >
              <InputLabel id="select-quotation-id">選擇對應報價單：</InputLabel>
              <Select
                labelId="select-quotation-id"
                value={quotationId}
                onChange={(e) => setQuotationId(e.target.value)}
                label="請選擇對應的報價單"
                variant="outlined"
              >
                {selectedRow.quotations.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.identifier} id: {item.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              setSelectedRow(null);
            }}
            color="secondary"
          >
            取消
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            disabled={!selectedRow || !quotationId}
          >
            確認
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

/************************* PoWorkOrderTableRow *****************************************/
const PoWorkOrderTableRow = ({
  key,
  index,
  arrayHelpers,
  values,
  PO_ID,
  ...rest
}) => {
  const formik = useFormikContext();
  const [message, setMessage] = useState();
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteButtonClick = async () => {
    let data = { ...formik.values };
    let getWordOrderID =
      data.workOrders.length > 0 ? data.workOrders[index].id : null;

    if (!getWordOrderID) return;

    try {
      const res = await apiRequest().delete(
        `${API_ENDPOINT_PREFIX}/purchase-order/${formik.values.id}/work-order/${getWordOrderID}`
      );
      arrayHelpers.remove(index);
      await formik.fetchCurrentPO();
    } catch (err) {
      err.status === 400 && setMessage(err.data.message);
    }
  };

  const {
    formIn: editFormIn,
    openForm: openEditForm,
    closeForm: closeEditForm,
  } = useFormAnimation();

  const renderPOComponent = {
    LinkUpQuotation: () => (
      <LinkUpQuptationSection
        arrayHelpers={arrayHelpers}
        index={index}
        setFieldValue={formik.setFieldValue}
        formik={formik}
      />
    ),
  };

  const ConfirmButton = ({ id }) => {
    const [loading, setLoading] = useState(false);

    const handleAction = async () => {
      setLoading(true);
      try {
        const res = await apiRequest().post(
          `${API_ENDPOINT_PREFIX}/work-order/${id}/confirm`
        );
        await formik.fetchCurrentPO();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        {loading ? (
          <IconButton>
            <LoadingIcon />
          </IconButton>
        ) : (
          <Tooltip title="Confirm Word Order">
            <IconButton onClick={handleAction}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  const ExportButton = ({ id }) => {
    const [loading, setLoading] = useState(false);

    const handleAction = async () => {
      try {
        setLoading(true);
        const res = await apiRequest({
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
          responseType: "blob",
        }).get(`${API_ENDPOINT_PREFIX}/work-order/${id}/exportExcel`);

        var blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        fileDownload(blob, `Work_Order_${id}.xlsx`);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        {loading ? (
          <IconButton>
            <LoadingIcon />
          </IconButton>
        ) : (
          <Tooltip title="Export Word Order">
            <IconButton onClick={handleAction}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <>
      <TableRow>
        <TableCell>{values.identifier}</TableCell>
        <TableCell>{values.client?.name || ""}</TableCell>
        <TableCell>{values.factory.name}</TableCell>
        <TableCell>{values.is_confirmed ? "已確認" : "未確認"}</TableCell>
        <TableCell>{values.is_completed ? "已完成" : "未完成"}</TableCell>

        <TableCell>
          <IconButton onClick={openEditForm}>
            <EditIcon />
          </IconButton>

          <ConfirmButton id={values.id} />

          <ExportButton id={values.id} />

          <IconButton onClick={() => setDeleteDialogOpen(true)}>
            <CloseIcon color={message ? "error" : ""} />
          </IconButton>
          {message ? (
            <Typography variant="h6" color="error">
              {message}
            </Typography>
          ) : null}
          <IconButton onClick={() => setCloneDialogOpen(true)}>
            <FileCopyIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <SlideFormWrapper formIn={editFormIn} closeForm={closeEditForm}>
        <EditForm
          entity={values}
          renderPOComponent={renderPOComponent}
          PO_ID={PO_ID}
          closeForm={() => {
            closeEditForm();
            rest.fetchCurrentPO();
          }}
        />
      </SlideFormWrapper>
      <CloneActionPage
        open={cloneDialogOpen}
        handleClose={() => setCloneDialogOpen(false)}
        onSelectRow={(row) => {
          setCloneDialogOpen(false);
        }}
        entity={values}
        PO_ID={PO_ID}
      />
      <DeleteConfirmActionPage
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={handleDeleteButtonClick}
        entity={values}
      />
    </>
  );
};

/************************* PoWorkOrderTable *****************************************/
const PoWorkOrderTable = ({
  arrayHelpers,
  PO_ID,
  searchKeys,
  columns,
  fetchCurrentPO,
}) => {
  const formik = useFormikContext();

  //confirmed option that add to Formik
  const [preSetValues, setPreSetValues] = useState("");

  // Selected option in search area
  const [selectedOption, setSelectedOption] = useState("");

  const RenderRows = () => {
    //work_orders and workOrders!!!!
    if (formik.values.work_orders?.length) {
      formik.values.workOrders = formik.values.work_orders;
    }

    return formik.values.workOrders?.map((workOrder, index) => {
      return (
        <PoWorkOrderTableRow
          key={index}
          index={index}
          arrayHelpers={arrayHelpers}
          values={workOrder}
          PO_ID={PO_ID}
          fetchCurrentPO={fetchCurrentPO}
        />
      );
    });
  };

  const handleAddPoWorkOrder = () => {
    arrayHelpers.push(preSetValues);
  };

  useEffect(() => {
    if (preSetValues) {
      handleAddPoWorkOrder(preSetValues);
    }
  }, [preSetValues]);

  return (
    <>
      <ComponentLayout
        renderHeader={(props) => (
          <Button
            variant="contained"
            color="primary"
            style={{
              position: "absolute",
              width: 104,
              margin: "12px 0",
              transform: "translate(113px , -12px)",
            }}
            onClick={props.openForm}
          >
            新增製工單
          </Button>
        )}
        renderCreateForm={(props) => (
          <WordOrderCreateForm
            {...props}
            callInfo={{
              by: "Purchase-Order",
              id: formik.values.id,
              quotation_ids: formik.values.quotations,
              setValueForTable: setPreSetValues,
            }}
          />
        )}
      />

      <PoSearchSection
        dialogTitle="搜索製工單"
        searchModule="work-order"
        preSetValues={preSetValues}
        setPreSetValues={setPreSetValues}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        searchFieldLabel="工單ID"
        searchKeys={searchKeys}
        columns={columns}
        PO_ID={formik.values.id}
        PO_NO={formik.values.po_no}
      />

      <FormTable
        headerNames={PoWorkOrderSectionTableHeader}
        renderRows={RenderRows}
      />
    </>
  );
};

/**************************** MainContent **************************************/
const MainContent = ({ PO_ID, searchKeys, columns, fetchCurrentPO }) => {
  return (
    <FieldArray
      name="work_orders"
      validateOnChange={false}
      render={(arrayHelpers) => (
        <PoWorkOrderTable
          arrayHelpers={arrayHelpers}
          PO_ID={PO_ID}
          searchKeys={searchKeys}
          columns={columns}
          fetchCurrentPO={fetchCurrentPO}
        />
      )}
    />
  );
};

/**************************** PoWorkOrderSection **************************************/
function PoWorkOrderSection({ PO_ID, searchKeys, columns, fetchCurrentPO }) {
  return (
    <FormSection
      title="製工單"
      renderContent={() => (
        <MainContent
          PO_ID={PO_ID}
          searchKeys={searchKeys}
          columns={columns}
          fetchCurrentPO={fetchCurrentPO}
        />
      )}
      collaspe
    />
  );
}

export default PoWorkOrderSection;
