import AutorenewIcon from "@material-ui/icons/Autorenew";

const LoadingIcon = () => {
  return (
    <>
      <style>
        {`@keyframes rotate {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }`}
      </style>
      <AutorenewIcon style={{ animation: `rotate 2s linear infinite` }} />
    </>
  );
};

export default LoadingIcon;
