import { FieldArray, useFormikContext } from "formik";
import {
  Button,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
} from "@material-ui/core";
import React from "react";
import FormTable from "../../../../../component/Form/FormTable";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import CloseIcon from "@material-ui/icons/Close";

const AdditionaCostItem = {
  title: "",
  cost: 0,
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: " 10px 0",
  },
}));


const AdditionCostTableRow = (props) => {
  const { value,  additionalCostItemIndex, arrayHelpers } =
    props;
  const formik = useFormikContext();

  const handleDeleteButtonClick = () => {
    arrayHelpers.remove(additionalCostItemIndex);
  };

  return (
    <TableRow>
      <TableCell>
        <FormTextInput
          name={`additional_costs.${additionalCostItemIndex}.title`}
          onChange={formik.handleChange}
          label="項目名稱"
          value={
            formik.values.additional_costs[additionalCostItemIndex].title
          }

        />
      </TableCell>
      <TableCell>
        <FormTextInput
          name={`additional_costs.${additionalCostItemIndex}.cost`}
          onChange={formik.handleChange}
          label="費用"
          value={
            formik.values.additional_costs[
              additionalCostItemIndex
            ].cost
          }
        />
      </TableCell>

      <TableCell>
        <IconButton onClick={handleDeleteButtonClick}>
          <CloseIcon />
        </IconButton>
      </TableCell>

    </TableRow>
  );
};

const AdditionCostTable = (props) => {

  const { arrayHelpers, existProductIndex } = props;
  const tableHeader = ["其他費用項目", "費用", "刪除"];
  const formik = useFormikContext();
  const classes = useStyles();

  const addAdditionaCostItem = () => {
    arrayHelpers.push(AdditionaCostItem);
  };
  
  //*** Render single table row ****/
  const renderRows = () => {
    return formik.values.additional_costs?.map(
      (additionalCostItem, additionalCostItemIndex) => {
        return (
          <AdditionCostTableRow
            value={additionalCostItem}
            key={additionalCostItemIndex}
            // existProductIndex={existProductIndex}
            additionalCostItemIndex={additionalCostItemIndex}
            arrayHelpers={arrayHelpers}
          />
        );
      }
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={addAdditionaCostItem}
        className={classes.button}
      >
        新增其他費用項目
      </Button>
      
      <FormTable
        headerNames={tableHeader}
        renderRows={() => renderRows()}
      />
    </>
  );
};



//****Return Component****
const AdditionalCostSection = () => {
  
  // const fieldArrayName = "additional_cost";
  return (
    <FieldArray
      name="additional_costs"
      validateOnChange={false}
      render={(arrayHelpers) => (
        <AdditionCostTable
          arrayHelpers={arrayHelpers}
          // existProductIndex={existProductIndex}
        />
      )}
    />
  );
};

export default AdditionalCostSection;
