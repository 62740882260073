import React from "react";
import { useFormikContext } from "formik";
import {  makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import FormTextInput from "../../../../../component/Form/FormTextInput";


const useStyles = makeStyles(() => ({
    section: {
      margin: "15px 0 0 0 ",
    },
  }));


function MarkUpsection() {
    const formik = useFormikContext();
    const classes = useStyles();

    return (
        <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormTextInput
            name={`mark_up`}
            label="Mark Up"
            value={formik.values.mark_up}
            onChange={formik.handleChange}
            className={classes.section}
          />
        </Grid>
      </Grid>
      );
}

export default MarkUpsection;