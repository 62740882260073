import SlideFormWrapper from "../../component/SlideForm";
import useFormAnimation from "../../hook/useFormAnimation";

const HeaderWithCreateForm = ({
  renderHeader = () => {},
  renderCreateForm = () => {},
}) => {
  const {
    formIn: createFormIn,
    openForm: openCreateForm,
    closeForm: closeCreateForm,
  } = useFormAnimation();
  return (
    <>
      {renderHeader({
        openForm: openCreateForm,
      })}
      <SlideFormWrapper formIn={createFormIn} closeForm={closeCreateForm}>
        {renderCreateForm({
          closeForm: closeCreateForm,
        })}
      </SlideFormWrapper>
    </>
  );
};

export default HeaderWithCreateForm;
