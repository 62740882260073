import { Grid, Typography } from "@material-ui/core";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import { useFormikContext } from "formik";
import UploadImageArea from "../../../../../component/UploadImageArea";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
} from "../../../../../helper/fetching";

const AddClientInfoSection = () => {
  const formik = useFormikContext();

  const handleImageChange = async (file) => {
    var formData = new FormData();

    formData.append("image", file);

    try {
      const res = await apiRequest().post(
        `${API_ENDPOINT_PREFIX}/upload/image`,
        formData
      );

      const imageData = res.data.data;

      formik.setFieldValue("stamp_image", imageData);

      const imageId = res.data.data.id;

      formik.setFieldValue("stamp_image_id", imageId);
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} md={4} lg={2}>
          <FormTextInput
            variant="outlined"
            id="name"
            label="客人名稱"
            name={`name`}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <FormTextInput
            variant="outlined"
            id="fake_name"
            label="簡稱"
            name={`fake_name`}
            value={formik.values.fake_name}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} md={4} lg={2}>
          <FormTextInput
            variant="outlined"
            id="email"
            label="電郵地址"
            name={`email`}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <FormTextInput
            variant="outlined"
            id="tel1"
            label="電話(1)"
            name={`tel1`}
            value={formik.values.tel1}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <FormTextInput
            variant="outlined"
            id="tel2"
            label="電話(2)"
            name={`tel2`}
            value={formik.values.tel2}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <FormTextInput
            variant="outlined"
            id="fax"
            label="傳真"
            name={`fax`}
            value={formik.values.fax}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={6}>
          <FormTextInput
            variant="outlined"
            id="stamp1"
            label="打字印(1)"
            name={`stamp1`}
            value={formik.values.stamp1}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={6}>
          <FormTextInput
            variant="outlined"
            id="stamp2"
            label="打字印(2)"
            name={`stamp2`}
            value={formik.values.stamp2}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={6} md={4} lg={12}>
        <Typography variant="h5">打字印(圖)</Typography>
        <UploadImageArea
          onChange={handleImageChange}
          value={formik.values.stamp_image}
        />
      </Grid>

      <Grid item xs={6} md={4} lg={12}>
        <FormTextInput
          variant="outlined"
          id="address1"
          label="地址(1)"
          name={`address1`}
          value={formik.values.address1}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={4} lg={12}>
        <FormTextInput
          variant="outlined"
          id="address2"
          label="地址(2)"
          name={`address2`}
          value={formik.values.address2}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <FormTextInput
          variant="outlined"
          id="Attention "
          label="Attention "
          name="attention "
          multiline
          rows={4}
          value={formik.values.attention}
          onChange={formik.handleChange}
        />
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6} md={4} lg={2}>
          <FormTextInput
            variant="outlined"
            id="ups"
            label="UPS"
            name={`ups`}
            value={formik.values.ups}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <FormTextInput
            variant="outlined"
            id="gold_mark_up"
            label="Gold Mark Up"
            name={`gold_mark_up`}
            value={formik.values.gold_mark_up}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddClientInfoSection;
