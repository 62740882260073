import PageHeadline from "../../../component/PageHeadline";
import PageSubHeadline from "../../../component/PageSubHeadline";
import { Button, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const HeaderWithButton = ({
  openForm,
  title_context = "title_context",
  show_Overview_title = true,
  button_context = "ADD",
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <PageHeadline>{title_context}</PageHeadline>
        {show_Overview_title ? <PageSubHeadline>總覽</PageSubHeadline> : null}
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openForm}
        >
          {button_context}
        </Button>
      </Grid>
    </Grid>
  );
};

export default HeaderWithButton;
