import { PageContext } from "../../../context/PageContext";
import EntityMangeLayout from "../../../layout/EntityMangeLayout";
import { API_ENDPOINT_PREFIX } from "../../../helper/fetching";
import PageHeader from "./component/PageHeader";
import useFetch from "../../../hook/useFetch";
import ReportListingSection from "./component/ReportListingSection";
// import EditForm from "./Component/FormikForm/EditForm";
// import CreateForm from "./Component/FormikForm/CreateForm";
import { fields, proformerReportHeaders ,demoData} from "./constant/fieldName";
const ProformerReportListingPage = ()=>{
    const tableHeaders=proformerReportHeaders;
    const proformerReportTableColunm =[
        {
            field: "item",
            headerName: "項目",
            flex: 1,
        },
        {
            field: "data",
            headerName: "資料",
            flex: 1,
        },
    ]

    const {
        entities: rows,
        isLoading,
        fetchEntity: fetchProformerReportList,
        setReady,
        handleFilterChange,
        setPage,
        total,
      } = useFetch({
        api: `${API_ENDPOINT_PREFIX}/client/`,
        paged: true,
      });


      


    return(
        <PageContext.Provider      
        value={{
            fetchProformerReportList,
            setReady,
            handleFilterChange,
          }}>
            {/* <EntityMangeLayout 
                columns={proformerReportTableColunm}
                rows={rows}
                // colActions={["edit"]}
                renderHeader={(props) => <PageHeader {...props} />}
                // renderCreateForm={(props) => <CreateForm {...props} />}
                // renderEditForm={(props) => <EditForm {...props} />}
                onPageChange={setPage}
                totalNumberOfRows={total}
                paginationMode="server"
                isLoading={isLoading}
            /> */}

            <ReportListingSection
                renderHeader={(props) => <PageHeader {...props} />}
                tableHeaders={tableHeaders}
                tableRows={demoData}
            />

        </PageContext.Provider>
    )

}
     

export default ProformerReportListingPage;