import { Collapse, makeStyles } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Link, useLocation } from "react-router-dom";
import { useContext, useState , useEffect } from "react";
import { FormContext } from "../../context/FormContext";
import { privateRoutes } from "../../route/routes";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { TokenContext } from "../../context/TokenContext"

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    nested: { paddingLeft: theme.spacing(4), },
}));

const SubMenus = ({
    route,
    handleClick,
}) => {
    const { navigatorName, submenus } = route;
    const location = useLocation();
    const classes = useStyles();
    const [collapse, setCollapse] = useState(true);
    const handleCollapse = () => {
        setCollapse(!collapse);
    };

    return (
        <>
            <ListItem
                button
                key={route.navigatorName}
                component={Link}
                to={route.path}
                selected={location.pathname.startsWith(route.path)}
                onClick={handleClick}>
                <ListItemText primary={navigatorName} />
                {collapse ? <ExpandLess onClick={handleCollapse} /> : <ExpandMore onClick={handleCollapse} />}
            </ListItem>

            <Collapse in={collapse} timeout="auto" unmountOnExit>
                {submenus.map((submenu, index) => {
                    return (<ListItem
                        button
                        key={submenu.navigatorName}
                        component={Link}
                        to={submenu.path}
                        selected={location.pathname.startsWith(submenu.path)}
                        onClick={handleClick}
                        className={classes.nested}
                    >
                        <ListItemText primary={submenu.navigatorName} />
                    </ListItem>)
                })}
            </Collapse>
        </>
    );
}

const DrawerContent = ({
    onClose,
    updateTitle = () => {}
}) => {
    const { permission } = useContext(TokenContext)

    const classes = useStyles();
    const location = useLocation();
    const handleClick = (routerName) => {
        if (onClose) {
            onClose();
        }
        updateTitle(routerName)
    }

    useEffect(()=>{
        const onRouterName = privateRoutes.find((route)=>route.path === location.pathname)?.navigatorName
        onRouterName && updateTitle(onRouterName)
    },[])
    
    return (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                {privateRoutes.map((route, index) => {
                    if (route.submenus) {
                        // check if it contains submenus
                        return <SubMenus key={index} route={route} handleClick={handleClick} />
                    } else if (route.navigatorName && !permission?.unavailable_page?.some((item) => item === route.path.replace("/", ""))) {
                        return (
                            <ListItem
                                button
                                key={route.navigatorName}
                                component={Link}
                                to={route.path}
                                selected={location.pathname.startsWith(route.path)}
                                onClick={()=>handleClick(route.navigatorName)}
                            >
                                <ListItemText primary={route.navigatorName} />
                            </ListItem>
                        )
                    } else {
                        return ""
                    }

                })}

            </List>
        </div>
    );
}

export default DrawerContent;