import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import PopupWidget from "../../../component/PopupWidget";
import TableWithCheckBox from "../../../component/TableWithCheckBox";
import { API_ENDPOINT_PREFIX } from "../../../helper/fetching";

function PoSearchSection({
  arrayHelpers,

  selectedOption,
  setSelectedOption,

  preSetValues,
  setPreSetValues,

  dialogTitle = "No Title",

  searchModule = "",

  toolTipTitle,

  searchFieldLabel,

  searchKeys,
  columns,

  ...rest
}) {
  const formik = useFormikContext();

  const handleAddSelectedOption = () => {
    if (selectedOption) {
      setPreSetValues(selectedOption);
    }
  };

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item>
        <PopupWidget
          dialogTitle={dialogTitle}
          handleSubmit={handleAddSelectedOption}
          toolTipTitle={toolTipTitle}
        >
          <TableWithCheckBox
            optionUrl={`${API_ENDPOINT_PREFIX}/${searchModule}`}
            setSelectedOption={setSelectedOption}
            searchFieldLabel={searchFieldLabel}
            searchKeys={searchKeys}
            columns={columns}
          />
        </PopupWidget>
      </Grid>
    </Grid>
  );
}

export default PoSearchSection;
