import { useState, useEffect } from "react";
import { API_ENDPOINT_PREFIX, SERVER_URL } from "../../helper/fetching";
import useFetch from "../../hook/useFetch";
import { PageContext } from "../../context/PageContext";
import EntityMangeLayout from "../../layout/EntityMangeLayout";
import CreateForm from "./Component/CreateForm";
import EditForm from "../PurchaseOrderPage/Component/EditForm/EditForm";

// import HeaderTemp from "../../component/HeaderTemp";
import HeaderWithButton from "./Component/HeaderWithButton";
import ComponentLayout from "../../layout/EntityMangeLayout/ComponentLayout";

import QuotationPageCreateForm from "../QuotationPage/QuotationListingPage/component/CreateForm";
import WordOrderCreateForm from "../ProductPage/ProductListingPage/component/CreateForm";
import DeleteForm from "./Component/DeleteForm";
import CloneForm from "./Component/CloneForm";

import { format } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";

const OrderPage = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const columns = [
    {
      field: "po_no",
      headerName: "PO",
      flex: 1,
    },
    {
      field: "client",
      headerName: "client name",
      flex: 1,
      valueGetter: (params) => params.value.name,
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      valueGetter: (params) => format(new Date(params.value), "MM-dd-yyyy"),
    },
    {
      field: "quotations_are_confirmed",
      headerName: "Is Quotations Confirmed",
      flex: 1,
      valueGetter: (params) =>
        params.value ? "Is Confirmed" : "Not Confirmed",
    },
  ];

  const fetchInitFilter = {
    api: `${API_ENDPOINT_PREFIX}/purchase-order`,
    paged: true,
  };

  const {
    entities: rows,
    isLoading,
    fetchEntity: fetchPurchaseOrder,
    setReady,
    handleFilterChange,
    setPage,
    total,
    pageSize,
  } = useFetch(fetchInitFilter);

  useEffect(() => {
    const po_no = searchParams.get("po_no");

    if (po_no) {
      const interval = setInterval(() => {
        const rows = document.querySelectorAll(".MuiDataGrid-row");
        for (let row of rows) {
          const poNoCell = row.querySelector(
            `.MuiDataGrid-cell[data-value="${po_no}"]`
          );
          if (poNoCell) {
            const actionsCell = row.querySelector('div[data-field="actions"]');
            if (actionsCell) {
              const editButton =
                actionsCell.querySelector('span[title="編輯"]');
              if (editButton) {
                editButton.click();

                // Remove po_no from URL after clicking the edit button
                searchParams.delete("po_no");
                history.replace({ search: searchParams.toString() });

                clearInterval(interval);
                break;
              }
            }
          }
        }
      }, 0);

      return () => clearInterval(interval);
    }
  }, [location.search, history, isLoading]);

  return (
    <PageContext.Provider
      value={{
        fetchPurchaseOrder,
        setReady,
        handleFilterChange,
        rows,
      }}
    >
      <EntityMangeLayout
        columns={columns}
        rows={rows}
        renderHeader={(props) => (
          <HeaderWithButton
            {...props}
            title_context="訂單"
            button_context="新增訂單"
          />
        )}
        renderCreateForm={(props) => <CreateForm {...props} />}
        renderEditForm={(props) => <EditForm {...props} />}
        renderDeleteForm={(props) => <DeleteForm {...props} />}
        renderCloneForm={(props) => <CloneForm {...props} />}
        pageSize={pageSize}
        onPageChange={setPage}
        colActions={["edit", "clone", "delete"]}
        paginationMode="server"
        totalNumberOfRows={total}
        isLoading={isLoading}
      ></EntityMangeLayout>

      {/* <br />
      <ComponentLayout
        renderHeader={(props) => (
          <HeaderWithButton
            {...props}
            show_Overview_title={false}
            title_context="報價單"
            button_context="新增報價單"
          />
        )}
        renderCreateForm={(props) => <QuotationPageCreateForm {...props} />}
      />
      <br />
      <ComponentLayout
        renderHeader={(props) => (
          <HeaderWithButton
            {...props}
            show_Overview_title={false}
            title_context="製工單"
            button_context="新增製工單"
          />
        )}
        renderCreateForm={(props) => <WordOrderCreateForm {...props} />}
      /> */}
    </PageContext.Provider>
  );
};

export default OrderPage;
