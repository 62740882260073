import { BrowserRouter } from "react-router-dom";
import StyledSnackBar from "./component/StyledSnackBar";
import { SnackBarContext } from "./context/SnackBarContext";
import { TokenBaseRouteComponent, TokenProvider } from "./context/TokenContext";
import useSnackBar from "./hook/useSnackBar";

function App() {
  const {
    open, 
    message, 
    severity, 
    setClose, 
    setMessage, 
    setSuccessMessage, 
    setErrorMessage
  } = useSnackBar();

  return (
    <TokenProvider>
      <BrowserRouter>
        <SnackBarContext.Provider value={{setClose, setMessage, setSuccessMessage, setErrorMessage}}>

            <TokenBaseRouteComponent/> 
            <StyledSnackBar
              open={open}
              autoHideDuration={2000}
              onClose={setClose}
              severity={severity}
              message={message}
            />
        </SnackBarContext.Provider>
      </BrowserRouter>
    </TokenProvider>
  );
}

export default App;
