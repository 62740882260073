import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import ComponentLayout from "../../layout/EntityMangeLayout/ComponentLayout";

const useStyles = makeStyles({
  dialogContent: {
    padding: "30px !important",
  },
  searchButton: {
    marginBottom: "15px",
  },
});
const CreateButton = ({ createTitle, CreateForm = () => {}, ...props }) => {
  const classes = useStyles();
  const BTN = (props) => (
    <Button
      variant="contained"
      color="primary"
      className={classes.searchButton}
      startIcon={<AddIcon />}
      onClick={props.openForm}
      {...props}
    >
      {createTitle}
    </Button>
  );
  return (
    <ComponentLayout
      renderHeader={(props) => <BTN {...props} />}
      renderCreateForm={(props) => <CreateForm {...props} />}
    />
  );
};

export default CreateButton;
