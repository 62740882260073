export const progressSteps = [
  {
    label: "開單",
    value: 1
  },
  {
    label: "吱蠟",
    value: 2
  },
  {
    label: "倒模",
    value: 3
  },
  {
    label: "執模",
    value: 4
  },
  {
    label: "配石",
    value: 5
  },
  {
    label: "出水",
    value: 6
  },
  {
    label: "鑲石",
    value: 7
  },
  {
    label: "執邊",
    value: 8
  },
  {
    label: "電金",
    value: 9
  },
  {
    label: "QC 成品",
    value: 10
  },
  {
    label: "工場發票",
    value: 11
  },
  {
    label: "出單(印牌仔)",
    value: 12
  },
  {
    label: "打包(落貨)",
    value: 13
  },
];
