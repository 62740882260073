import { Grid, makeStyles, Typography } from "@material-ui/core";
import { calQuotationTotal } from "../../Calculator/QuotationTotal";
import { useFormikContext } from "formik";


const useStyles = makeStyles((theme) => ({
    formTitle: {
        marginBottom: 10,
        marginTop: 10,
    },
}));

function QuotationPriceSection() {
    const classes = useStyles();
    const formik = useFormikContext();
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant="h5" className={classes.formTitle}>
                    報價單單價:{ calQuotationTotal(formik.values)}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default QuotationPriceSection;