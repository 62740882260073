import AutoCompleteInput from "../../../../../component/AutoCompleteInput";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import UploadImageArea from "../../../../../component/UploadImageArea";
import { Grid, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useState } from "react";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
} from "../../../../../helper/fetching";
// import { makeStyles } from "@material-ui/styles";

const BasicInfoSection = (props) => {
  const { isEditPage } = props;
  const formik = useFormikContext();

  const handleImageChange = async (file) => {
    var formData = new FormData();
    formData.append("image", file);
    try {
      const res = await apiRequest().post(
        `${API_ENDPOINT_PREFIX}/upload/image`,
        formData
      );
      const imageData = res.data;
      // formik.setFieldValue("stamp_image_id", imageData.id);
      formik.setFieldValue("images", imageData.data);
      formik.setFieldValue("image_id", imageData.id);
    } catch (error) {
    } finally {
    }
  };

  const useStyles = makeStyles({
    image: {
      width: "33% ",
    },
  });
  const classes = useStyles();
  const [identifier, setIdentifier] = useState("");

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <UploadImageArea
          value={formik.values.images}
          onChange={handleImageChange}
        />
      </Grid>

      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="identifier"
          label="款號"
          name="identifier"
          value={formik.values.identifier}
          onChange={formik.handleChange}
          required={true}
        />

        {/* <AutoCompleteInput
            id="product"
            getOptionLabel={(option) => (option ? option.identifier : "")}
            getOptionValue = {(value)=> `${value.identifier}" "${value.stone_initial} `}
            optionUrl={`${API_ENDPOINT_PREFIX}/work-order`}
            label="款號"
            name="identifier"
             value={identifier}
             onChange={setIdentifier}
            noOptionsText="沒有選項"
          /> */}
      </Grid>

      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="category"
          label="款式"
          name="category"
          value={formik.values.category}
          onChange={formik.handleChange}
          required={true}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="aa_no"
          label="AA no"
          name="aa_no"
          value={formik.values.aa_no}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="style_no"
          label="Style no"
          name="style_no"
          value={formik.values.style_no}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="wrist_size"
          label="手寸"
          name="wrist_size"
          value={formik.values.wrist_size}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="chain_length"
          label="鏈長"
          name="chain_length"
          value={formik.values.chain_length}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="zhen_fen_se"
          label="真分色"
          name="zhen_fen_se"
          value={formik.values.zhen_fen_se}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="dian_fen_se"
          label="電分色"
          name="dian_fen_se"
          value={formik.values.dian_fen_se}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="lu_zhu_bian"
          label="轆珠邊"
          name="lu_zhu_bian"
          value={formik.values.lu_zhu_bian}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="er_zhen"
          label="耳針"
          name="er_zhen"
          value={formik.values.er_zhen}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="xian_pai"
          label="線拍"
          name="xian_pai"
          value={formik.values.xian_pai}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="shi_mian_dian_bai"
          label="石面電白"
          name="shi_mian_dian_bai"
          value={formik.values.shi_mian_dian_bai}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="shi_di_dian_bai"
          label="石底電白"
          name="shi_di_dian_bai"
          value={formik.values.shi_di_dian_bai}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="dian_huang"
          label="電黃"
          name="dian_huang"
          value={formik.values.dian_huang}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="dian_hung"
          label="電紅"
          name="dian_hung"
          value={formik.values.dian_hung}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="dian_hei"
          label="電黑"
          name="dian_hei"
          value={formik.values.dian_hei}
          onChange={formik.handleChange}
        />
      </Grid>
      {/* <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="po_5_5"
          label="5.5迫"
          name="po_5_5"
          value={formik.values.po_5_5}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="po_4_5"
          label="4.5迫"
          name="po_4_5"
          value={formik.values.po_4_5}
          onChange={formik.handleChange}
        />
      </Grid> */}
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="work_order_er_po"
          label="製工單耳迫"
          name="work_order_er_po"
          value={formik.values.work_order_er_po}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="pi_po"
          label="啤迫"
          name="pi_po"
          value={formik.values.pi_po}
          onChange={formik.handleChange}
        />
      </Grid>

      <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="xia_bi"
          label="虾比"
          name="xia_bi"
          value={formik.values.xia_bi}
          onChange={formik.handleChange}
        />
      </Grid>
      {/* <Grid item xs={6} md={2} lg={2}>
        <FormTextInput
          variant="outlined"
          id="er_po"
          label="耳迫"
          name="er_po"
          value={formik.values.er_po}
          onChange={formik.handleChange}
        />
      </Grid> */}
      <Grid item xs={12}>
        <FormTextInput
          variant="outlined"
          id="description"
          label="描述"
          name="description"
          minRows={4}
          multiline={true}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default BasicInfoSection;
