import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import { useContext, useState } from "react";
import FormTextInput from "../../../component/Form/FormTextInput";
import * as Yup from "yup";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
  convertToFormData,
} from "../../../helper/fetching";
import { PageContext } from "../../../context/PageContext";
import UploadImageArea from "../../../component/UploadImageArea";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  informationWrapper: {
    marginBottom: 20,
  },
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
}));

const DeleteForm = ({ entity: product, closeForm, ...rest }) => {
  const classes = useStyles();

  const { fetchPurchaseOrder } = useContext(PageContext);
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (data, { setSubmitting }) => {
      try {
        const res = await apiRequest().delete(
          `${API_ENDPOINT_PREFIX}/purchase-order/${product.id}`
        );

        const json = res.data;

        closeForm();
      } catch (err) {
        err.status === 400 &&
          setMessage(
            err.data.message.includes("is confirmed")
              ? "此訂單的報價單或者製工單已被確認，則此訂單不可刪除"
              : "暫不可刪除"
          );
      } finally {
        await fetchPurchaseOrder();

        setSubmitting(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <div {...rest}>
        <div>
          <Typography variant="h3" className={classes.formTitle}>
            刪除訂單
          </Typography>
          <Typography variant="h5" className={classes.formTitle}>
            製工訂單
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormTextInput
                variant="outlined"
                id="po_no"
                label="PO"
                defaultValue={product.po_no}
                className={classes.input}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <FormTextInput
                variant="outlined"
                id="id"
                label="編號"
                defaultValue={product.id.toString().padStart(5, "0")}
                className={classes.input}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                刪除
              </Button>
              {message ? (
                <Typography variant="h6" color="error">
                  {message}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </div>
    </FormikProvider>
  );
};

export default DeleteForm;
