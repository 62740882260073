import {
  Button,
  Checkbox,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import { Fragment } from "react";
import FormTable from "../../../../../component/Form/FormTable";
import FormTextInput from "../../../../../component/Form/FormTextInput";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  tableRow: {},
  tableCell: {
    width: "100%",
  },
  button: {
    margin: "0 0 20px 0",
  },
}));

const gemObj = (props = {}) => {
  const {
    full_name = "",
    stone_code = "",
    stone_price_code = "",
    stone_type = "",
    stone_size = "",
    stone_qty = 0,
    stone_weight = 0,
    stone_is_client = false,
    stone_from_hk = false,
  } = props;

  return {
    full_name: full_name,
    stone_code: stone_code,
    stone_price_code: stone_price_code,
    stone_type: stone_type,
    stone_size: stone_size,
    stone_qty: stone_qty,
    stone_weight: stone_weight,
    stone_from_hk: stone_from_hk,
    stone_is_client: stone_is_client,
  };
};

const GemTableRow = ({
  index,
  arrayHelpers,
  value,
  stoneTypes,
  stonePriceTypes,
  ...rest
}) => {
  const formik = useFormikContext();

  const handleDeleteButtonClick = () => arrayHelpers.remove(index);

  const arrayFieldName = "deduct_gems";

  const classes = useStyles();

  const calTotal_Weight_qty = (value1 = 0, value2 = 0) => {
    if (value1 && value2) {
      return parseFloat(value1 * value2).toFixed(5).replace(/\.?0*$/, '');
    } else {
      return "----";
    }
  };

  return (
    <TableRow {...rest} className={classes.tableRow}>
      <TableCell>{index + 1}</TableCell>

      <TableCell className={classes.tableCell}>
        <FormTextInput
          name={`${arrayFieldName}.${index}.full_name`}
          onChange={formik.handleChange}
          value={value.full_name}
          label="全名"
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].full_name &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].full_name
          }
          helperText={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].full_name &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].full_name
          }
        />
      </TableCell>

      <TableCell className={classes.tableCell}>
        <FormTextInput
          name={`${arrayFieldName}.${index}.stone_code`}
          onChange={formik.handleChange}
          value={value.stone_code}
          label="石碼"
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_code &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_code
          }
          helperText={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_code &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_code
          }
        />
      </TableCell>

      <TableCell className={classes.tableCell}>
        <FormTextInput
          name={`${arrayFieldName}.${index}.stone_price_code`}
          onChange={formik.handleChange}
          value={value.stone_price_code}
          label="價錢代碼"
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_price_code &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_price_code
          }
          helperText={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_price_code &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_price_code
          }
        />
      </TableCell>

      <TableCell className={classes.tableCell}>
        <FormTextInput
          name={`${arrayFieldName}.${index}.stone_initial`}
          onChange={formik.handleChange}
          value={value.stone_initial}
          label="石類"
          // error={
          //   formik.touched[arrayFieldName] &&
          //   formik.touched[arrayFieldName][index] &&
          //   formik.touched[arrayFieldName][index].stone_type &&
          //   formik.errors[arrayFieldName] &&
          //   formik.errors[arrayFieldName][index] &&
          //   formik.errors[arrayFieldName][index].stone_type
          // }
          // helperText={
          //   formik.touched[arrayFieldName] &&
          //   formik.touched[arrayFieldName][index] &&
          //   formik.touched[arrayFieldName][index].stone_type &&
          //   formik.errors[arrayFieldName] &&
          //   formik.errors[arrayFieldName][index] &&
          //   formik.errors[arrayFieldName][index].stone_type
          // }
        />
      </TableCell>

      <TableCell className={classes.tableCell}>
        <FormTextInput
          name={`${arrayFieldName}.${index}.stone_type`}
          onChange={formik.handleChange}
          value={value.stone_type}
          label="石類(廠方)"
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_type &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_type
          }
          helperText={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_type &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_type
          }
        />
      </TableCell>

      <TableCell className={classes.tableCell}>
        <FormTextInput
          name={`${arrayFieldName}.${index}.stone_size`}
          onChange={formik.handleChange}
          value={value.stone_size}
          label="石size"
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_size &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_size
          }
          helperText={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_size &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_size
          }
        />
      </TableCell>

      <TableCell className={classes.tableCell}>
        <FormTextInput
          name={`${arrayFieldName}.${index}.stone_qty`}
          onChange={formik.handleChange}
          value={value.stone_qty}
          type="number"
          label="粒數"
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_qty &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_qty
          }
          helperText={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_qty &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_qty
          }
        />
      </TableCell>

      <TableCell className={classes.tableCell}>
        <FormTextInput
          name={`${arrayFieldName}.${index}.stone_weight`}
          onChange={formik.handleChange}
          value={value.stone_weight}
          type="number"
          label="重量"
          error={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_weight &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_weight
          }
          helperText={
            formik.touched[arrayFieldName] &&
            formik.touched[arrayFieldName][index] &&
            formik.touched[arrayFieldName][index].stone_weight &&
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].stone_weight
          }
        />
      </TableCell>

      <TableCell className={classes.tableCell}>
        <Typography>
          {calTotal_Weight_qty(
            formik.values.product_count,
            formik.values.deduct_gems[index].stone_qty
          )}
        </Typography>
      </TableCell>

      <TableCell className={classes.tableCell}>
        <Typography>
          {calTotal_Weight_qty(
            formik.values.product_count,
            formik.values.deduct_gems[index].stone_weight
          )}
        </Typography>
      </TableCell>

      {/* <TableCell className={classes.tableCell}>
        <FormTextInput
          name={`${arrayFieldName}.${index}.unit_price`}
          onChange={formik.handleChange}
          value={value.unit_price}
          type="number"
          label="單價"
          error={
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].unit_price
          }
          helperText={
            formik.errors[arrayFieldName] &&
            formik.errors[arrayFieldName][index] &&
            formik.errors[arrayFieldName][index].unit_price
          }
        />
      </TableCell> */}

      <TableCell className={classes.tableCell}>
        <Checkbox
          color="primary"
          name={`${arrayFieldName}.${index}.stone_is_client`}
          onChange={formik.handleChange}
          inputProps={{ "aria-label": "secondary checkbox" }}
          checked={formik.values[arrayFieldName][index]["stone_is_client"]}
        />
      </TableCell>

      {/*TODO: name need to be confirm */}
      <TableCell className={classes.tableCell}>
        <Checkbox
          color="primary"
          name={`${arrayFieldName}.${index}.stone_from_hk`}
          onChange={formik.handleChange}
          inputProps={{ "aria-label": "secondary checkbox" }}
          checked={formik.values[arrayFieldName][index]["stone_from_hk"]}
        />
      </TableCell>

      <TableCell>
        <Tooltip title="刪除" arrow>
          <IconButton onClick={handleDeleteButtonClick}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const GemTable = ({ arrayHelpers, ...rest }) => {
  const tableHeader = [
    "#",
    "全名",
    "石碼",
    "價錢代碼",
    "石類",
    "石類(廠方)",
    "石size",
    "粒數",
    "重量",
    "總花費粒數",
    "總使用重量",
    // "單價",
    "客來石",
    "香港來石",
    "動作",
  ];

  const formik = useFormikContext();

  const classes = useStyles();

  const handleAddGem = () => arrayHelpers.push(gemObj());

  const renderRows = () =>
    formik.values.deduct_gems?.map((gem, index) => (
      <GemTableRow
        key={index}
        index={index}
        arrayHelpers={arrayHelpers}
        value={gem}
      />
    ));

  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddGem}
        className={classes.button}
        // disabled={full}
      >
        新增寶石
      </Button>

      <FormTable headerNames={tableHeader} renderRows={renderRows} />
    </Fragment>
  );
};

const GemSection = () => {
  return (
    <FieldArray
      name="deduct_gems"
      validateOnChange={false}
      render={(arrayHelpers) => <GemTable arrayHelpers={arrayHelpers} />}
    />
  );
};

export default GemSection;
